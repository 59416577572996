import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start leave-text" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";

interface Props {
  item: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuComponent',
  props: {
    item: {}
  },
  emits: ["click:edit", "click:leave"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const emitClick = (event: any) => {
  emit(event, props.item);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:detail')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "InfoCircle",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('global.viewDetail')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:leave')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Logout",
                class: "leave-icon"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('global.leave')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})