import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-flex ion-align-items-center"
}
const _hoisted_2 = { class: "photo-wrapper" }
const _hoisted_3 = { class: "ion-padding-start" }

import {IonLabel} from "@ionic/vue";
import {computed, onMounted, Ref, ref} from "vue";
import {ILocation} from "@/interfaces/locale/ILocation";
import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";

interface Props {
  item: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemMobileComponent',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const location: Ref<ILocation | undefined> = ref<ILocation>()

const locationId = computed(() => location.value? location.value.id : undefined);
const imageId = computed(() => location.value? location.value?.image_id : undefined);
const locationName = computed(() => location.value? location.value?.name : undefined);

onMounted(() => {
    try {
        const payload = {
            model: MODEL.LOCATION,
            fields: ['id', 'name', 'address', 'country_id', 'phone_number', 'email', 'local_type', 'code', 'entity_id', 'image_id'],
        };
        const filter = [
            {
                field: 'code',
                op: "==",
                value: props.item.location_code,
            },
        ];
        handleSearchReadModel(payload, filter).then(async (data) => {
            location.value = data.items[0] as ILocation;
        })
    } catch (err) {
        console.error(err);
    }
})

return (_ctx: any,_cache: any) => {
  return (location.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AvatarLocaleComponent, {
            "locale-id": locationId.value,
            "image-id": imageId.value,
            "locale-name": locationName.value ?? ''
          }, null, 8, ["locale-id", "image-id", "locale-name"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(location.value['name']) + " ", 1),
              _createElementVNode("p", null, _toDisplayString(location.value['address']), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})