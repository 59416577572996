import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  class: "ion-padding-top comments-content",
  slot: "content"
}
const _hoisted_2 = { class: "comments-text-container" }
const _hoisted_3 = { class: "comments-text" }
const _hoisted_4 = {
  key: 1,
  class: "comment-input ion-margin-vertical"
}
const _hoisted_5 = { class: "comments-input" }
const _hoisted_6 = {
  key: 0,
  class: "comment-input"
}

import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import AvatarCommentComponent from "@/components/admin/tasks/AvatarCommentComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import { ENUM_TASK, MODEL, ROLES } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import dayjs from "dayjs";
import {
  handleDeleteModel,
  handlePostModel,
  handlePutModel,
  handleSearchReadModel,
} from "@/services/generic";
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonNote,
  IonTextarea,
  toastController,
} from "@ionic/vue";

interface Props {
  taskId: number | undefined;
  taskStatus?: string;
}

interface Comment {
  created_user_id?: number | undefined;
  comment?: string;
  task_id?: string;
  id?: number;
  created_datetime?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommentTask',
  props: {
    taskId: {},
    taskStatus: {}
  },
  setup(__props: any) {

const props = __props;
const message: Ref<string> = ref("");
const comments: Ref<Comment[]> = ref([]);
const idComment: Ref<any> = ref(null);
const { user } = useRegister();

const handleGetComments = async () => {
  try {
    const payload = {
      model: MODEL.COMMENT,
      size: 200,
      fields: [
        "created_user_id",
        "comment",
        "task_id",
        "id",
        "created_datetime",
      ],
    };
    const filters = [
      {
        value: props.taskId,
        field: "task_id",
        op: "==",
      },
    ];
    const sortBy = [
      {
        field: "created_datetime",
        order: "asc",
      },
    ];
    const { items } = await handleSearchReadModel(payload, filters, sortBy);
    comments.value = items.map((item: any) => {
      return {
        ...item,
        created_datetime: dayjs(item.created_datetime).format("DD MMM YYYY"),
      };
    });
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleNewComment = async () => {
  try {
    if (message.value.length) {
      const payload = {
        model: MODEL.COMMENT,
        fields: {
          task_id: props.taskId,
          comment: message.value,
        },
      };
      await handlePostModel(payload);
      message.value = "";
      handleGetComments();
    }
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleDeleteComment = async (id: any) => {
  try {
    const payload = {
      model: MODEL.COMMENT,
      id: id,
    };
    await handleDeleteModel(payload);
    handleGetComments();
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleEnableEditComment = (id: any) => {
  idComment.value = id;
};

const handleUpdateComment = async () => {
  const item = comments.value.find(
    (comment: any) => comment.id === idComment.value
  );
  try {
    if (item?.comment?.length) {
      const payload = {
        model: MODEL.COMMENT,
        id: idComment.value,
        fields: {
          comment: item.comment,
        },
      };
      await handlePutModel(payload);
      idComment.value = null;
      handleGetComments();
    }
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const updateInputComment = (e: any) => {
  const index = comments.value.findIndex(
    (comment: any) => comment.id === idComment.value
  );
  comments.value[index].comment = e.target.value;
};

handleGetComments();

const canOptionsMenu = (user_created_user: any) => {
  return (
    user_created_user === user.value.id || user.value.role !== ROLES.EMPLOYEE
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(IonAccordionGroup), { value: "first" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonAccordion), { value: "first" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonItem), { slot: "header" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonLabel), { class: "comments-title" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Comentarios (" + _toDisplayString(comments.value.length) + ")", 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "divider" }, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value, ({ comment, created_datetime, id, created_user_id }, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "comments-item ion-padding-top",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(AvatarCommentComponent, { userId: created_user_id }, {
                      default: _withCtx((props) => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_unref(IonLabel), { class: "comments-user" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(props.fullName), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (idComment.value != id)
                            ? (_openBlock(), _createBlock(_unref(IonLabel), {
                                key: 0,
                                class: "comments-message"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(comment), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_unref(IonTextarea), {
                                  rows: 1,
                                  color: "dark",
                                  cols: 1,
                                  placeholder: "Editar comentario",
                                  value: comment,
                                  onIonInput: updateInputComment,
                                  onKeypress: _withKeys(handleUpdateComment, ["enter"])
                                }, null, 8, ["value"]),
                                _createVNode(_unref(VsxIcon), {
                                  iconName: "Back",
                                  color: "#cf2617",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (idComment.value = null))
                                }),
                                _createVNode(_unref(VsxIcon), {
                                  iconName: "Send",
                                  onClick: handleUpdateComment
                                })
                              ])),
                          _createVNode(_unref(IonNote), { class: "comments-date" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(created_datetime), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["userId"])
                  ]),
                  _createElementVNode("div", null, [
                    (canOptionsMenu(created_user_id))
                      ? (_openBlock(), _createBlock(DropdownMenuTaskComponent, {
                          key: 0,
                          "text-edit": "Editar",
                          "text-delete": "Eliminar",
                          "onClick:delete": ($event: any) => (handleDeleteComment(id)),
                          "onClick:edit": ($event: any) => (handleEnableEditComment(id))
                        }, null, 8, ["onClick:delete", "onClick:edit"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.taskStatus !== _unref(ENUM_TASK).finished)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_unref(IonTextarea), {
              rows: 1,
              color: "dark",
              cols: 1,
              placeholder: "Añadir comentario",
              modelValue: message.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((message).value = $event)),
              onKeypress: _withKeys(handleNewComment, ["enter"])
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(VsxIcon), {
              iconName: "Send",
              onClick: handleNewComment
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})