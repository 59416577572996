import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import {IonButton, IonSlide, IonSlides} from "@ionic/vue";
import PlanDetail from "@/components/PlanDetail.vue";
import {computed, ComputedRef, onMounted, onUnmounted, ref, watch} from "vue";
import {usePlan} from "@/composables/usePlan";
import {IPlan} from "@/interfaces/plan/IPlan";
import {IPlanProduct} from "@/interfaces/plan_product/IPlanProduct";
import {useRouter} from "vue-router";
import {post} from "@/services/api";
import {usePaymentStore} from "@/stores/payment";

import "cordova-plugin-purchase";
import {useRegister} from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import {usePlatform} from "@/composables/usePlatform";
import {IPaymentResult} from "@/interfaces/payment/IPaymentResult";
import PlanDetailUser from "@/components/PlanDetailUser.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import {PLAN_TYPE_CODE} from "@/utils/constants";
import { handleAmountUserLocationData, handleUsersByLocationData } from "@/services/user_location";

// eslint-disable-next-line no-use-before-define
declare let CdvPurchase: any;


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanView',
  setup(__props) {

const router = useRouter();

const {setPlanProduct, setTransaction} = usePaymentStore();
const {user} = useRegister();
const {updateLocationPlan, updateUserPlan} = useCore();
const {isPlatformIOS, isPlatformAndroid} = usePlatform();
const coreStore: CoreStore = useCoreStore();

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 'auto',
  setWrapperSize: true,
  spaceBetween: 0,
};

const {plans,  getSubscriptionPlans, getSubscriptionPlansPro, getSubscriptionPlansUser, getSubscriptionAllPlans} = usePlan();
const {setRefreshPlan} = useCore()

const planProducts = ref<IPlanProduct[]>([]);

const planProductsFiltered = computed(() => {
  if (updateLocationPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === false && planProduct.plan.id === PLAN_TYPE_CODE.PRO);
  }
  if (!updateLocationPlan.value && !updateUserPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === false);
  }
  if (updateUserPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === true);
  }
  return [];
});

const planSelected = async (planProduct: IPlanProduct) => {
  setPlanProduct(planProduct);
  if (planProduct.product) {
    try {
      const result = await CdvPurchase.store.order(planProduct.product.offers[0]);
      if (result?.isError) {
        await router.push({name: 'failedPayment'})
      }
    } catch {
      await router.push({name: 'failedPayment'});
    }
  } else {
    if (user.value.entity_id == null) {
      await router.replace({name: 'registerBusinessInfo'})
    } else {
      await router.replace({name: 'admin.locations.list'})
    }
  }
}
const updateUserPlanAction = async () => {
  router.back();
};

const updateLocationPlanAction = async () => {
  router.back();
};


watch(plans, () => {
  planProducts.value = [];
  if (plans.value.length) {
    planProducts.value = plans.value.map(plan => ({
      plan
    }));
    CdvPurchase.store.register([]);
    registerProducts(plans.value);
  }
});

CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

function registerProducts(plans: IPlan[]) {
  const paidPlan = plans.filter((plan, _) => plan.store_id);
  CdvPurchase.store.verbosity = CdvPurchase.store.DEBUG;
  CdvPurchase.store.register(paidPlan.map(plan => ({
      id: plan.store_id!,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: isPlatformAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE
  })));
  CdvPurchase.store.initialize();
  CdvPurchase.store.update();
  CdvPurchase.store.restorePurchases();
  mergeProducts();
}

CdvPurchase.store.ready(() => {
  mergeProducts();
});

// eslint-disable-next-line no-use-before-define
const verifyAndroidTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  const transactionToVerify = {
    platform: isPlatformAndroid ? 'android' : CdvPurchase.Platform.APPLE_APPSTORE,
    store_id: transaction.products[0].id,
    purchase_token: transaction.purchaseId
  }
  setTransaction(transactionToVerify);
  verifyTransaction(transactionToVerify)
}

const verifyTransaction = async (payload: any) => {
  try {

    post({url: '/payment/check', payload}).then(async (response: IPaymentResult) => {
      setRefreshPlan(true);
    })
    if (updateUserPlan.value) {
      await router.replace({name: 'successUserPayment'});
    } else {
      await router.replace({name: 'successPayment'});
    }
  } catch {
    await router.push({name: 'unverifiedPayment'});
  }
}

// eslint-disable-next-line no-use-before-define
const onApprovedTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  verifyAndroidTransaction(transaction)
}

CdvPurchase.store.when()
    .finished(transaction => alert("state" + transaction.state))
    .approved(onApprovedTransaction);

const mergeProducts = () => {
  planProducts.value = planProducts.value.map(planProduct => ({
    ...planProduct,
    product: CdvPurchase.store.products.find(product => product.id === planProduct.plan.store_id && planProduct.plan.store_id)
  }));
}

onMounted(() => {
  getSubscriptionAllPlans();
});

onUnmounted(() => {
  CdvPurchase.store.off(onApprovedTransaction)
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    "title-button-back": _ctx.$t('register.selectPlan'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      (!_unref(updateUserPlan))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_unref(plans).length)
              ? (_openBlock(), _createBlock(_unref(IonSlides), {
                  key: 0,
                  pager: false,
                  options: slideOpts
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(planProductsFiltered.value, (planProduct, index) => {
                      return (_openBlock(), _createBlock(_unref(IonSlide), {
                        key: index,
                        class: _normalizeClass({'center': _unref(updateUserPlan) || _unref(updateLocationPlan), })
                      }, {
                        default: _withCtx(() => [
                          _createVNode(PlanDetail, {
                            plan: planProduct.plan,
                            onSelected: () => planSelected(planProduct as IPlanProduct)
                          }, null, 8, ["plan", "onSelected"])
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (planProductsFiltered.value)
              ? (_openBlock(), _createBlock(PlanDetailUser, {
                  key: 0,
                  "plan-products": planProductsFiltered.value as IPlanProduct[],
                  onSelected: planSelected
                }, null, 8, ["plan-products"]))
              : _createCommentVNode("", true)
          ])),
      (_unref(updateUserPlan))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(IonButton), {
              fill: "outline",
              expand: "block",
              onClick: updateUserPlanAction
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("global.goBack")), 1)
              ]),
              _: 1
            })
          ]))
        : (_unref(updateLocationPlan))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(IonButton), {
                fill: "outline",
                expand: "block",
                onClick: updateLocationPlanAction
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("global.goBack")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})