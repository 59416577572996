import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "photo-wrapper" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "item__box" }
const _hoisted_4 = { class: "item__box" }
const _hoisted_5 = { class: "item__box" }
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "title__info" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "title__info" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "title__info" }
const _hoisted_12 = { class: "title__info" }
const _hoisted_13 = { class: "title__info" }
const _hoisted_14 = { class: "title__info" }

import { computed, onMounted } from "vue";
import { useLocation } from "@/composables/useLocation";
import PhotoLocalComponent from "@/components/shared/PhotoLocalComponent.vue";
import { usePlatform } from "@/composables/usePlatform";

interface Props {
  item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerDetailComponent',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;
const { getCountries, getCities, countries, cities } = useLocation();
const { isPlatformIOS } = usePlatform();

const country = computed(() => {
  return countries.value[0];
});
const city = computed(() => {
  return cities.value[0];
});

onMounted(async () => {
  await getCountries(props.item.country_id);
  await getCities(props.item.city_id);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PhotoLocalComponent, {
        "image-id": _ctx.item?.image_id,
        "local-id": _ctx.item?.id
      }, null, 8, ["image-id", "local-id"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.item?.roles_stats?.owner), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("joinTeam.ownersRole")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.item?.roles_stats?.manager), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("joinTeam.managersRole")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.item?.roles_stats?.member), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("joinTeam.employeesRole")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("joinTeam.sector")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.item.local_type), 1)
      ]),
      (country.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("register.country")), 1),
            _createElementVNode("p", null, _toDisplayString(country.value.name), 1)
          ]))
        : _createCommentVNode("", true),
      (city.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("register.city")), 1),
            _createElementVNode("p", null, _toDisplayString(city.value.name), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("register.address")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.item.address), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("register.phone")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.item.phone_number), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("register.corporateEmail")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.item.email), 1)
      ])
    ])
  ], 2))
}
}

})