import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import type { Ref } from "vue";
import { handleSearchReadModel } from "@/services/generic";
import SelectComponent from "./SelectComponent.vue";

export interface Props {
  modelValue: any;
  itemValue?: string;
  itemTitle?: string;
  model: string;
  filters?: any;
  fieldSearch?: string;
  icon?:string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectAutocompleteComponent',
  props: {
    modelValue: {},
    itemValue: { default: "id" },
    itemTitle: { default: "name" },
    model: { default: "" },
    filters: { default: [] },
    fieldSearch: {},
    icon: {}
  },
  emits: ["update:modelValue", "select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const data: Ref<any> = ref([]);

const getModel = async (value = "") => {
  try {
    const search = props.fieldSearch || "";
    const payload = {
      model: props.model,
      fields: [props.itemTitle, props.itemValue, search],
    };
    const filter = [
      ...props.filters,
      ...[
        {
          field: props.itemTitle,
          op: "ilike",
          value: value,
        },
      ],
    ];

    const { items } = await handleSearchReadModel(payload, filter);
    data.value = items;
    return items;
  } catch (err) {
    console.error(err);
  }
};

const selectOption = (value: any) => {
  emit("select", value);
  emit("update:modelValue", value[props.itemValue]);
};
getModel();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SelectComponent, {
    "model-value": _ctx.modelValue,
    "search-function": getModel,
    "on-select": selectOption,
    "item-title": _ctx.itemTitle,
    model: _ctx.model,
    icon: _ctx.icon
  }, null, 8, ["model-value", "item-title", "model", "icon"]))
}
}

})