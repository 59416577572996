import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "item-task" }
const _hoisted_3 = { class: "item-footer" }
const _hoisted_4 = { class: "item-icons" }
const _hoisted_5 = { class: "ion-flex ion-align-items-center" }
const _hoisted_6 = { class: "number-comments" }
const _hoisted_7 = { class: "item-timeago" }

import {ref, onMounted, watch} from "vue";
import { VsxIcon } from "vue-iconsax";
import type {Ref} from "vue";
import {Task} from "@/interfaces/tasks/ITasks";
import {IonCard, IonItem, IonAvatar} from "@ionic/vue";
import {es} from "date-fns/locale";
import {handleReadModel} from "@/services/generic";
import {getImageLetter} from "@/utils/getImageLetter";
import {useRegister} from "@/composables/useRegister";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import ModalDetailTask from "@/components/admin/tasks/ModalDetailTask.vue";
import moment from "moment";
import useCore from "@/composables/useCore";

interface Props {
  task: Task;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemTask',
  props: {
    task: {}
  },
  emits: ["update:tasks"],
  setup(__props: any, { emit: __emit }) {

const {showTask, taskId} = useCore();

const {getPhoto} = useRegister();
const date: Ref<string | null> = ref(null);
const image: Ref<string | undefined> = ref(undefined);
const showDetail: Ref<boolean> = ref(false);
const props = __props;
const emit = __emit;

const getInfoUser = async () => {
  if (props.task.assigned_user_id) {
    const payload = {
      id: props.task.assigned_user_id,
      model: "user",
    };
    const {id, name, image_id} = await handleReadModel(payload);
    if (image_id) {
      const data = await getPhoto(id, image_id, true);
      if (data.url) {
        image.value = data.url;
      } else {
        image.value = getImageLetter(name);
      }
    } else {
      image.value = getImageLetter(name);
    }
  }
};

const handleUpdateTask = () => {
  showDetail.value = false;
  emit("update:tasks");
};

onMounted(() => {
  if (props.task.date_start) {
    const timezoneOffset = moment(props.task.date_start).utc(false);
    date.value = timezoneOffset.format();
  }
  getInfoUser();
});

watch(props.task, () => {
  getInfoUser();
});

watch(showTask, () => {
  if (showTask.value && props.task.id == taskId.value) {
    showDetail.value
  }
});

return (_ctx: any,_cache: any) => {
  const _component_timeago = _resolveComponent("timeago")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonCard), {
      class: "ion-margin",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showDetail.value = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonItem), { lines: "none" }, {
          default: _withCtx(() => [
            (_ctx.task.assigned_user_id)
              ? (_openBlock(), _createBlock(_unref(IonAvatar), {
                  key: 0,
                  slot: "start",
                  class: "ion-margin-vertical"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      alt: "Wehop User",
                      src: image.value
                    }, null, 8, _hoisted_1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.task.title), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.task.is_priority)
                    ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                        key: 0,
                        iconName: "Flash",
                        class: "icon-flash"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "Message",
                      class: "icon-message"
                    }),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.task.comments.length), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (date.value)
                    ? (_openBlock(), _createBlock(_component_timeago, {
                        key: 0,
                        datetime: date.value,
                        locale: _unref(es),
                        "converter-options": {
                includeSeconds: true,
                addSuffix: false,
                useStrict: false,
              },
                        "auto-update": ""
                      }, null, 8, ["datetime", "locale"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(ModalComponent, {
      modelValue: showDetail.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showDetail).value = $event)),
      noIconClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(ModalDetailTask, {
          task: _ctx.task,
          "onClick:back": _cache[1] || (_cache[1] = ($event: any) => (showDetail.value = false)),
          "onUpdate:tasks": handleUpdateTask
        }, null, 8, ["task"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})