import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "circle--login" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "ion-padding ion-margin-top" }
const _hoisted_4 = { class: "ion-flex ion-justify-content-center ion-padding" }

import { IonPage, IonContent, IonButton, IonText } from "@ionic/vue";
import { schemaLogin } from "@/utils/getMapModel";
import wehopLogoCircle from "@/assets/wehop-logo-circle.svg";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useAuth } from "@/composables/useAuth";
import "cordova-plugin-purchase";
import FormComponent from "@/components/shared/form/FormComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const { phone, password, login } = useAuth();
const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "login",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("login.phone"),
      type: "tel",
      icon: "Call",
      default: localStorage.getItem("phone_number"),
    },
    {
      name: "password",
      label: i18n.global.t("login.password"),
      type: "password",
      icon: "Key",
    },
  ],
});

const doLogin = (values: any) => {
  const phoneNumber = values.phone.split("|");
  phone.value = phoneNumber[1];
  password.value = values.password;
  login().then(() => {
    form.value.handleResetForm();
  });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _unref(wehopLogoCircle),
              alt: ""
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(FormComponent, {
              ref_key: "form",
              ref: form,
              model: model.value,
              schema: _unref(schemaLogin),
              onSuccess: doLogin,
              "no-cancel-button": "",
              "prevent-default": ""
            }, {
              options: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: "/forgot-password",
                  class: "link margin-top-20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("login.forgotPassword")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(IonButton), {
                  fill: "clear",
                  expand: "block",
                  "router-link": ""
                })
              ]),
              submitButton: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  mode: "ios",
                  expand: "block",
                  class: "button--login ion-margin-top",
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("actions.logIn")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "schema"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(IonText), { color: "neutral-60" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.iDontHave")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/register",
                class: "link register-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.register")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})