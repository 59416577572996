import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "downgrade-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "downgrade-button" }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import { onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handleUsersByLocationData } from "@/services/user_location";
import { ILocationResult } from "@/interfaces/ILocationResult";



export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeOptionOneView',
  setup(__props) {

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const result =  ref<ILocationResult|undefined>();

const goAddUsers = async () => {
  coreStore.setUpdateUserPlan(true);
  await router.replace({ name: "registerPlan" });
};
const goHome = async () => {
  await router.replace({ name: "admin.home" });
};
const goSelectUsers = async () => {
  await router.replace({ name: "registerPlanUsersDowngrade" });
};

onMounted(async () => {
  result.value = await handleUsersByLocationData();
});

return (_ctx: any,_cache: any) => {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      (result.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("payment.planChangeTitle")), 1),
            _createElementVNode("p", {
              class: "downgrade-description",
              innerHTML: _ctx.$t('payment.planChangeTitleDescriptionOption1')
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonButton), {
                        expand: "block",
                        onClick: goHome
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("actions.goToDashboard")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})