import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import {IonAvatar} from '@ionic/vue';
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {getImageLetter} from "@/utils/getImageLetter";
import {CoreStore, useCoreStore} from "@/stores/core";



export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarCurrentLocaleComponent',
  props: {
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  isNew: {
    type: Boolean,
    default: false
  },
  onlyCurrent: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const {getLocalePhoto, localePhoto} = useRegister();
const photo = ref<string | null>(getImageLetter(registerStore.location?.name ?? 'LO'));

watch(localePhoto, () => {
  if (localePhoto.value) {
    photo.value = registerStore.localePhoto;
    coreStore.setNewLocal(false);
  }
});

onMounted(async () => {
  try {
    if (!coreStore.newLocal) {
      if (coreStore.selectedLocale == null || props.onlyCurrent) {
        if (registerStore.location && registerStore.location.image_id && registerStore.location.id) {
          const data = await getLocalePhoto(registerStore.location.id, registerStore.location.image_id, true);
          if (data.url) {
            registerStore.setLocalePhoto(data.url);
            photo.value = data.url;
          }
        }
      } else {
        if (coreStore.selectedLocale && coreStore.selectedLocale.image_id && coreStore.selectedLocale.id) {
          const data = await getLocalePhoto(coreStore.selectedLocale.id, coreStore.selectedLocale.image_id, true);
          if (data.url) {
            registerStore.setLocalePhoto(data.url);
            photo.value = data.url;
          }
        }
      }
    }
  } catch (e) {
    console.error(e.message);
  }
})


return (_ctx: any,_cache: any) => {
  return (_unref(registerStore).location)
    ? (_openBlock(), _createBlock(_unref(IonAvatar), {
        key: 0,
        class: "avatar-wrapper",
        style: _normalizeStyle(`width:${__props.width}px;height:${__props.height}px`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: photo.value,
            alt: _unref(registerStore).location.name
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})