import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "user__name" }
const _hoisted_2 = { class: "user__role" }
const _hoisted_3 = { style: {"margin-right":"10px"} }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "box-title-item" }
const _hoisted_6 = { class: "title-item" }
const _hoisted_7 = { class: "box-address-item" }
const _hoisted_8 = { class: "address-item" }
const _hoisted_9 = {
  key: 0,
  class: "item-end"
}
const _hoisted_10 = { class: "ion-flex ion-justify-content-center" }
const _hoisted_11 = { class: "title-item-admin" }

import { IonItem, IonAvatar, IonLabel } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { useLocation } from "@/composables/useLocation";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useRouter } from "vue-router";
import { handlePutModel } from "@/services/generic";
import { MODEL, ROLES } from "@/utils/constants";
import { ILocation } from "@/interfaces/locale/ILocation";
import { computed, onMounted, watch } from "vue";
import useCore from "@/composables/useCore";
import { CoreStore, useCoreStore } from "@/stores/core";
import AvatarCurrentLocaleComponent from "@/components/shared/avatar/AvatarCurrentLocaleComponent.vue";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownLocationMenuComponent',
  emits: ["to:locations"],
  setup(__props, { emit: __emit }) {

const { locations, getLocations, getLocation } = useLocation();
const registerStore: RegisterStore = useRegisterStore();
const emit = __emit;
const router = useRouter();
const { refreshLocation } = useCore();
const coreStore: CoreStore = useCoreStore();
const { user } = useRegister();
const { handleGetMe, getLocalePhoto } = useRegister();

const isOwner = computed(() => {
  return user.value.role == ROLES.OWNER;
});

const locationList = computed(() => {
  return locations.value;
});

const handleGoLocations = () => {
  coreStore.setShowDropdown(false);
  emit("to:locations");
  router.push({ name: "admin.locations.list" });
};

const setCurrentLocation = async (location: ILocation) => {
  coreStore.setShowDropdown(false);
  await handlePutModel({
    model: MODEL.USER,
    id: registerStore.user.id,
    fields: {
      location_id: location.id,
    },
  }).then(async () => {
    registerStore.setLocation(location);
    await handleGetMe();
    if (location.image_id) {
      const data = await getLocalePhoto(
        location.id ?? -1,
        location.image_id,
        true
      );
      if (data.url) {
        registerStore.setLocalePhoto(data.url);
      }
    }
    setTimeout(() => {
      coreStore.setRefreshLocation(true);
      coreStore.setRefreshUserLocation(true);
      coreStore.setRefreshTask(true);
      coreStore.setRefreshDashboardOwner(true);
      coreStore.setRefreshDashboard(true);
    }, 800);
  });
};
onMounted(() => {
  getLocations();
});
watch(refreshLocation, async () => {
  if (refreshLocation.value) {
    await getLocations();
    let location = null;
    if (registerStore.location.id != null) {
      location = await getLocation(registerStore.location.id);
      if (location) {
        registerStore.setLocation(location[0]);
      }
    } else {
      await handleGetMe().then(async () => {
        if (registerStore.user.location_id != null) {
          location = await getLocation(registerStore.user.location_id);
          if (location) {
            registerStore.setLocation(location[0]);
          }
        }
      });
    }
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
    coreStore.setRefreshTask(false);
    coreStore.setRefreshDashboardOwner(false);
    coreStore.setRefreshDashboard(false);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(IonItem), { onClick: _unref(getLocations) }, {
        default: _withCtx(() => [
          _createVNode(AvatarCurrentLocaleComponent, {
            "only-current": true,
            width: 45,
            height: 45
          }),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(registerStore).location
                ? _unref(registerStore).location.name
                : _unref(registerStore).user.name), 1),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(registerStore).location
                ? _unref(registerStore).location.address
                : _unref(registerStore).user.role), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "ArrowDown2",
        onClick: _unref(getLocations),
        slot: "end"
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationList.value, (location, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: location.id,
            class: _normalizeClass({ 'item-start': index === 0 })
          }, [
            _createVNode(_unref(IonItem), {
              button: true,
              detail: false,
              onClick: ($event: any) => (setCurrentLocation(location))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(AvatarLocaleComponent, {
                    "locale-id": location.id ?? -1,
                    "image-id": location.image_id,
                    "locale-name": location.name,
                    width: 35,
                    height: 35
                  }, null, 8, ["locale-id", "image-id", "locale-name"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(location.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(location.address), 1)
                  ])
                ]),
                (_unref(registerStore).user.location_id === location.id)
                  ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                      key: 0,
                      iconName: "TickCircle",
                      color: "#4178F5",
                      slot: "end",
                      type: "bold"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ], 2))
        }), 128)),
        (isOwner.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createVNode(_unref(IonItem), {
                button: true,
                detail: false,
                onClick: handleGoLocations
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "Setting4",
                      color: "#4178F5",
                      slot: "end"
                    }),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("locale.adminLocales")), 1)
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})