import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "downgrade-title" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "downgrade-button" }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides, IonRow, IonCol  } from "@ionic/vue";

import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";



export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeOptionTwoView',
  setup(__props) {

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};


const goAddUsers = async () => {
  coreStore.setUpdateUserPlan(true);
  await router.replace({ name: "registerPlan" });
};
const goHome = async () => {
  await router.replace({ name: "admin.home" });
};
const goSelectUsers = async () => {
  await router.replace({ name: "registerPlanUsersDowngrade" });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("payment.planChangeTitle")), 1),
        _createElementVNode("p", {
          class: "downgrade-description",
          innerHTML: _ctx.$t('payment.planChangeTitleDescriptionOption2', {users: _unref(coreStore).subscriptionAvailability?.users_status?.number_users ?? 0})
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(IonRow), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonCol), { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    onClick: goAddUsers
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("payment.addUsers")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonCol), { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), {
                    fill: "outline",
                    expand: "block",
                    onClick: goSelectUsers
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("payment.selectUsers")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})