import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "drawer__menu"
}
const _hoisted_2 = { class: "drawer__header" }
const _hoisted_3 = { class: "drawer__content" }

import { IonFabButton } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";

interface Props {
  title: string;
  modelValue: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerComponent',
  props: {
    title: {},
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const closeDrawer = () => {
  emit("update:modelValue", false);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(IonFabButton), {
            mode: "ios",
            size: "small",
            color: "transparent",
            onClick: closeDrawer
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), { iconName: "ArrowRight" })
            ]),
            _: 1
          }),
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})