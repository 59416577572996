import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "box"
}
const _hoisted_2 = { class: "modal__container" }
const _hoisted_3 = { class: "modal__delete ion-padding-top" }
const _hoisted_4 = { class: "modal__title" }
const _hoisted_5 = { class: "modal__content" }
const _hoisted_6 = { class: "modal__actions ion-padding-bottom" }

import { IonButton, IonBackdrop } from "@ionic/vue";

interface Props {
  modelValue: boolean;
  nameItem?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSimpleDeleteComponent',
  props: {
    modelValue: { type: Boolean },
    nameItem: {}
  },
  emits: ["update:modelValue", "confirm:delete"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const closeModal = () => {
  emit("update:modelValue", false);
};

const confirmDelete = () => {
  emit("confirm:delete");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.modelValue)
      ? (_openBlock(), _createBlock(_unref(IonBackdrop), {
          key: 0,
          visible: _ctx.modelValue,
          "stop-propagation": false,
          tappable: false
        }, null, 8, ["visible"]))
      : _createCommentVNode("", true),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "title", {}, () => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("tasks.remove_title")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "content", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tasks.remove_description")), 1)
                ])
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
            ]),
            _renderSlot(_ctx.$slots, "actions", {}, () => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(IonButton), {
                  fill: "clear",
                  onClick: closeModal
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" No")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(IonButton), {
                  fill: "clear",
                  class: "button-confirm",
                  onClick: confirmDelete
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Si, eliminar ")
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})