import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-profile-wrapper" }
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "my-profile-wrapper__form" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import { schemaUpdateRegisterOwner } from "@/utils/getMapModel";
import { IonButton, alertController } from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handlerServiceDeleteUser } from "@/services/user";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import useCore from "@/composables/useCore";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileOwnerComponent',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { updateOwnerInfo, name, last_name, email, birthday } = useRegister();
const { setResetPinDigit, setResetForm } = useCore();
const { clearUserStore } = useRegister();

const form: Ref<any> = ref(null);
const addLocationResult: Ref<IActionShareResult | undefined> = ref();

const model: Ref<any> = ref({
  name: "user",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.fullName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t("register.lastName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "email",
      icon: "sms",
    },
    {
      name: "birthdate",
      label: i18n.global.t("register.birthday"),
      type: "date",
      icon: "calendar",
    },
  ],
});

const updateInfo = (values: any) => {
  name.value = values.name;
  last_name.value = values.last_name;
  email.value = values.email;
  birthday.value = values.birthdate;
  updateOwnerInfo(registerStore.user.id).then(() => {
    coreStore.setRefreshUserLocation(true);
  });
};

const handlerDeleteUser = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.deleteUserHeader"),
    message: i18n.global.t("global.deleteUserBody"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("actions.confirm"),
        cssClass: "wehop-alert-confirm",
        handler: async () => {
          try {
            handlerServiceDeleteUser().then(() => {
              setResetPinDigit(true);
              setResetForm(true);
              clearUserStore();
              setTimeout(async () => {
                await router.push({ name: "login" });
              }, 500);
            });
          } catch (e) {
            console.error(e);
          }
        },
      },
      {
        text: i18n.global.t("actions.cancel"),
        cssClass: "wehop-alert-cancel",
      },
    ],
  });

  await alert.present();

  console.log(alert);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(PhotoEditComponent, {
      "user-id": _unref(registerStore).user.id,
      "image-id": _unref(registerStore).user.image_id ?? -1,
      class: "my-profile-wrapper__photo",
      "go-back": ""
    }, null, 8, ["user-id", "image-id"]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(registerStore).user.phone_number), 1),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(FormComponent, {
        ref_key: "form",
        ref: form,
        model: model.value,
        schema: _unref(schemaUpdateRegisterOwner),
        "form-mode": "update",
        "id-model": _unref(registerStore).user.id,
        "no-cancel-button": "",
        onSuccess: updateInfo,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.update")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema", "id-model"]),
      _createElementVNode("a", {
        "exact-active-class": "active",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handlerDeleteUser()), ["prevent"])),
        id: "delete_user_button",
        class: "my-profile-wrapper caption"
      }, [
        _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.deleteUser")), 1)
      ])
    ])
  ]))
}
}

})