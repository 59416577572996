import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-profile-wrapper"
}
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "my-profile-wrapper__form" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import {
  IonButton,
  alertController,
  IonRadio,
  IonItem,
  IonLabel,
  IonRadioGroup,
  RadioGroupCustomEvent,
  toastController,
} from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handlerServiceDeleteUser } from "@/services/user";
import useCore from "@/composables/useCore";
import router from "@/router";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handlePutModel, handleSearchReadModel } from "@/services/generic";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileEmployeeComponent',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { updateEmployeeInfo, clearUserStore, name, email, last_name } =
  useRegister();
const { setResetPinDigit, setResetForm } = useCore();

const role = ref<number>(ROLE_ID.EMPLOYEE);
const locations = ref<ILocation[]>([]);
const userLocation = ref<IUserLocation>();

const user = ref<IUser>();

const getEmployee = async (event: RadioGroupCustomEvent) => {
  role.value = event.target.value;
};

const confirm = async () => {
  if (userLocation.value) {
    await handlePutModel({
      model: MODEL.USERLOCATION,
      id: userLocation.value.id,
      fields: {
        role_id: role.value,
      },
    }).then(async (_) => {
      const toast = await toastController.create({
        message: i18n.global.t("global.updateSuccess"),
        duration: 1500,
        position: "bottom",
        mode: "ios",
      });
      await toast.present();
      coreStore.setRefreshUserLocation(true);
      router.back();
    });
  }
};

const handlerDeleteUser = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.deleteUserHeader"),
    message: i18n.global.t("global.deleteUserBody"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("actions.confirm"),
        cssClass: "wehop-alert-confirm",
        handler: async () => {
          try {
            handlerServiceDeleteUser().then(() => {
              setResetPinDigit(true);
              setResetForm(true);
              clearUserStore();
              setTimeout(async () => {
                await router.push({ name: "login" });
              }, 500);
            });
          } catch (e) {
            console.error(e);
          }
        },
      },
      {
        text: i18n.global.t("actions.cancel"),
        cssClass: "wehop-alert-cancel",
      },
    ],
  });

  await alert.present();
};

onMounted(async () => {
  if (coreStore.selectedUser) {
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "user_id",
        op: "==",
        value: coreStore.selectedUser.id,
      },
      {
        field: "location_id",
        op: "==",
        value: registerStore.location.id,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    const userLocations = data.items as IUserLocation[];
    userLocation.value = userLocations[0];

    //Assign Role
    role.value = parseInt(userLocation.value?.role_id.toString());
  }

  registerStore.setUserSelectedPhoto("");
  if (coreStore.selectedUser != null) {
    user.value = coreStore.selectedUser;
  } else {
    user.value = registerStore.user;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (user.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(PhotoEditComponent, {
          class: "my-profile-wrapper__photo",
          "go-back": ""
        }),
        _createElementVNode("p", _hoisted_2, _toDisplayString(user.value.prefix_number) + _toDisplayString(user.value.phone_number), 1),
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_unref(IonRadioGroup), {
            value: role.value,
            style: {"margin-top":"20px"},
            mode: "md",
            onIonChange: _cache[0] || (_cache[0] = ($event: any) => (getEmployee($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonItem), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.employee")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonRadio), {
                    slot: "end",
                    value: _unref(ROLE_ID).EMPLOYEE
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonItem), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.manager")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonRadio), {
                    slot: "end",
                    value: _unref(ROLE_ID).MANAGER
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_unref(IonButton), {
            style: {"margin-top":"40px"},
            mode: "ios",
            expand: "block",
            type: "button",
            onClick: confirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.save")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/register/terms",
            class: "margin-top-20 my-profile-wrapper caption",
            style: {"margin-top":"100px"},
            "exact-active-class": "active",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handlerDeleteUser()), ["prevent"])),
            id: "delete_user_button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("global.deleteUser")), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})