import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "join-wrapper__back" }
const _hoisted_2 = { class: "join-wrapper__body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "join-wrapper__title" }
const _hoisted_5 = { class: "join-wrapper__description" }

import {
  IonBackButton,
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
} from "@ionic/vue";
import joinValidate from "@/assets/join-validate.svg";
import { schemaValidateCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useJoin } from "@/composables/useJoin";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinValidateCodeView',
  setup(__props) {

const { code, sendCode } = useJoin();
const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "validate",
  fields: [
    {
      name: "code",
      label: i18n.global.t("joinTeam.whiteCode"),
      type: "text",
    },
  ],
});

const validateCode = (values: any) => {
  code.value = values.code;
  sendCode().then(() => {
    form.value.handleResetForm();
  });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('joinTeam.joinATeam'),
              color: "neutral-80",
              defaultHref: "/join"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("img", {
              src: _unref(joinValidate),
              alt: ""
            }, null, 8, _hoisted_3),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("joinTeam.youKnowCode")), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("joinTeam.youKnowCodeDescription")), 1),
            _createVNode(FormComponent, {
              ref_key: "form",
              ref: form,
              model: model.value,
              schema: _unref(schemaValidateCode),
              "no-cancel-button": "",
              onSuccess: validateCode,
              "prevent-default": ""
            }, {
              checkbox: _withCtx(() => [
                _createVNode(_unref(IonLabel), { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.iAccept")) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: "/register/policy-privacy",
                      class: "link margin-top-20"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("register.thePrivacyPolicy")), 1)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("register.and")) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: "/register/terms",
                      class: "link margin-top-20"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("register.conditions")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              submitButton: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  mode: "ios",
                  expand: "block",
                  class: "button-validate ion-margin-top",
                  type: "submit",
                  fill: "outline"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("actions.validateCode")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "schema"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})