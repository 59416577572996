import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-avatar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "label-avatar" }

import { handleReadModel } from "@/services/generic";
import { IonAvatar, IonLabel } from "@ionic/vue";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import { useRegister } from "@/composables/useRegister";
import { getImageLetter } from "@/utils/getImageLetter";

interface Props {
  userId: number | null;
  label?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarDescriptionComponent',
  props: {
    userId: {},
    label: {}
  },
  setup(__props: any) {

const props = __props;
const image: Ref<string | undefined> = ref(undefined);
const fullName: Ref<string | undefined> = ref("");
const { getPhoto } = useRegister();

const getUserInfo = async () => {
  const payload = {
    id: props.userId,
    model: "user",
  };
  const { id, name, last_name, image_id } = await handleReadModel(payload);
  fullName.value = `${name} ${last_name}`;
  if (image_id) {
    const data = await getPhoto(id, image_id, true);
    if (data.url) {
      image.value = data.url;
    } else {
      image.value = getImageLetter(name);
    }
  } else {
    image.value = image_id ? "" : getImageLetter(name);
  }
};
onMounted(() => {
  getUserInfo();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IonAvatar), {
      slot: "start",
      class: "ion-margin-vertical"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "User Image",
          src: image.value
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.label)
        ? (_openBlock(), _createBlock(_unref(IonLabel), {
            key: 0,
            class: "label-text"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(IonLabel), { class: "label-user" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(fullName.value), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})