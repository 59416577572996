import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-flex ion-align-items-center" }
const _hoisted_2 = { class: "ion-padding-start" }

import { IonLabel } from "@ionic/vue";
import { ref, computed, watch } from "vue";
import type { Ref } from "vue";
import { useRegister } from "@/composables/useRegister";
import { getImageLetter } from "@/utils/getImageLetter";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";

interface Props {
  item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemMobileComponent',
  props: {
    item: {}
  },
  setup(__props: any) {

const { getLocalePhoto, localePhoto } = useRegister();

const props = __props;
const photo: Ref<string | null> = ref("");

const getPhoto = async () => {
  if (props.item.image_id) {
    const data = await getLocalePhoto(props.item.id, props.item.image_id, true);
    if (data) {
      photo.value = data.url;
    } else {
      photo.value = null;
    }
  }
};

getPhoto();

watch(localePhoto, () => {
  if (localePhoto.value) {
    getPhoto();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AvatarLocaleComponent, {
      "locale-id": _ctx.item.id,
      "image-id": _ctx.item.image_id,
      "locale-name": _ctx.item.name
    }, null, 8, ["locale-id", "image-id", "locale-name"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(IonLabel), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.name) + " ", 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.address), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})