import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-profile-wrapper"
}
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "title__info" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "title__info" }

import {RegisterStore, useRegisterStore} from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import {schemaUpdateRegisterOwner} from "@/utils/getMapModel";
import {IonButton} from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import {onMounted, ref, Ref} from "vue";
import i18n from "@/plugins/i18n";
import {useRegister} from "@/composables/useRegister";
import {CoreStore, useCoreStore} from "@/stores/core";
import {IUser} from "@/interfaces/login/ILoginResponse";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileOwnerDetailComponent',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const {updateManagerInfo, name, email} = useRegister();

const model: Ref<any> = ref({
    name: "user",
    fields: [
        {
            name: "name",
            label: i18n.global.t('register.fullName'),
            type: "text",
            icon: "profile",
        },
        {
            name: "email",
            label: i18n.global.t('register.email'),
            type: "email",
            icon: "sms",
        },
    ],
});

const user = ref<IUser>()

const updateInfo = (values: any) => {
  if (user.value) {
    name.value = values.name;
    email.value = values.email;
    updateManagerInfo(user.value.id).then(() => {
      coreStore.setSelectedUser(null);
    })
  }
}

onMounted(() => {
  if (coreStore.selectedUser != null) {
    user.value = coreStore.selectedUser
  } else {
    user.value = registerStore.user;
  }
})


return (_ctx: any,_cache: any) => {
  return (user.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(PhotoEditComponent, {
          "user-id": user.value.id,
          "image-id": user.value.image_id ?? -1,
          class: "my-profile-wrapper__photo",
          "go-back": ""
        }, null, 8, ["user-id", "image-id"]),
        _createElementVNode("p", _hoisted_2, _toDisplayString(user.value.prefix_number) + _toDisplayString(user.value.phone_number), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("register.fullName")), 1),
            _createElementVNode("p", null, _toDisplayString(user.value.name) + " " + _toDisplayString(user.value.last_name), 1)
          ]),
          (user.value.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("register.email")), 1),
                _createElementVNode("p", null, _toDisplayString(user.value.email), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})