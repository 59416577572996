import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "div__loading ion-flex ion-justify-content-center ion-align-items-center" }

import { Vue3Lottie } from "vue3-lottie";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import LoadingJSON from "@/assets/loading.json";
import { CoreStore, useCoreStore } from "@/stores/core";

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalLoadingComponent',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalComponent, {
    "model-value": _unref(coreStore).loading,
    noIconClose: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Vue3Lottie), {
          animationData: _unref(LoadingJSON),
          height: 200,
          width: 200
        }, null, 8, ["animationData"])
      ])
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})