import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-margin-top" }
const _hoisted_2 = { class: "join-wrapper__body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "join-wrapper__title" }
const _hoisted_5 = { class: "join-wrapper__description" }

import { IonButton, IonContent, IonLabel, IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { onMounted, ref, Ref } from "vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { APPLICATION_STATUS, MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import teamPreview from "@/assets/team-preview.svg";

interface Props {
  item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'WaitingComponent',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const location: Ref<ILocation | undefined> = ref<ILocation>();

onMounted(() => {
  try {
    const payload = {
      model: MODEL.LOCATION,
      fields: [
        "id",
        "name",
        "address",
        "country_id",
        "phone_number",
        "email",
        "local_type",
        "code",
        "entity_id",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "code",
        op: "==",
        value: props.item.location_code,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      location.value = data.items[0] as ILocation;
    });
  } catch (err) {
    console.error(err);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.item['status'] == _unref(APPLICATION_STATUS).WAITING
        ? 'action-wrapper-waiting'
        : 'action-wrapper-rejected',
    ])
  }, [
    _createVNode(_unref(IonButton), {
      fill: "clear",
      id: `open-modal${_ctx.item['location_code']}${_ctx.item['id']}`
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(VsxIcon), {
          size: "15",
          iconName: 
          _ctx.item['status'] == _unref(APPLICATION_STATUS).WAITING
            ? 'Clock'
            : 'CloseCircle'
        ,
          slot: "start"
        }, null, 8, ["iconName"])
      ]),
      _: 1
    }, 8, ["id"]),
    _createVNode(_unref(IonModal), {
      trigger: `open-modal${_ctx.item['location_code']}${_ctx.item['id']}`,
      "initial-breakpoint": 0.5,
      breakpoints: [0, 0.25, 0.5, 0.75],
      "handle-behavior": "cycle"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonContent), { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(IonLabel), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.status == _unref(APPLICATION_STATUS).WAITING
                ? _ctx.$t("joinTeam.requestWaitingMessage", {
                    name: location.value["name"],
                  })
                : _ctx.$t("joinTeam.requestRejectedMessage", {
                    name: location.value["name"],
                  })), 1)
                ]),
                _: 1
              }),
              _createElementVNode("section", _hoisted_2, [
                _createElementVNode("img", {
                  src: _unref(teamPreview),
                  alt: ""
                }, null, 8, _hoisted_3),
                _createElementVNode("h3", _hoisted_4, _toDisplayString(location.value["name"] ?? ""), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(location.value["address"]), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["trigger"])
  ], 2))
}
}

})