import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-profile-wrapper__body" }
const _hoisted_2 = { class: "header-task" }
const _hoisted_3 = { class: "ion-flex ion-align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "ion-padding-top ion-padding-horizontal"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "ion-padding-top" }

import {ref} from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import PendingListComponent from "@/components/admin/tasks/PendingListComponent.vue";
import { ENUM_TASK, MODEL, TASKS_VIEW } from "@/utils/constants";
import { useGenericStore } from "@/stores/generic";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import HeaderNavigationComponent from "../location/HeaderNavigationComponent.vue";
import ModalFiltersTask from "./ModalFiltersTask.vue";
import AdviceListComponent from "./AdviceListComponent.vue";
import { useRegister } from "@/composables/useRegister";
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  IonSearchbar,
  IonButton,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";
import { fi } from "date-fns/locale";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabListComponent',
  setup(__props, { expose: __expose }) {

const getList: Ref<any> = ref(null);
const getAdvice: Ref<any> = ref(null);
const showFilters: Ref<boolean> = ref(false);
const selected = ref(TASKS_VIEW.PENDING);
const { user } = useRegister();
const storeList = useGenericStore();
const { isPlatformIOS } = usePlatform();

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? TASKS_VIEW.PENDING;
};

const updatePendingList = () => {
  getList.value.getModel({ reset: true });
};

const updateAdviceList = () => {
  getAdvice.value.getModel({ reset: true });
};

const updatePendingListFilter = () => {
  showFilters.value = false;
  getList.value.getModel({ reset: true });
};

const filterList = (evt: any) => {
  
  storeList.setFiltersModel(MODEL.TASK, [
    {
      field: "title",
      op: "ilike",
      value: evt.target.value,
    },
    {
      field: "status",
      value:
        selected.value === TASKS_VIEW.PENDING
          ? ENUM_TASK.pending
          : ENUM_TASK.finished,
      op: "==",
    },
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ]);

  getList.value.getModel({ reset: true, showLoading: false });

  if (evt.key === 'Enter') {
    getList.value.getModel({ reset: true });
  }
};

const handleKeyDown = function (event: KeyboardEvent) {
  console.log("HandleKey", event.key);
  if (event.key === 'Enter') {
    filterList(event);
  }
}

__expose({ updatePendingList, updateAdviceList });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["ion-padding-horizontal", _unref(isPlatformIOS) ? 'safe-area' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t("tasks.list")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(IonSearchbar), {
            onIonInput: filterList,
            placeholder: _ctx.$t('global.searchByName'),
            onKeydown: _cache[0] || (_cache[0] = ($event: any) => (handleKeyDown($event)))
          }, null, 8, ["placeholder"]),
          _createVNode(_unref(IonButton), {
            class: "button-filter",
            fill: "clear",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (showFilters.value = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), { iconName: "Sort" })
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_unref(IonSegment), {
        color: "primary",
        value: selected.value,
        mode: "md",
        onIonChange: _cache[2] || (_cache[2] = ($event: any) => (optionSelected($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonSegmentButton), {
            value: _unref(TASKS_VIEW).PENDING
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tasks.pending")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_unref(IonSegmentButton), {
            value: _unref(TASKS_VIEW).FINISHED
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonLabel), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tasks.finished")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["value"])
    ], 2),
    _createElementVNode("section", null, [
      (selected.value === _unref(TASKS_VIEW).PENDING)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(AdviceListComponent, {
              ref_key: "getAdvice",
              ref: getAdvice
            }, null, 512)
          ]))
        : _createCommentVNode("", true),
      (selected.value === _unref(TASKS_VIEW).PENDING)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(PendingListComponent, {
              tab: selected.value,
              sort: "asc",
              ref_key: "getList",
              ref: getList
            }, null, 8, ["tab"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(PendingListComponent, {
              tab: selected.value,
              sort: "desc",
              ref_key: "getList",
              ref: getList
            }, null, 8, ["tab"])
          ]))
    ]),
    _withDirectives(_createVNode(ModalComponent, {
      "model-value": true,
      noIconClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(HeaderNavigationComponent, {
          preventDefault: "",
          "onClick:back": _cache[3] || (_cache[3] = ($event: any) => (showFilters.value = false)),
          title: _ctx.$t('tasks.filter')
        }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(ModalFiltersTask, {
            tab: selected.value,
            "onUpdate:filters": updatePendingListFilter,
            class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
          }, null, 8, ["tab", "class"])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, showFilters.value]
    ])
  ]))
}
}

})