import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-wrapper__best"
}
const _hoisted_2 = { class: "plan-wrapper__best__rounded" }
const _hoisted_3 = { class: "plan-wrapper__header" }
const _hoisted_4 = { class: "plan-wrapper__price" }
const _hoisted_5 = { class: "plan-wrapper__price__amount" }
const _hoisted_6 = { class: "plan-wrapper__title" }
const _hoisted_7 = { class: "plan-wrapper__include caption" }
const _hoisted_8 = { class: "plan-wrapper__options" }
const _hoisted_9 = { class: "plan-wrapper__options__option" }
const _hoisted_10 = { class: "plan-wrapper__option__icon" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "plan-wrapper__options__option" }
const _hoisted_14 = { class: "plan-wrapper__option__icon" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "plan-wrapper__options__option" }
const _hoisted_18 = { class: "plan-wrapper__option__icon" }
const _hoisted_19 = { class: "plan-wrapper__options__option" }
const _hoisted_20 = { class: "plan-wrapper__option__icon" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "plan-wrapper__options__option" }
const _hoisted_24 = { class: "plan-wrapper__option__icon" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }

import {IonButton, IonCard, IonCardContent, IonIcon} from '@ionic/vue';
import { VsxIcon } from "vue-iconsax";
import {flashOutline} from 'ionicons/icons';
import {PropType} from "vue";
import {IPlan} from "@/interfaces/plan/IPlan";
import {CoreStore, useCoreStore} from "@/stores/core";
import {PLAN_TYPE} from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanDetail',
  props: {
  plan: Object as PropType<IPlan>,
  planDisabled: {type: Boolean, default: false}
},
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

const props = __props

const coreStore: CoreStore = useCoreStore()
const emit = __emit;

const selectPlan = (plan: number) => {
  
  coreStore.setPlanType(plan)
  emit("selected", props.plan)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonCard), {
    class: _normalizeClass(["plan-wrapper", {'selected': __props.plan?.best_plan}])
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonCardContent), { class: "plan-wrapper__content" }, {
        default: _withCtx(() => [
          (__props.plan?.best_plan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(IonIcon), {
                    class: "plan-wrapper__best__icon",
                    icon: _unref(flashOutline)
                  }, null, 8, ["icon"]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('register.theMostOutstanding')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(__props.plan.price == 'free' ? '0' : __props.plan.price) + "€", 1),
              _createTextVNode("/" + _toDisplayString(_ctx.$t('global.month')), 1)
            ]),
            _createElementVNode("h2", _hoisted_6, _toDisplayString(__props.plan.name), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('register.planInclude')), 1)
          ]),
          _createElementVNode("section", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "Shop",
                  type: "linear"
                })
              ]),
              (__props.plan?.location_max == 'unlimited')
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('register.planLocalUnlimited')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$tc('register.planLocals', parseInt(__props.plan!.location_max), {local: __props.plan?.location_max})), 1))
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "People",
                  type: "linear"
                })
              ]),
              (__props.plan?.user_max == 'unlimited')
                ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t('register.planUserUnlimited')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('register.planUsers', {user: __props.plan?.user_max})), 1))
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "ProfileAdd",
                  type: "linear"
                })
              ]),
              _createElementVNode("p", null, _toDisplayString(__props.plan?.price_per_user) + "€/" + _toDisplayString(_ctx.$t('register.planCostPerUser')), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "Microphone2",
                  type: "linear"
                })
              ]),
              (__props.plan?.audio_life_time == 'unlimited')
                ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.$t('register.planMessageUnlimited')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_ctx.$t('register.planMessages', {message: __props.plan?.audio_life_time})), 1))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "TickCircle",
                  type: "linear"
                })
              ]),
              (__props.plan?.task_max == 'unlimited')
                ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString(_ctx.$t('register.planTaskBoardUnlimited')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_ctx.$t('register.planTaskBoard', {taskBoard: __props.plan?.task_max})), 1))
            ])
          ]),
          _createVNode(_unref(IonButton), {
            disabled: __props.planDisabled,
            mode: "ios",
            expand: "block",
            class: "button--select ion-margin-top",
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (selectPlan(__props.plan?.id ?? _unref(PLAN_TYPE).FREE)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('actions.select')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})