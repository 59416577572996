import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "admin__navigation__drawer" }
const _hoisted_2 = { class: "admin__user__item" }
const _hoisted_3 = { class: "admin__items" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  href: "https://wehop.app/",
  target: "_blank"
}

import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { ref } from "vue";
import { IonContent, IonMenu } from "@ionic/vue";
import { Item } from "@/interfaces/GenericModel";
import { useRouter } from "vue-router";
import links from "@/layouts/navigation/links";
import { useAuth } from "@/composables/useAuth";
import DropdownLocationMenuComponent from "@/components/shared/DropdownLocationMenuComponent.vue";
import { MODEL, ROLES } from "@/utils/constants";
import employee_links from "@/layouts/navigation/employee_links";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationComponent',
  setup(__props) {

const router = useRouter();
const { logout } = useAuth();
const { user, handleGetMe } = useRegister();
const tourStore: TourStore = useTourStore();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const { setRefreshDashboardOwner } = useCore();

const items: Ref<Array<Item>> = ref(
  user.value.role == ROLES.OWNER ? links : employee_links
);

const step2_1 = ref(null);
const step3_1 = ref(null);
const step4_1 = ref(null);
const step5_1 = ref(null);

const tour2_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour3_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour4_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour5_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const closeMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "";
  menu.style.inset = "";
  menu?.close();
};
const handleGetRoute = (to: any) => {
  switch (to) {
    case "admin.home":
      closeMenu();
      router.push({ name: to });
      break;
    case "employee.home":
      closeMenu();
      router.push({ name: to });
      break;
    case "default.talkie.list":
      if (!user.value.is_talkie) {
        tour5_1.addStep({
          attachTo: { element: step5_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTalkieTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_talkie: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour5_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour5_1.title"),
          text: i18n.global.t("tour.tour5_1.text"),
        });
        tour5_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    case "admin.locations.list":
      if (!user.value.is_teams) {
        tour2_1.addStep({
          attachTo: { element: step2_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTeamTour({
                  show: true,
                  step: 2,
                });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_teams: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour2_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour2_1.title"),
          text: i18n.global.t("tour.tour2_1.text"),
        });
        tour2_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }

      break;
    case "default.tasks.list":
      if (!user.value.is_task) {
        tour3_1.addStep({
          attachTo: { element: step3_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTaskTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_task: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour3_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour3_1.title"),
          text: i18n.global.t("tour.tour3_1.text"),
        });
        tour3_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    case "admin.groups.list":
      if (!user.value.is_members) {
        tour4_1.addStep({
          attachTo: { element: step4_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setMemberTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_members: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour4_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour4_1.title"),
          text: i18n.global.t("tour.tour4_1.text"),
        });
        tour4_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    default:
      router.push({ name: to });
      break;
  }
};
const handleLogout = () => {
  closeMenu();
  logout();
};

const handleProfile = () => {
  closeMenu();
  coreStore.setSelectedUser(null);
  router.push({ name: "myProfileOwner" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonMenu), {
    "content-id": "main-content",
    onIonDidClose: closeMenu
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(DropdownLocationMenuComponent, { "onTo:locations": closeMenu })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_unref(user).role == _unref(ROLES).OWNER)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("a", {
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleGetRoute('admin.home')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "Home" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.home")), 1)
                    ]),
                    _createElementVNode("a", {
                      ref_key: "step5_1",
                      ref: step5_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleGetRoute('default.talkie.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "VoiceCricle" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.talkie")), 1)
                    ], 512),
                    _createElementVNode("a", {
                      ref_key: "step2_1",
                      ref: step2_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleGetRoute('admin.locations.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "Shop" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.locals")), 1)
                    ], 512),
                    _createElementVNode("a", {
                      ref_key: "step3_1",
                      ref: step3_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (handleGetRoute('default.tasks.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "ClipboardText" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.tasks")), 1)
                    ], 512),
                    _createElementVNode("a", {
                      ref_key: "step4_1",
                      ref: step4_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (handleGetRoute('admin.groups.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "People" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.members")), 1)
                    ], 512)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("a", {
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (handleGetRoute('employee.home')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "Home" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.home")), 1)
                    ]),
                    _createElementVNode("a", {
                      ref_key: "step5_1",
                      ref: step5_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (handleGetRoute('default.talkie.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "VoiceCricle" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.talkie")), 1)
                    ], 512),
                    _createElementVNode("a", {
                      ref_key: "step3_1",
                      ref: step3_1,
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (handleGetRoute('default.tasks.list')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "ClipboardText" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.tasks")), 1)
                    ], 512),
                    _createElementVNode("a", {
                      ref: "step6_1",
                      href: "#",
                      "exact-active-class": "active",
                      onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (handleGetRoute('joinRequestList')), ["prevent"]))
                    }, [
                      _createVNode(_unref(VsxIcon), { iconName: "People" }),
                      _createElementVNode("span", null, _toDisplayString(_unref(i18n).global.t("global.team")), 1)
                    ], 512)
                  ])),
              _createElementVNode("div", null, [
                _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("a", _hoisted_6, [
                  _createVNode(_unref(VsxIcon), { iconName: "Sms" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("global.contact")), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(handleProfile, ["prevent"])
                }, [
                  _createVNode(_unref(VsxIcon), { iconName: "Profile" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("global.profile")), 1)
                ]),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(handleLogout, ["prevent"])
                }, [
                  _createVNode(_unref(VsxIcon), { iconName: "Logout" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("global.logout")), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})