import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import {IonAvatar} from '@ionic/vue';
import {onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";
import {getImageLetter} from "@/utils/getImageLetter";


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarLocaleComponent',
  props: {
  localeId: {
    type: Number,
    required: true
  },
  imageId: {
    type: Number as () => number | null,
    default: -1
  },
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  localeName: {
    type: String,
    required: true,
    default: ""
  },
},
  setup(__props) {

const props = __props

const photo = ref<string | null>(null);

const {getLocalePhoto} = useRegister();

onMounted(async () => {
  if (props.imageId !== null && props.imageId !== -1) {
    const data = await getLocalePhoto(props.localeId, props.imageId, true);
    if (data.url) {
      photo.value = data.url;
    } else {
      photo.value = null;
    }
  } else {
    photo.value = null;
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAvatar), {
    style: _normalizeStyle(`width:${__props.width}px;height:${__props.height}px`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: photo.value ? photo.value : _unref(getImageLetter)(__props.localeName ?? 'LO')
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})