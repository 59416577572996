import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password-wrapper__title" }
const _hoisted_2 = { class: "forgot-password-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPassword } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { useForgotPassword } from "@/composables/useForgotPassword";
import ForgotPasswordWrapper from "@/views/forgot_password/ForgotPasswordWrapper.vue";
import i18n from "@/plugins/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePasswordView',
  setup(__props) {

const { password, sendPassword } = useForgotPassword();

const model: Ref<any> = ref({
  name: "change_password",
  fields: [
    {
      name: "password",
      label: i18n.global.t("forgotPassword.newPassword"),
      type: "password",
      icon: "key",
    },
    {
      name: "confirm_password",
      label: i18n.global.t("forgotPassword.repeatPassword"),
      type: "password",
      icon: "key",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  password.value = values.password;
  await sendPassword();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ForgotPasswordWrapper, null, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("forgotPassword.changePassword")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("forgotPassword.changePasswordDescription")), 1),
      _createVNode(FormComponent, {
        model: model.value,
        schema: _unref(schemaPassword),
        "no-cancel-button": "",
        onSuccess: passwordSubmit,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.save")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema"])
    ]),
    _: 1
  }))
}
}

})