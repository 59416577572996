import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__logout_buttons" }

import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { VsxIcon } from "vue-iconsax";
import { useAuth } from "@/composables/useAuth";
import { useCoreStore, CoreStore } from "@/stores/core";
import { IonButton } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalLogoutComponent',
  setup(__props) {

const router = useRouter();
const coreStore: CoreStore = useCoreStore();
const { logout } = storeToRefs(coreStore);

const handleLogout = (value: boolean) => {
  coreStore.setLogout(value);
  router.go(1);
};

const handleLogoutUser = async () => {
  const { logout } = useAuth();
  await logout();
  setTimeout(() => {
    coreStore.setLogout(false);
  }, 1000);
  router.go(0);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalComponent, {
    "model-value": _unref(logout),
    "onUpdate:modelValue": handleLogout
  }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "modal__logout_component" }, [
        _createElementVNode("span", null, "¿Esta seguro de salir de la aplicación?")
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(IonButton), {
          color: "primary",
          mode: "ios",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleLogout(false)))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, "No", -1)
          ])),
          _: 1
        }),
        _createVNode(_unref(IonButton), {
          color: "danger",
          mode: "ios",
          onClick: handleLogoutUser
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(VsxIcon), { iconName: "Logout" }),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "salir", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})