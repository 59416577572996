import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container-list",
  slot: "content"
}

import CardItemNotification from "./CardItemNotification.vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useList } from "@/composables/useList";
import { useGenericStore, GenericStore } from "@/stores/generic";
import { MODEL } from "@/utils/constants";
import dayjs from "dayjs";
import { IonAccordionGroup, IonAccordion } from "@ionic/vue";
import { useRegister } from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdviceListComponent',
  setup(__props, { expose: __expose }) {

const model = MODEL.ADVICE;
const { getModelData } = useList();
const storeList: GenericStore = useGenericStore();
const { user } = useRegister();
storeList.setItemsModel(model, []);
storeList.setSortByModel(model, [
  {
    field: "date_start",
    order: "asc",
  },
]);

const headers = [
  {
    label: "Nombre",
    name: "name",
  },
  {
    label: "Fecha inicio",
    name: "date_start",
  },
  {
    label: "Fecha fin",
    name: "date_end",
  },
  {
    label: "User id",
    name: "created_user_id",
  },
];

const getModel = (payload: any) => {
  getModelData({
    headers: headers,
    modelName: model,
    reset: true,
    showLoading: payload?.showLoading,
  });
};

const setDefaultFilter = () => {
  storeList.setFiltersModel(MODEL.ADVICE, [
    {
      field: "date_start",
      value: dayjs().format("YYYY-MM-DD"),
      op: "<=",
    },
    {
      field: "date_end",
      value: dayjs().format("YYYY-MM-DD"),
      op: ">=",
    },
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ]);
};
setDefaultFilter();
__expose({ getModel });

return (_ctx: any,_cache: any) => {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return _withDirectives((_openBlock(), _createBlock(_unref(IonAccordionGroup), { value: "first" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonAccordion), { value: "first" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, { slot: "header" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "advice-title" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Avisos")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(ListWrapper, {
              model: _unref(model),
              headers: headers
            }, {
              item_mobile: _withCtx((props) => [
                _createVNode(CardItemNotification, {
                  advice: props,
                  "onUpdate:advices": _cache[0] || (_cache[0] = ($event: any) => (getModel({ reset: true, showLoading: true })))
                }, null, 8, ["advice"])
              ]),
              _: 1
            }, 8, ["model"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512)), [
    [_vShow, _unref(storeList).generic[`items_${_unref(model)}`].length]
  ])
}
}

})