import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-task ion-padding-top ion-padding-horizontal" }
const _hoisted_2 = { class: "ion-padding-top" }

import { ref, computed } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { MODEL, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { schemanewAdvice } from "@/utils/getMapModel";
import { useRegister } from "@/composables/useRegister";
import dayjs from "dayjs";
import {
  IonPage,
  IonChip,
  IonContent,
  IonLabel,
  IonAvatar,
  IonButton,
  toastController,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";

interface Props {
  advice: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDetailAdvice',
  props: {
    advice: {}
  },
  emits: ["click:back", "update:advices", "click:delete"],
  setup(__props: any, { emit: __emit }) {

const schema: Ref<any> = ref(schemanewAdvice);
const showModalUpdateAdvice: Ref<boolean> = ref(false);
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const props = __props;
const emit = __emit;

const model: Ref<any> = ref({
  name: MODEL.ADVICE,
  fields: [
    {
      name: "name",
      label: "Nombre del aviso",
      type: "textarea",
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
      default: props.advice.date_start,
    },
    {
      name: "date_end",
      label: "Fecha de finalización",
      type: "date",
      default: props.advice.date_end,
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const updateAdvice = async () => {
  showModalUpdateAdvice.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("advice.updated_advice"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  emit("update:advices");
};

const dateFinish = computed(() => {
  const date = dayjs(props.advice.date_end);
  return `Finaliza el día ${date.format("DD")} de ${date.format(
    "MMM"
  )} del ${date.format("YYYY")}`;
});

const canOptionsMenu = computed(() => {
  return (
    props.advice.created_user_id === user.value.id ||
    user.value.role !== ROLES.EMPLOYEE
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (canOptionsMenu.value)
          ? (_openBlock(), _createBlock(DropdownMenuTaskComponent, {
              key: 0,
              "text-edit": "advice.edit",
              "text-delete": "advice.delete",
              "onClick:delete": _cache[0] || (_cache[0] = ($event: any) => (emit('click:delete'))),
              "onClick:edit": _cache[1] || (_cache[1] = ($event: any) => (showModalUpdateAdvice.value = true))
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(IonContent), { class: "content-task ion-padding-horizontal" }, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.advice.name), 1),
          _createVNode(_unref(IonChip), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonAvatar), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), { iconName: "Calendar" })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonLabel), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(dateFinish.value), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(ModalComponent, {
        noIconClose: "",
        modelValue: showModalUpdateAdvice.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showModalUpdateAdvice).value = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(HeaderNavigationComponent, {
            preventDefault: "",
            "onClick:back": _cache[2] || (_cache[2] = ($event: any) => (showModalUpdateAdvice.value = false)),
            title: 'Editar Aviso',
            class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
          }, null, 8, ["class"]),
          _createElementVNode("div", _hoisted_2, [
            (showModalUpdateAdvice.value)
              ? (_openBlock(), _createBlock(FormComponent, {
                  key: 0,
                  "no-cancel-button": "",
                  model: model.value,
                  "form-mode": "update",
                  "id-model": _ctx.advice.id,
                  schema: schema.value,
                  onSuccess: updateAdvice
                }, {
                  submitButton: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      mode: "ios",
                      expand: "block",
                      type: "submit"
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Guardar ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "id-model", "schema"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})