import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "register-wrapper__back" }
const _hoisted_2 = { class: "register-wrapper__body" }
const _hoisted_3 = { class: "register-wrapper__logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "register-wrapper__title" }

import {IonContent, IonPage, IonBackButton, IonList, IonItem} from "@ionic/vue";
import {storefrontOutline, peopleOutline} from 'ionicons/icons';
import wehopLogoCircle from "@/assets/wehop-logo-circle.svg";
import RegisterOption from "@/components/RegisterOption.vue";
import {USER_TYPES} from "@/utils/constants";
import {usePlatform} from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterView',
  setup(__props) {

const { isPlatformIOS } = usePlatform();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding register-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: "Darse de alta",
              color: "neutral-80",
              defaultHref: "/auth/login"
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _unref(wehopLogoCircle),
                alt: ""
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('register.whatDoYouWantToDo')), 1)
          ]),
          _createVNode(_unref(IonList), {
            lines: "inset",
            class: "register-wrapper__options"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonItem), null, {
                default: _withCtx(() => [
                  _createVNode(RegisterOption, {
                    icon: _unref(storefrontOutline),
                    title: "Quiero crear mi equipo",
                    description: "Busco poder crear y gestionar mi equipo.",
                    to: "registerStore",
                    "user-type": _unref(USER_TYPES).OWNER
                  }, null, 8, ["icon", "user-type"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonItem), null, {
                default: _withCtx(() => [
                  _createVNode(RegisterOption, {
                    icon: _unref(peopleOutline),
                    title: "Quiero unirme a un equipo",
                    description: "Quiero formar parte de un equipo y compartir con sus miembros.",
                    to: "registerEmployee",
                    "user-type": _unref(USER_TYPES).EMPLOYEE
                  }, null, 8, ["icon", "user-type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})