import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "business-wrapper__header" }
const _hoisted_2 = { class: "business-wrapper__title" }
const _hoisted_3 = { class: "business-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaRegisterEntityFinal } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";
import { useShepherd } from "vue-shepherd";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterBusinessInfoFinalStepView',
  setup(__props) {

const { email, phone, sendEntityInfo, number_employees, number_locals } =
  useRegister();
const { isPlatformIOS } = usePlatform();

const tour1_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "text",
      icon: "sms",
    },
    {
      name: "phone",
      label: i18n.global.t("register.phone"),
      type: "text",
      icon: "call",
    },
    {
      name: "number_locals",
      label: i18n.global.t("register.locals"),
      type: "text",
      icon: "copy",
    },
    {
      name: "number_employees",
      label: i18n.global.t("register.employees"),
      type: "text",
      icon: "people",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  email.value = values.email;
  phone.value = values.phone;
  number_employees.value = values.number_employees;
  number_locals.value = values.number_locals;
  await sendEntityInfo();
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_2.value, on: "bottom" },
    title: i18n.global.t("tour.tour1_2.title"),
    text: i18n.global.t("tour.tour1_2.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "total-steps": 4,
    "show-progress": false,
    "title-button-back": _ctx.$t('register.signUp'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("register.businessInfo")), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("register.businessInfoDescriptionFinalStep")), 1)
        ]),
        _createVNode(FormComponent, {
          model: model.value,
          schema: _unref(schemaRegisterEntityFinal),
          "no-cancel-button": "",
          onSuccess: businessInfoSubmit,
          "prevent-default": ""
        }, {
          submitButton: _withCtx(() => [
            _createElementVNode("div", {
              ref_key: "tour1_2",
              ref: tour1_2,
              style: {"margin":"0px","padding":"0px","width":"100%"}
            }, [
              _createVNode(_unref(IonButton), {
                mode: "ios",
                expand: "block",
                class: "button--login ion-margin-top",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("actions.continue")), 1)
                ]),
                _: 1
              })
            ], 512)
          ]),
          _: 1
        }, 8, ["model", "schema"])
      ])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})