import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {computed, reactive, watch} from "vue";
import {Item} from "@/interfaces/GenericModel";
import ListComponentDesktop from "@/components/shared/list/ListComponentDesktop.vue";
import ListComponentMobile from "@/components/shared/list/ListComponentMobile.vue";
import {useList} from "@/composables/useList";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {useGeneric} from "@/composables/useGeneric";

interface Props {
  headers: Array<Item>;
  hideHeaders?: Array<string> | [] | undefined;
  model: string;
  filters?: Array<Item>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListWrapper',
  props: {
    headers: {},
    hideHeaders: {},
    model: {},
    filters: {}
  },
  setup(__props: any) {

const props = __props;
const {generic, getModelData} = useList();
const {refresh} = useGeneric();
const {isMobile} = useNativePlatform();
const {setRefresh} = useGeneric();

const defaultPagination = reactive({
  page: 1,
  total: 1,
  size: 10,
});

const pagination = computed(() => {
  const pagination = generic.value[`pagination_${props.model}`];
  return pagination || defaultPagination;
});

const data = computed(() => {
  return generic.value[`items_${props.model}`] || [];
});

getModelData({headers: props.headers, modelName: props.model});

const handleGetDataPage = (payload: any) => {
  const {page} = payload || {};
  getModelData({headers: props.headers, modelName: props.model, page});
};


watch(refresh, () => {
  getModelData({headers: props.headers, modelName: props.model});
});



return (_ctx: any,_cache: any) => {
  return (_unref(isMobile))
    ? (_openBlock(), _createBlock(ListComponentMobile, _mergeProps({ key: 0 }, _ctx.$attrs, {
        items: data.value,
        total: pagination.value?.total,
        page: pagination.value?.page,
        "hide-headers": _ctx.hideHeaders,
        onPage: handleGetDataPage
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
            ])
          }
        })
      ]), 1040, ["items", "total", "page", "hide-headers"]))
    : (_openBlock(), _createBlock(ListComponentDesktop, _mergeProps({
        key: 1,
        onPage: handleGetDataPage
      }, _ctx.$attrs, {
        items: data.value,
        headers: _ctx.headers,
        "hide-headers": _ctx.hideHeaders,
        pagination: pagination.value
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
            ])
          }
        })
      ]), 1040, ["items", "headers", "hide-headers", "pagination"]))
}
}

})