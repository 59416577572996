import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "join-wrapper__back" }
const _hoisted_2 = { class: "join-wrapper__body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "join-wrapper__title" }
const _hoisted_5 = { class: "join-wrapper__description" }
const _hoisted_6 = { class: "join-wrapper__confirm" }
const _hoisted_7 = { class: "join-wrapper__confirm bold" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import { IonBackButton, IonButton, IonContent, IonPage } from "@ionic/vue";

import { useJoin } from "@/composables/useJoin";
import { onMounted, ref } from "vue";
import defaultPhoto from "@/assets/default_photo.svg";
import { useRegister } from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinPreviewView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const { sendApplication } = useJoin();
const { getLocalePhoto } = useRegister();
const photo = ref<string | null>(null);


const img = ref<string | ArrayBuffer | null>(
  `https://ui-avatars.com/api/?name=${registerStore.location.name.charAt(0)}${registerStore.location.name.charAt(1)}&size=400&rounded=true`
);

const sendRequest = () => {
  sendApplication();
};

onMounted(async () => {
  if (
    registerStore.location.image_id > 0 &&
    registerStore.location.image_id != null
  ) {
    const data = await getLocalePhoto(
      registerStore.location.id!,
      registerStore.location.image_id
    );
    if (data) {
      photo.value = `data:image/png;base64,${data}`;
    } else {
      photo.value = null;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('joinTeam.youWillJoin'),
              color: "neutral-80",
              defaultHref: "/join"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("img", {
              src: photo.value ? photo.value : _unref(defaultPhoto),
              style: {"width":"120px"}
            }, null, 8, _hoisted_3),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(registerStore).location.name ?? ""), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(registerStore).location.address ?? ""), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("joinTeam.joinConfirm")), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("joinTeam.joinConfirmSecond")), 1),
            _createVNode(_unref(IonButton), {
              mode: "ios",
              expand: "block",
              class: "button-validate ion-margin-top",
              type: "button",
              onClick: sendRequest,
              fill: "solid"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("actions.sendRequest")), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})