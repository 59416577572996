import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "business-wrapper__title" }
const _hoisted_2 = { class: "business-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaRegisterEntity } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterBusinessInfoView',
  setup(__props) {

const {
  name,
  commercial_name,
  address,
  vat,
  city_id,
  country_id,
  saveEntityInfo,
} = useRegister();

const { isPlatformIOS } = usePlatform();

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.businessName"),
      type: "text",
      icon: "Shop",
    },
    {
      name: "commercial_name",
      label: i18n.global.t("register.companyName"),
      type: "text",
      icon: "Edit",
    },
    {
      name: "vat",
      label: "CIF / NIF",
      type: "text",
      icon: "ClipboardText",
    },
    {
      name: "address",
      label: i18n.global.t("register.address"),
      type: "text",
      icon: "Location",
    },
    {
      icon: "Send2",
      name: "country_id",
      type: "autocomplete",
      label: i18n.global.t("register.country"),
      model: "country",
      field_search: "iso_code",
    },
    {
      icon: "Location",
      name: "city_id",
      type: "autocomplete",
      label: i18n.global.t("register.city"),
      model: "city",
      depends_on: "country",
      foreign_key: "iso_code",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  name.value = values.name;
  commercial_name!.value = values.commercial_name;
  address!.value = values.address;
  city_id!.value = values.city_id;
  country_id!.value = values.country_id;
  vat!.value = values.vat;
  await saveEntityInfo();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "total-steps": 4,
    "show-progress": false,
    "title-button-back": _ctx.$t('register.signUp'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("register.businessInfo")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("register.businessInfoDescription")), 1),
      _createVNode(FormComponent, {
        model: model.value,
        schema: _unref(schemaRegisterEntity),
        "no-cancel-button": "",
        onSuccess: businessInfoSubmit,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.continue")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema"])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})