import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "gradient"
}

import { getPhotoUrl } from "@/services/me";
import { VsxIcon } from "vue-iconsax";
import { getImageLetter } from "@/utils/getImageLetter";
import { IonAvatar } from "@ionic/vue";
import { computed, onMounted, PropType, ref } from "vue";
import { IUser } from "@/interfaces/login/ILoginResponse";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPhoto',
  props: {
  listMode: {
    type: Boolean,
    required: true,
  },
  item: {
    type: Object as PropType<IUser>,
    required: true,
  },
  itemRecording: {
    type: Number,
    required: true,
  },
  showRecording: {
    type: Boolean,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const listAvatarStyle = computed(() => {
  return !props.listMode
    ? ""
    : "width: 55px !important;height: 55px !important;";
});

const photoUrl = ref<string>(""); // Initialize with an empty string

async function fetchPhoto() {
  if (props.item.image_id) {
    try {
      getPhotoUrl(props.item.id, props.item.image_id, true).then((data) => {
        photoUrl.value = data.url;
      });
    } catch (error) {
      console.error("Error fetching photo:", error);
    }
  } else {
    photoUrl.value = getImageLetter(props.item.name);
  }
}

onMounted(() => {
  fetchPhoto();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (photoUrl.value)
      ? (_openBlock(), _createBlock(_unref(IonAvatar), {
          key: 0,
          style: _normalizeStyle(listAvatarStyle.value)
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: photoUrl.value }, null, 8, _hoisted_1),
            (__props.item.image_id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true),
            (__props.showRecording)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(__props.item.id == __props.itemRecording ? 'icon-recording' : 'icon')
                }, [
                  _createVNode(_unref(VsxIcon), {
                    iconName: __props.item.id == __props.itemRecording ? 'Sound' : 'Microphone2',
                    color: __props.item.image_id ? 'white' : '#4178F5'
                  }, null, 8, ["iconName", "color"])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true)
  ]))
}
}

})