import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start" }
const _hoisted_3 = { class: "ion-padding-start" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuAudioComponent',
  emits: ["click:detail", "click:button", "click:mark"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const emitClick = (event: any) => {
  emit(event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:detail')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "InfoCircle",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('talkie.detail')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:button')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Maximize",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('talkie.showButton')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emitClick('click:mark')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Messages",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('talkie.markAsListened')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})