import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ion-padding ion-justify-content-start" }
const _hoisted_2 = { align: "left" }
const _hoisted_3 = {
  key: 0,
  class: "check-tr"
}
const _hoisted_4 = {
  key: 0,
  class: "check-tr"
}
const _hoisted_5 = { class: "pagination" }
const _hoisted_6 = ["onClick"]

import { ref, onMounted, watch, computed, toRefs } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import { Item, Pagination } from "@/interfaces/GenericModel";
import { IonCheckbox } from "@ionic/vue";
import { calculatePagesCount } from "@/utils/calculatePageCount";

interface Props {
  items: Array<Item>;
  hideHeaders: Array<string> | [] | undefined;
  headers: Array<Item>;
  pagination: Pagination;
  checkable?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListComponentDesktop',
  props: {
    items: {},
    hideHeaders: {},
    headers: {},
    pagination: {},
    checkable: { type: Boolean }
  },
  emits: ["page"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const totalPage: Ref<number[]> = ref([]);
const emit = __emit;
const paginationProps = toRefs(props).pagination;
const classDynamic = (item: any) => {
  const classD = [];
  if (item?.disabled) {
    classD.push("disabled-tr");
  }
  if (item?.selected) {
    classD.push("selected-tr");
  }
  return classD.join(" ");
};

const selectPage = (page: number) => {
  emit("page", { page });
};

const mapPagination = computed(() => {
  const page = props.pagination.page;
  if (page > 3) {
    return totalPage.value.slice(page - 3, page + 2);
  }
  return totalPage.value.slice(0, 5);
});

const setPagination = () => {
  if (props.pagination.total) {
    const cal = calculatePagesCount(
      props.pagination.size,
      props.pagination.total
    );
    totalPage.value = Array.from({ length: cal }, (_, i) => i + 1);
  }
};

watch(paginationProps, () => {
  setPagination();
});
onMounted(() => {
  setPagination();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", _hoisted_2, [
        _createElementVNode("tr", null, [
          (_ctx.checkable)
            ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
                _createVNode(_unref(IonCheckbox))
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (item, index) => {
            return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(item.label), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index,
            class: _normalizeClass(classDynamic(item?.extraData))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (_ctx.checkable && index === 0)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                      _createVNode(_unref(IonCheckbox), {
                        "data-check": item?.extraData?.selected || false,
                        checked: item?.extraData?.selected || false
                      }, null, 8, ["data-check", "checked"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("td", null, [
                  _renderSlot(_ctx.$slots, `${header.name}_desktop`, _mergeProps({ ref_for: true }, item), () => [
                    _createTextVNode(_toDisplayString(item[header.name]), 1)
                  ])
                ])
              ], 64))
            }), 128))
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["pagination--next", { 'disabled-a': _ctx.pagination.page === 1 }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (selectPage(_ctx.pagination.page - 1)), ["prevent"]))
      }, [
        _createVNode(_unref(VsxIcon), {
          iconName: "ArrowLeft",
          type: "linear"
        })
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mapPagination.value, (index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: index,
          href: "#",
          class: _normalizeClass(["pagination--page", { active: index === _ctx.pagination.page }]),
          onClick: _withModifiers(($event: any) => (selectPage(index)), ["prevent"])
        }, _toDisplayString(index), 11, _hoisted_6))
      }), 128)),
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["pagination--back", {
          'disabled-a': _ctx.pagination.page === totalPage.value[totalPage.value.length - 1],
        }]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (selectPage(_ctx.pagination.page + 1)), ["prevent"]))
      }, [
        _createVNode(_unref(VsxIcon), {
          iconName: "ArrowRight",
          type: "linear"
        })
      ], 2)
    ])
  ]))
}
}

})