import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "photo-edit-wrapper" }
const _hoisted_2 = { class: "photo-edit-wrapper__icon" }

import { IonAvatar, IonButton, IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { ref } from "vue";
import LocaleUploadPhotoView from "@/views/admin/location/LocaleUploadPhotoView.vue";
import AvatarCurrentLocaleComponent from "@/components/shared/avatar/AvatarCurrentLocaleComponent.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'PhotoLocalComponent',
  setup(__props) {

const modal = ref();

const closeDialog = async (values: any) => {
  modal.value.$el.dismiss();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AvatarCurrentLocaleComponent, {
      width: 120,
      height: 120,
      "is-new": ""
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(IonButton), {
        shape: "circle",
        class: "photo-edit-wrapper__icon__button",
        mode: "ios",
        expand: "block",
        type: "button",
        id: "open-modal",
        name: "confirm",
        color: "primary-90-shade"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(VsxIcon), { iconName: "Camera" })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonModal), {
        ref_key: "modal",
        ref: modal,
        trigger: "open-modal",
        "initial-breakpoint": 1,
        breakpoints: [0, 0.25, 0.5, 0.75]
      }, {
        default: _withCtx(() => [
          _createVNode(LocaleUploadPhotoView, { onClose: closeDialog })
        ]),
        _: 1
      }, 512)
    ])
  ]))
}
}

})