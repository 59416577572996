import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import {IonAvatar} from '@ionic/vue';
import defaultPhoto from "@/assets/avatar_default.svg";
import defaultPhotoAdd from "@/assets/default_photo.svg";
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {CoreStore, useCoreStore} from "@/stores/core";
import {IUser} from "@/interfaces/login/ILoginResponse";



export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarMemberComponent',
  props: {
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  isNew: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props

const photo = ref<string | null>(props.isNew ? defaultPhotoAdd : defaultPhoto);
const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const {getPhoto, userSelectedPhoto} = useRegister();
const userData = ref<IUser|null>();

watch(userSelectedPhoto, () => {
  if (userSelectedPhoto.value !== "") {
    photo.value = userSelectedPhoto.value !== ""? userSelectedPhoto.value : defaultPhoto;
  }
});

onMounted(async () => {
  userData.value = coreStore.selectedUser ?? null;
  if (userData.value?.image_id == null) {
    photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
  } else {
    if (registerStore.userSelectedPhoto == "") {
      const data = await getPhoto(userData.value.id, userData.value.image_id, true);
      if (data.url) {
        registerStore.setUserSelectedPhoto(data.url);
        photo.value = data.url;
      } else {
        photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
      }
    } else {
      photo.value = registerStore.userSelectedPhoto !== ""? registerStore.userSelectedPhoto : defaultPhoto;
    }
  }
})


return (_ctx: any,_cache: any) => {
  return (userData.value)
    ? (_openBlock(), _createBlock(_unref(IonAvatar), {
        key: 0,
        class: "avatar-wrapper",
        style: _normalizeStyle(`width:${__props.width}px;height:${__props.height}px`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: photo.value,
            alt: userData.value.first_name
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})