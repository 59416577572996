import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "join-wrapper__back" }
const _hoisted_2 = { class: "join-wrapper__body" }

import { useNativePlatform } from "@/composables/useNativePlatform";

import {
  IonBackButton,
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  SegmentCustomEvent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import router from "@/router";
import { ref, computed } from "vue";
import ApplicationRequestComponent from "@/components/shared/applications/ApplicationRequestComponent.vue";
import ApplicationActiveComponent from "@/components/shared/applications/ApplicationActiveComponent.vue";
import { APPLICATION_STATUS } from "@/utils/constants";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";

export default /*@__PURE__*/_defineComponent({
  __name: 'JoinRequestListView',
  setup(__props) {

const { setJoinRequestTabSelected } = useCore();

const coreStore: CoreStore = useCoreStore()

const selected = computed(() => {
  return coreStore.tabJoinRequestSelected;
});

const applicationSelected = (event: SegmentCustomEvent) => {
  setJoinRequestTabSelected(event.detail.value);
};

const handleRefresh = () => {
  setTimeout(() => {
    router.go(0);
  }, 500);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(WrapperPageComponent, null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonRefresher), {
                slot: "fixed",
                onIonRefresh: handleRefresh
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonRefresherContent))
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(IonBackButton), {
                  text: _ctx.$t('joinTeam.myWorkspaces'),
                  color: "neutral-80",
                  defaultHref: "/join"
                }, null, 8, ["text"])
              ]),
              _createElementVNode("section", _hoisted_2, [
                _createVNode(_unref(IonSegment), {
                  color: "primary",
                  value: selected.value,
                  mode: "md",
                  onIonChange: _cache[0] || (_cache[0] = ($event: any) => (applicationSelected($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonSegmentButton), {
                      value: _unref(APPLICATION_STATUS).ACCEPTED
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonLabel), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.actives")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _createVNode(_unref(IonSegmentButton), {
                      value: _unref(APPLICATION_STATUS).WAITING
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonLabel), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.requests")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("section", null, [
                (selected.value == _unref(APPLICATION_STATUS).ACCEPTED)
                  ? (_openBlock(), _createBlock(ApplicationActiveComponent, { key: 0 }))
                  : _createCommentVNode("", true),
                (selected.value == _unref(APPLICATION_STATUS).WAITING)
                  ? (_openBlock(), _createBlock(ApplicationRequestComponent, { key: 1 }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})