import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { IonApp, IonRouterOutlet } from "@ionic/vue";
import ModalLogoutComponent from "./components/shared/ModalLogoutComponent.vue";
import ModalLoadingComponent from "./components/shared/modal/ModalLoadingComponent.vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import {enableBackgroundMode} from "@/services/background_services";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { isMobile } = useNativePlatform();

enableBackgroundMode();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRouterOutlet)),
      _createVNode(ModalLogoutComponent),
      (_unref(isMobile))
        ? (_openBlock(), _createBlock(ModalLoadingComponent, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})