import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "welcome-wrapper__image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "welcome-wrapper__body" }
const _hoisted_4 = { class: "welcome-wrapper__logo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "welcome-wrapper__title" }
const _hoisted_7 = { class: "welcome-wrapper__subtitle text-body-s" }
const _hoisted_8 = { class: "welcome-wrapper__actions" }

import { IonButton, IonContent, IonPage } from "@ionic/vue";
import welcomeBackground from "@/assets/welcome_background.png";
import wehopLogo from "@/assets/wehop-logo.svg";
import { App } from "@capacitor/app";
import { onMounted, ref } from "vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { usePlatform } from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'WelcomeView',
  setup(__props) {

const { isMobile } = useNativePlatform();
const { isPlatformIOS } = usePlatform();

const appVersion = ref<string>();

onMounted(() => {
  if (isMobile) {
    App.getInfo().then((info) => {
      appVersion.value = isPlatformIOS
        ? info.version + "." + info.build
        : info.version;
    });
  } else {
    appVersion.value = "1.0.122";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "welcome-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _unref(welcomeBackground),
              alt: ""
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _unref(wehopLogo),
                alt: ""
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("welcome.title")), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("welcome.subtitle")), 1),
            _createElementVNode("section", _hoisted_8, [
              _createVNode(_unref(IonButton), {
                class: "welcome-wrapper__register",
                fill: "outline",
                expand: "block",
                "router-link": "/register"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("welcome.register")), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(IonButton), {
                class: "welcome-wrapper__login",
                expand: "block",
                "router-link": "/auth/login"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("welcome.login")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          (appVersion.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["caption app-version", _unref(isPlatformIOS) ? 'version-ios' : 'version-android'])
              }, " v" + _toDisplayString(appVersion.value), 3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})