import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password-wrapper__title form-padding" }
const _hoisted_2 = { class: "forgot-password-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPinCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import { USER_TYPES } from "@/utils/constants";
import { usePlatform } from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPinCodeView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();
const { isPlatformIOS } = usePlatform();

const { pin, pinCodeValid, sendPin, savePin } = useRegister();
const form: Ref<any> = ref(null);
const model: Ref<any> = ref({
  name: "pin_code",
  fields: [
    {
      name: "pin",
      label: "Escribe el código de verificación",
      type: "pin",
    },
  ],
});

const pinCodeSubmit = async (values: any) => {
  pin.value = values.pin;
  pinCodeValid.value = true;
  if (coreStore.userType == USER_TYPES.EMPLOYEE) {
    await sendPin().then(() => {
      form.value.handleResetForm();
    });
  } else {
    await savePin().then(() => {
      form.value.handleResetForm();
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-progress": false,
    "title-button-back": _ctx.$t('register.createProfile'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("register.verificationCode")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("register.verificationCodeDescription")), 1),
      _createVNode(FormComponent, {
        ref_key: "form",
        ref: form,
        model: model.value,
        schema: _unref(schemaPinCode),
        "no-cancel-button": "",
        onSuccess: pinCodeSubmit,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("register.continue")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema"])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})