import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-list" }

import { Task } from "@/interfaces/tasks/ITasks";
import CardItemTask from "./CardItemTask.vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useList } from "@/composables/useList";
import { useGenericStore, GenericStore } from "@/stores/generic";
import { ENUM_TASK, MODEL, TASKS_VIEW } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";

interface Props {
  tab: string;
  sort: {
    type: string,
    default: "desc"
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PendingListComponent',
  props: {
    tab: {},
    sort: {}
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;
const model = MODEL.TASK;
const { user } = useRegister();
const { getModelData } = useList();
const storeList: GenericStore = useGenericStore();
storeList.setItemsModel(model, []);
storeList.setSortByModel(MODEL.TASK, [
  {
    field: "date_start",
    order: props.sort,
  },
]);

const headers = [
  {
    label: "Titulo",
    name: "title",
  },
  {
    label: "Descripción",
    name: "description",
  },
  {
    label: "Local",
    name: "location_id",
  },
  {
    label: "Usuario asignado",
    name: "assigned_user_id",
  },
  {
    label: "Es prioridad",
    name: "is_priority",
  },
  {
    label: "Status",
    name: "status",
  },
  {
    label: "Fecha de creación",
    name: "date_start",
  },
  {
    label: "Comentarios",
    name: "comments",
  },
  {
    label: "Created_by",
    name: "created_user_id",
  },
  {
    label: "Finalized_by",
    name: "finalized_user_id",
  },
];
const getModel = (payload: any) => {
  getModelData({
    headers: headers,
    modelName: model,
    reset: true,
    showLoading: payload?.showLoading,
  });
};

const setDefaultFilter = () => {
  storeList.setFiltersModel(MODEL.TASK, [
    {
      field: "status",
      value:
        props.tab === TASKS_VIEW.PENDING
          ? ENUM_TASK.pending
          : ENUM_TASK.finished,
      op: "==",
    },
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ]);
};
setDefaultFilter();
__expose({ getModel });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ListWrapper, {
      model: _unref(model),
      headers: headers
    }, {
      item_mobile: _withCtx((props) => [
        _createVNode(CardItemTask, {
          task: (props as Task),
          "onUpdate:tasks": _cache[0] || (_cache[0] = ($event: any) => (getModel({ reset: true, showLoading: true })))
        }, null, 8, ["task"])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})