import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "phone-wrapper" }

import { IonInput, IonSelect, IonSelectOption } from "@ionic/vue";
import { ref, computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneNumberComponent',
  props: {
  value: {
    type: String,
  },
  placeholder: {
    type: String,
    required: true,
  },
},
  emits: ["update:phoneNumber"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const prefix = ref("+34");

const valueInput = computed(() => {
  return props.value?.split("|")[1];
});

const setValue = (e: any) => {
  const phone = prefix.value + "|" + e.target.value;
  emit("update:phoneNumber", phone);
};
const setPrefix = (e: any) => {
  prefix.value = e.target.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_unref(IonSelect), {
      "aria-label": "Pais",
      interface: "popover",
      style: {"width":"25%"},
      value: "+34",
      onIonChange: setPrefix,
      class: "prefix-wrapper"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonSelectOption), { value: "+34" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("+34")
          ])),
          _: 1
        }),
        _createVNode(_unref(IonSelectOption), { value: "+57" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("+57")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonInput), {
      value: valueInput.value,
      style: {"width":"75%"},
      placeholder: __props.placeholder,
      type: "tel",
      onIonBlur: setValue,
      class: "phone-number-wrapper"
    }, null, 8, ["value", "placeholder"])
  ]))
}
}

})