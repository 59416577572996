import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "more" }
const _hoisted_2 = { class: "my-profile-wrapper__body" }
const _hoisted_3 = { class: "ion-padding" }

import {
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  toastController,
} from "@ionic/vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { Share } from "@capacitor/share";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import i18n from "@/plugins/i18n";
import DropdownMenuGroupComponent from "@/components/admin/group/DropdownMenuGroupComponent.vue";
import { GROUP_OWNER_VIEW } from "@/utils/constants";
import {computed, ref} from "vue";
import GroupRequestComponent from "@/components/admin/group/GroupRequestComponent.vue";
import GroupMemberComponent from "@/components/admin/group/GroupMemberComponent.vue";
import { useRegister } from "@/composables/useRegister";
import { Clipboard } from "@capacitor/clipboard";
import {usePlatform} from "@/composables/usePlatform";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupListView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const {isPlatformIOS} = usePlatform()
const { sendInvitationLink } = useRegister();
const { setGroupTabSelected } = useCore();

const coreStore: CoreStore = useCoreStore()

const selected = computed(() => {
  return coreStore.tabGroupSelected;
});

const optionSelected = (event: SegmentCustomEvent) => {
  setGroupTabSelected(event.detail.value ?? "");
};

const copyCode = async () => {
  await Clipboard.write({
    string: registerStore.location.code ?? "",
  });
  const toast = await toastController.create({
    message: i18n.global.t("global.codeCopied"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
};

const shareCode = async () => {
  await Share.share({
    title: i18n.global.t("global.shareCodeTitle"),
    text: i18n.global.t("global.shareCodeDescription", {
      platform: isPlatformIOS ? "iOS" : "Android",
      name: registerStore.user.name,
      code: registerStore.location.code ?? "",
    }),
    dialogTitle: i18n.global.t("actions.share"),
  });
  await sendInvitationLink(
    registerStore.user.phone_number,
    registerStore.location.code!
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createVNode(WrapperPageComponent, null, {
        options: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(DropdownMenuGroupComponent, {
              "onClick:copy": copyCode,
              "onClick:share": shareCode
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(IonSegment), {
                color: "primary",
                value: selected.value,
                mode: "md",
                onIonChange: _cache[0] || (_cache[0] = ($event: any) => (optionSelected($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonSegmentButton), {
                    value: _unref(GROUP_OWNER_VIEW).MEMBERS
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("group.members")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  _createVNode(_unref(IonSegmentButton), {
                    value: _unref(GROUP_OWNER_VIEW).REQUESTS
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("group.requests")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _createElementVNode("section", null, [
              (selected.value == _unref(GROUP_OWNER_VIEW).MEMBERS)
                ? (_openBlock(), _createBlock(GroupMemberComponent, { key: 0 }))
                : _createCommentVNode("", true),
              (selected.value == _unref(GROUP_OWNER_VIEW).REQUESTS)
                ? (_openBlock(), _createBlock(GroupRequestComponent, { key: 1 }))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})