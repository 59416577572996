import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { IonAvatar } from '@ionic/vue';
import defaultPhoto from "@/assets/default_photo.svg";
import {onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarComponent',
  props: {
    userId: {
        type: Number,
        required: true
    },
    imageId: {
        type: Number,
        required: true,
        default: -1
    },
},
  setup(__props) {

const props = __props

const photo = ref<string | null>(null);

const {getPhoto} = useRegister();

onMounted(async () => {
    if (props.imageId !== null && props.imageId!== -1) {
        const data = await getPhoto(props.userId, props.imageId, true);
        if (data.url) {
            photo.value = data.url;
        } else {
            photo.value = null;
        }
    } else {
        photo.value = null;
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAvatar), null, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: photo.value ? photo.value : _unref(defaultPhoto),
        alt: ""
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}
}

})