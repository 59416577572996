import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import { IonAvatar } from "@ionic/vue";
import defaultPhoto from "@/assets/avatar_default.svg";
import defaultPhotoAdd from "@/assets/default_photo.svg";
import { onMounted, ref, watch } from "vue";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarUserComponent',
  props: {
  width: {
    type: Number,
    default: 45,
  },
  height: {
    type: Number,
    default: 45,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const photo = ref<string | null>(props.isNew ? defaultPhotoAdd : defaultPhoto);
const registerStore: RegisterStore = useRegisterStore();
const { getPhoto, user, userPhoto } = useRegister();

watch(userPhoto, () => {
  if (userPhoto.value) {
    photo.value =
      registerStore.userPhoto !== "" ? registerStore.userPhoto : defaultPhoto;
  }
});

onMounted(async () => {
  if (user.value.image_id == null) {
    photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
  } else {
    //Logged User
    if (registerStore.userPhoto == "") {
      const data = await getPhoto(user.value.id, user.value.image_id, true);
      if (data.url) {
        registerStore.setUserPhoto(data.url);
        photo.value = data.url;
      } else {
        photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
      }
    } else {
      photo.value =
        registerStore.userPhoto !== "" ? registerStore.userPhoto : defaultPhoto;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_unref(user))
    ? (_openBlock(), _createBlock(_unref(IonAvatar), {
        key: 0,
        class: "avatar-wrapper",
        style: _normalizeStyle(`width:${__props.width}px;height:${__props.height}px`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: photo.value,
            alt: _unref(user).name
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})