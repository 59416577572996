import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "fab-record" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "fab-record fab-disabled fab-no-recording" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "fab-record" }
const _hoisted_8 = { class: "tabs" }
const _hoisted_9 = { class: "tab tab--left" }
const _hoisted_10 = { class: "tab tab--right" }

import { useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import { ref, computed, onMounted } from "vue";
import type { Ref } from "vue";
import useRecord from "@/composables/useRecord";
import { useRegister } from "@/composables/useRegister";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { TourStore, useTourStore } from "@/stores/tour";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecordBarComponent',
  props: {
  showAllOptions: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {



const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();

const { recording, listening, userId, toggleRecord } = useRecord();
const { isPlatformIOS } = usePlatform();
const { user, handleGetMe } = useRegister();

const router = useRouter();

const disabledRecord: Ref<boolean> = ref(false);

const recordingClass = computed(() => {
  if (disabledRecord.value) return "fab-disabled";
  return recording.value ? "fab-recording" : "fab-no-recording";
});

const recordingIcons = computed(() => {
  if (disabledRecord.value) return "MicrophoneSlash";
  return recording.value ? "Sound" : "Microphone2";
});

const userRecording = computed(() => {
  return userId.value;
});

const tour5_2 = ref(null);
const tour5_3 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const handleRecordAudio = () => {
  toggleRecord({
    nameUser: "Todos",
    durationAudio: 2,
    mode: "all",
    userId: user.value.id,
  });
};

onMounted(() => {
  if (!user.value.is_talkie_body) {
    tour.addStep({
      attachTo: {element: tour5_2.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 1});
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_2.title"),
      text: i18n.global.t("tour.tour5_2.text"),
    });
    tour.addStep({
      attachTo: {element: tour5_2.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 2});
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_3.title"),
      text: i18n.global.t("tour.tour5_3.text"),
    });
    tour.addStep({
      attachTo: {element: tour5_3.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 3});
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_body: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_4.title"),
      text: i18n.global.t("tour.tour5_4.text"),
    });
    tour.start();
  }
});

const handleMenuResend = () => {
  if (user.value.role === 'owner') {
    router.push({ name: 'admin.home' })
  } else {
    router.push({ name: 'employee.home' })
  }  
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(recording) || _unref(listening))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (userRecording.value == _unref(user).id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["circle--record", {recordingClass: recordingClass.value, 'ios': _unref(isPlatformIOS)}]),
                  onClick: handleRecordAudio
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(VsxIcon), { iconName: recordingIcons.value }, null, 8, ["iconName"])
                  ])
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["circle--record", {'ios': _unref(isPlatformIOS)}]),
                  ref_key: "tour5_3",
                  ref: tour5_3
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(VsxIcon), { iconName: "MicrophoneSlash" })
                  ])
                ], 2)
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            ref_key: "tour5_2",
            ref: tour5_2,
            class: _normalizeClass(["circle--record", {recordingClass: recordingClass.value, 'ios': _unref(isPlatformIOS)}]),
            onClick: handleRecordAudio
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(VsxIcon), { iconName: recordingIcons.value }, null, 8, ["iconName"])
            ])
          ], 2)
        ])),
    (__props.showAllOptions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["bar--record", {'ios': _unref(isPlatformIOS)}])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", { onClick: handleMenuResend }, [
                _createVNode(_unref(VsxIcon), {
                  iconName: "Home2",
                  class: "color-icon"
                })
              ])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tab tab--fab" }, null, -1)),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_router_link, { to: { name: 'default.tasks.list' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "ClipboardText",
                    class: "color-icon"
                  })
                ]),
                _: 1
              })
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})