import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  class: "container",
  ref: "menu"
}
const _hoisted_2 = { class: "ion-flex ion-justify-content-between ion-align-items-center ion-padding item__select" }
const _hoisted_3 = { class: "text-body-s" }
const _hoisted_4 = { class: "photo-wrapper" }
const _hoisted_5 = { class: "ion-flex ion-justify-content-start" }
const _hoisted_6 = {
  key: 0,
  class: "text__description"
}
const _hoisted_7 = { class: "photo-wrapper" }
const _hoisted_8 = { class: "ion-padding-top" }
const _hoisted_9 = { class: "ion-padding-top" }

import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { computed, onMounted, ref, watch } from "vue";
import { mapModel, mapSchema } from "@/utils/getMapModel";
import {
  handleDeleteModel,
  handleGetDescribeModel,
  handleReadModel,
  handlePostModel,
  handlePutModel,
} from "@/services/generic";
import { useList } from "@/composables/useList";
import { useGenericStore } from "@/stores/generic";
import {
  actionSheetController,
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonPage,
  IonRow,
  IonSearchbar,
  toastController,
} from "@ionic/vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import DrawerComponent from "@/components/shared/drawer/DrawerComponent.vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import DropdownMenuComponent from "@/components/admin/location/DropdownMenuComponent.vue";
import ItemMobileComponent from "@/components/admin/location/ItemMobileComponent.vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import ModalDeleteComponent from "@/components/admin/location/ModalDeleteComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import DrawerDetailComponent from "@/components/admin/location/DrawerDetailComponent.vue";
import { useRole } from "@/composables/useRole";
import { MODEL, ROLE_ID } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import PhotoLocalComponent from "@/components/shared/PhotoLocalComponent.vue";
import i18n from "@/plugins/i18n";
import { CoreStore, useCoreStore } from "@/stores/core";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";
import { useRoute } from "vue-router";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";
import useCore from "@/composables/useCore";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import router from "@/router";
import { getSubscriptionAvailability, handleSubscriptionLocationAllow } from "@/services/subscription";

const fieldQuery =
  "fields=name,country_id,city_id,address,phone_number,email,local_type,code";

export default /*@__PURE__*/_defineComponent({
  __name: 'CrudView',
  setup(__props) {

const headers = [
  {
    label: "Nombre",
    name: "name",
  },
  {
    label: "Dirección",
    name: "address",
  },
  {
    label: "Teléfono",
    name: "phone_number",
  },
  {
    label: "Sector Empresarial",
    name: "local_type",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "City",
    name: "city_id",
  },
  {
    label: "Country",
    name: "country_id",
  },
  {
    label: "Image",
    name: "image_id",
  },
  {
    label: "Code",
    name: "code",
    hide: true,
  },
  {
    label: "",
    name: "actions",
  },
];
const hideHeaders = ["code"];

const showDrawerForm: Ref<boolean> = ref(false);
const showDrawerDetail: Ref<boolean> = ref(false);
const enableCol: Ref<boolean> = ref(false);
const addLocationResult: Ref<IActionShareResult | undefined> = ref();
const showModalDelete: Ref<boolean> = ref(false);
const itemSelected: Ref<any> = ref(null);
const { getModelData } = useList();
const schema: Ref<any> = ref(null);
const storeList = useGenericStore();
const { isMobile } = useNativePlatform();
const { canOwnerAdmin } = useRole();
const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const { refreshLocation, setRefreshDashboard } = useCore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { sendInvitationLink } = useRegister();
const route = useRoute();
const { isPlatformIOS } = usePlatform();
const { handleGetMe } = useRegister();
const { notMoreLocations, setNotMoreLocation } = useCore();

const model: Ref<any> = ref({
  name: "location",
  fields: [],
});
storeList.setSortByModel(model.value.name, [
    {
      field: "created_datetime"
    },
]);


const step2_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const handleGetModel = async () => {
  const describe = await handleGetDescribeModel(model.value.name, fieldQuery);
  const modelMap = mapModel(describe);
  const schemaMap = mapSchema(describe);
  schema.value = schemaMap;
  model.value.fields = modelMap;
};

const handleClickDeleteModel = async () => {
  try {
    await handleDeleteModel({
      model: model.value.name,
      id: itemSelected.value.id,
    });
    getModel({ reset: true });
    const toast = await toastController.create({
      message: "Local borrado",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDelete.value = false;
  } catch (error) {
    console.log(error);
  }
};

const openModalDelete = (item: any) => {
  itemSelected.value = item;
  showModalDelete.value = true;
};

const openCreate = async () => {
  if (await handleSubscriptionLocationAllow()) {
    itemSelected.value = null;
    showDrawerForm.value = true;
    coreStore.setNewLocal(true);
    coreStore.setUpdateUserPlan(false);
  } else {
    coreStore.setUpdateLocationPlan(true);
    await showActionSheet();
  }
};

const openDetail = async (item: any) => {
  itemSelected.value = await handleReadModel({
    model: MODEL.LOCATION,
    id: item.id,
  });
  showDrawerDetail.value = true;
  coreStore.setSelectedLocale(item);
};

const copyCode = async (item: any) => {
  await Clipboard.write({
    string: item.code,
  });
  const toast = await toastController.create({
    message: i18n.global.t("global.codeCopied"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  coreStore.setSelectedLocale(null);
  await toast.present();
};

const shareCode = async (item: any) => {
  await Share.share({
    title: i18n.global.t("global.shareCodeTitle"),
    text: i18n.global.t("global.shareCodeDescription", {
      platform: isPlatformIOS ? "iOS" : "Android",
      name: registerStore.user.name,
      code: item.code ?? "",
    }),
    dialogTitle: i18n.global.t("actions.share"),
  });
  coreStore.setSelectedLocale(null);
  await sendInvitationLink(registerStore.user.phone_number, item.code!);
};

const openEdit = (item: any) => {
  itemSelected.value = item;
  showDrawerForm.value = true;
  coreStore.setSelectedLocale(item);
};

const formMode = computed(() => {
  return showDrawerForm.value && itemSelected.value;
});

const formModeTitle = computed(() => {
  return formMode.value ? "update" : "create";
});

const drawerTitle = computed(() => {
  return formMode.value
    ? i18n.global.t("locale.editLocale")
    : i18n.global.t("locale.newLocale");
});

const buttonTitle = computed(() => {
  return formMode.value
    ? "Guardar cambios"
    : i18n.global.t("locale.createLocale");
});

const getModel = (payload?: any) => {
  getModelData({
    headers: headers,
    modelName: model.value.name,
    reset: payload?.reset,
  });
};

const savedLocation = async (values: any) => {
  let message;
  if (showDrawerForm.value && itemSelected.value) {
    message = i18n.global.t("global.changesSaved");
  } else {
    message = i18n.global.t("locale.localeCreated");
    coreStore.toggleLoading(true);
    try {
      await handlePostModel({
        model: MODEL.USERLOCATION,
        fields: {
          location_id: values.id,
          user_id: user.value.id,
          role_id: ROLE_ID.OWNER,
          shift_days: "Turno: Lunes, Miércoles, Viernes ",
          workdays: "Horario de Lunes a Viernes de 09:00 a 16:00",
        },
      }).then(async () => {

        //Get Amount Locals
        await getSubscriptionAvailability();

        handlePutModel({
          model: MODEL.USER,
          id: user.value.id,
          fields: {
            location_id: values.id,
            is_owner: false
          },
        }).then(() => {
          coreStore.setRefreshLocation(true);
          setRefreshDashboard(true);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      coreStore.toggleLoading(false);
    }
  }

  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  showDrawerForm.value = false;
  getModel({ reset: true });
};

const filterList = (evt: any) => {
  storeList.setFiltersModel(model.value.name, [
    {
      field: "name",
      op: "ilike",
      value: evt.target.value,
    },
  ]);
  getModel();
};

watch(showDrawerDetail, () => {
  if (showDrawerDetail.value) {
    enableCol.value = showDrawerDetail.value;
  } else {
    setTimeout(() => {
      enableCol.value = showDrawerDetail.value;
    }, 200);
  }
});

watch(showDrawerForm, () => {
  if (showDrawerForm.value) {
    enableCol.value = showDrawerForm.value;
  } else {
    setTimeout(() => {
      enableCol.value = showDrawerForm.value;
    }, 200);
  }
});

watch(refreshLocation, () => {
  if (refreshLocation.value) {
    storeList.setItemsModel(model.value.name, []);
    getModel();
  }
});

const showActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: i18n.global.t("global.notMoreLocations"),
    subHeader: i18n.global.t("global.notMoreLocationsDescription"),
    cssClass: "wehop-class",
    buttons: [
      {
        text: i18n.global.t("global.addLocations"),
        data: {
          action: "add",
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        cssClass: 'wehop-class__cancel_button',
        data: {
          action: "cancel",
        },
      },
    ],
  });

  await actionSheet.present();

  const res = await actionSheet.onDidDismiss();
  addLocationResult.value = res as IActionShareResult;
  if (addLocationResult.value.data.action === "add") {
    showDrawerForm.value = false;
    await router.replace({ name: "registerPlan" });
  }
  setNotMoreLocation(false);
};

watch(notMoreLocations, async () => {
  if (notMoreLocations.value) {
    await showActionSheet();
  }
});

onMounted(() => {
  const newLocal = route.params.new_local?.toString() ?? null;
  if (newLocal == "1") {
    openCreate();
  }
  if (!user.value.is_teams_body) {
    tour.addStep({
      attachTo: { element: step2_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 3 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_teams_body: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_2.title"),
      text: i18n.global.t("tour.tour2_2.text"),
    });
    tour.start();
  }
});

handleGetModel();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRow), {
            class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonCol), {
                size: enableCol.value && !_unref(isMobile) ? '8' : '12',
                class: _normalizeClass({ 'ion-no-padding': _unref(isMobile) })
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonRow), null, {
                    default: _withCtx(() => [
                      (!_unref(isMobile))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_unref(IonCol), {
                              size: "6",
                              class: "ion-flex ion-justify-content-start ion-padding-horizontal"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("locale.localeManager")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonCol), {
                              size: "6",
                              class: "ion-flex ion-justify-content-end"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  (_unref(canOwnerAdmin))
                                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                                        key: 0,
                                        color: "primary",
                                        mode: "ios",
                                        onClick: openCreate
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(VsxIcon), { iconName: "Add" }),
                                          _cache[7] || (_cache[7] = _createElementVNode("span", null, "Añadir", -1))
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonCol), { size: "4" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonSearchbar), { onIonInput: filterList })
                              ]),
                              _: 1
                            })
                          ], 64))
                        : (_openBlock(), _createBlock(_unref(IonCol), {
                            key: 1,
                            size: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(HeaderNavigationComponent, {
                                title: _ctx.$t('locale.localeManager')
                              }, null, 8, ["title"])
                            ]),
                            _: 1
                          })),
                      _createVNode(_unref(IonCol), {
                        size: "12",
                        class: _normalizeClass({ 'ion-padding-top ion-no-padding': _unref(isMobile) })
                      }, {
                        default: _withCtx(() => [
                          _createVNode(ListWrapper, {
                            model: model.value.name,
                            headers: headers,
                            "hide-headers": hideHeaders
                          }, {
                            actions_desktop: _withCtx((props) => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(DropdownMenuComponent, {
                                  item: props,
                                  "onClick:edit": openEdit,
                                  "onClick:delete": openModalDelete,
                                  "onClick:copy": copyCode,
                                  "onClick:detail": openDetail
                                }, null, 8, ["item"])
                              ], 512)
                            ]),
                            item_mobile: _withCtx((props) => [
                              _createElementVNode("div", _hoisted_2, [
                                _createVNode(ItemMobileComponent, { item: props }, null, 8, ["item"]),
                                _createVNode(DropdownMenuComponent, {
                                  item: props,
                                  "onClick:edit": openEdit,
                                  "onClick:copy": copyCode,
                                  "onClick:share": shareCode,
                                  "onClick:delete": openModalDelete,
                                  "onClick:detail": openDetail
                                }, null, 8, ["item"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["model"])
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["size", "class"]),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (showDrawerForm.value && !_unref(isMobile))
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 0,
                        class: _normalizeClass([_unref(isPlatformIOS) ? 'safe-area' : '', "ion-no-padding"]),
                        size: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(DrawerComponent, {
                            modelValue: showDrawerForm.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showDrawerForm).value = $event)),
                            title: drawerTitle.value,
                            class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("locale.localeDescriptionAdd")), 1),
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(PhotoLocalComponent, { style: {"z-index":"9999"} })
                              ]),
                              _createVNode(FormComponent, {
                                "no-cancel-button": "",
                                model: model.value,
                                "form-mode": formModeTitle.value,
                                "id-model": itemSelected.value?.id,
                                schema: schema.value,
                                onSuccess: savedLocation
                              }, {
                                submitButton: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_unref(IonButton), {
                                      mode: "ios",
                                      type: "submit"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(buttonTitle.value), 1)
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]),
                                _: 1
                              }, 8, ["model", "form-mode", "id-model", "schema"])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title", "class"])
                        ]),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (showDrawerDetail.value && !_unref(isMobile))
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 0,
                        size: "4",
                        class: "ion-no-padding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(DrawerComponent, {
                            modelValue: showDrawerDetail.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showDrawerDetail).value = $event)),
                            title: itemSelected.value.name
                          }, {
                            default: _withCtx(() => [
                              _createVNode(DrawerDetailComponent, { item: itemSelected.value }, null, 8, ["item"])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          (_unref(isMobile))
            ? (_openBlock(), _createBlock(_unref(IonFab), {
                key: 0,
                slot: "fixed",
                vertical: "bottom",
                horizontal: "end",
                class: "wehop-class"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref_key: "step2_2",
                    ref: step2_2
                  }, [
                    _createVNode(_unref(IonFabButton), { onClick: openCreate }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(VsxIcon), { iconName: "Add" })
                      ]),
                      _: 1
                    })
                  ], 512)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(ModalDeleteComponent, {
        modelValue: showModalDelete.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showModalDelete).value = $event)),
        "onConfirm:delete": handleClickDeleteModel,
        name: itemSelected.value?.name
      }, null, 8, ["modelValue", "name"]),
      (showDrawerForm.value && _unref(isMobile))
        ? (_openBlock(), _createBlock(ModalComponent, {
            key: 0,
            noIconClose: "",
            modelValue: showDrawerForm.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showDrawerForm).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(HeaderNavigationComponent, {
                preventDefault: "",
                "onClick:back": _cache[3] || (_cache[3] = ($event: any) => (showDrawerForm.value = false)),
                title: drawerTitle.value
              }, null, 8, ["title"]),
              (!formMode.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Indica, a continuación, los datos correspondientes para poder crear tu equipo "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(PhotoLocalComponent)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(FormComponent, {
                  "no-cancel-button": "",
                  model: model.value,
                  "form-mode": formModeTitle.value,
                  "id-model": itemSelected.value?.id,
                  schema: schema.value,
                  "hide-headers": hideHeaders,
                  onSuccess: savedLocation
                }, {
                  submitButton: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      mode: "ios",
                      expand: "block",
                      type: "submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(buttonTitle.value), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "form-mode", "id-model", "schema"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (showDrawerDetail.value && _unref(isMobile))
        ? (_openBlock(), _createBlock(ModalComponent, {
            key: 1,
            noIconClose: "",
            modelValue: showDrawerDetail.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((showDrawerDetail).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(HeaderNavigationComponent, {
                preventDefault: "",
                "onClick:back": _cache[5] || (_cache[5] = ($event: any) => (showDrawerDetail.value = false)),
                title: itemSelected.value.name,
                class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
              }, null, 8, ["title", "class"]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(DrawerDetailComponent, { item: itemSelected.value }, null, 8, ["item"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})