import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-created-wrapper__body" }
const _hoisted_2 = { class: "profile-created-wrapper__logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "profile-created-wrapper__title" }
const _hoisted_5 = { class: "profile-created-wrapper__subtitle text-body-s" }
const _hoisted_6 = { class: "profile-created-wrapper__options" }
const _hoisted_7 = { class: "profile-created-wrapper__logo-normal" }
const _hoisted_8 = ["src"]

import {useRouter} from "vue-router";
import {CoreStore, useCoreStore} from "@/stores/core";
import defaultPhoto from "@/assets/default_photo.svg";
import wehopLogo from "@/assets/wehop-logo.svg";

import {IonButton, IonContent, IonPage} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterProfileCreatedView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore()
const {isPlatformIOS } = usePlatform();

const router = useRouter();

const goHome = async () => {
  coreStore.resetHelpStep();
  await router.push({name: "home"});
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding profile-created-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _unref(defaultPhoto),
                alt: ""
              }, null, 8, _hoisted_3),
              _cache[0] || (_cache[0] = _createElementVNode("p", null, "John Doe", -1))
            ]),
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('register.profileCreated')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('register.profileCreatedDescription')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_unref(IonButton), {
                mode: "ios",
                expand: "block",
                class: "button--login ion-margin-top",
                type: "button",
                onClick: goHome
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.goTo')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _unref(wehopLogo),
              alt: ""
            }, null, 8, _hoisted_8)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})