import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding"
}
const _hoisted_2 = { class: "javascript" }
const _hoisted_3 = { class: "ion-padding" }
const _hoisted_4 = { class: "vue ion-padding" }

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref } from "vue";
import type { Ref } from "vue";
import { mapModel, mapSchema } from "@/utils/getMapModel";
import { code } from "@/utils/docs/formComponent";
import { handleGetDescribeModel } from "@/services/generic";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexView',
  setup(__props) {

const form = ref(null);
const handleSuccess = (values: any) => {
  form.value = values;
};

const schema: Ref<any> = ref(null);
const model: Ref<any> = ref({
  name: "userlocation",
  fields: [],
});

const getModel = async () => {
  const fields = "fields=location_id,user_id,role_id,workdays,shift_days";
  const describe = await handleGetDescribeModel(model.value.name, fields);
  const modelMap = mapModel(describe);
  const schemaMap = mapSchema(describe);
  schema.value = schemaMap;
  model.value.fields = modelMap;
  console.log(model.value);
};
getModel();

return (_ctx: any,_cache: any) => {
  const _directive_highlightjs = _resolveDirective("highlightjs")!

  return (_openBlock(), _createBlock(_unref(IonPage), { class: "ion-md-padding" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCard), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonCardHeader), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCardTitle), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Formulario")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonCardSubtitle), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Ubicación: /components/shared/form/FormComponent.vue")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonCardContent), null, {
                default: _withCtx(() => [
                  _createVNode(FormComponent, {
                    schema: schema.value,
                    model: model.value,
                    onSuccess: handleSuccess
                  }, null, 8, ["schema", "model"]),
                  (form.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_unref(IonCardSubtitle), null, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Resultado:")
                          ])),
                          _: 1
                        }),
                        _withDirectives((_openBlock(), _createElementBlock("pre", null, [
                          _cache[3] || (_cache[3] = _createTextVNode("              ")),
                          _createElementVNode("code", _hoisted_2, _toDisplayString(form.value), 1),
                          _cache[4] || (_cache[4] = _createTextVNode("\n            "))
                        ])), [
                          [_directive_highlightjs]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(IonCardHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonCardTitle), null, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Ejemplo de uso")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(IonCardSubtitle), null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Ubicación: /views/docs/IndexView.vue")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _withDirectives((_openBlock(), _createElementBlock("pre", null, [
                      _cache[7] || (_cache[7] = _createTextVNode("              ")),
                      _createElementVNode("code", _hoisted_4, _toDisplayString(_unref(code)), 1),
                      _cache[8] || (_cache[8] = _createTextVNode("\n            "))
                    ])), [
                      [_directive_highlightjs]
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})