import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "change-password-message-wrapper__body" }
const _hoisted_2 = { class: "change-password-message-wrapper__title" }
const _hoisted_3 = { class: "change-password-message-wrapper__subtitle text-body-s" }
const _hoisted_4 = { class: "change-password-message-wrapper__actions" }
const _hoisted_5 = { class: "change-password-message-wrapper__logo" }
const _hoisted_6 = ["src"]

import { IonButton, IonContent, IonPage } from "@ionic/vue";
import wehopLogo from "@/assets/wehop-logo.svg";

export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePasswordMessageView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "change-password-message-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("forgotPassword.congratulations")), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("forgotPassword.congratulationsDescription")), 1),
            _createElementVNode("section", _hoisted_4, [
              _createVNode(_unref(IonButton), {
                class: "change-password-message-wrapper__login",
                expand: "block",
                "router-link": "/auth/login"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("forgotPassword.goToLogin")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _unref(wehopLogo),
                alt: ""
              }, null, 8, _hoisted_6)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})