import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "downgrade-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "downgrade-users" }
const _hoisted_6 = { key: 0 }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton } from "@ionic/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import "cordova-plugin-purchase";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { RegisterStore, useRegisterStore } from "@/stores/register";

import TeamInfo from "@/components/shared/TeamInfo.vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handleLocationData } from "@/services/location";
import { useRegister } from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeTeamsView',
  setup(__props) {

const { setLocation } = useRegister();
const router = useRouter();

const { isPlatformIOS } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();

const teams = ref<ILocation[]>([]);
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goNextStep = async () => {
  coreStore.setNewLocal(true);
  handleLocationData().then(async (data) => {
    ///Set Location
    setLocation(data[0]);
    if (
      coreStore.subscriptionAvailability &&
      coreStore.subscriptionAvailability?.users_status?.number_users <= 5
    ) {
      await router.replace({ name: "registerPlanTeamsFinishDowngrade" });
    } else {
      await router.replace({ name: "registerPlanOptionTwoDowngrade" });
    }
  })
};

onMounted(async () => {
  if (registerStore.user.entity_id) {
    await handleLocationData().then((res: ILocation[]) => {
      teams.value = res;
    });
  }
});

return (_ctx: any,_cache: any) => {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      (teams.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("payment.planChangeTitle")), 1),
            (teams.value)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "downgrade-description caption",
                  innerHTML: _ctx.$t('payment.planChangeTeamTitleDescriptionOption')
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (teams.value)
              ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(teams.value, (team) => {
                      return (_openBlock(), _createElementBlock("li", { key: team }, [
                        (_unref(registerStore).user.location_id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
                              _createVNode(TeamInfo, {
                                team: team,
                                "location-id": _unref(registerStore).user.location_id
                              }, null, 8, ["team", "location-id"])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      color: "primary",
                      expand: "block",
                      disabled: 
                _unref(coreStore).subscriptionAvailability?.teams_status?.number_teams >
                1
              ,
                      onClick: goNextStep
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("actions.continue")), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})