import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ion-padding-start" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { ref, watch } from "vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { TourStore, useTourStore } from "@/stores/tour";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuMemberComponent',
  props: {
  showDelete: {
    type: Boolean,
    default: true,
  },
  isOwner: {
    type: Boolean,
    default: false,
  },
},
  emits: ["click:detail", "click:edit", "click:delete"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { user } = useRegister();
const { handleGetMe } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();

const props = __props;

const tourMembersOptions = ref(null);
const tourMembersDelete = ref(null);

const tourMembersOptionWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};

watch(showDropdown, () => {
  if (
    showDropdown.value &&
    !user.value.is_members_options &&
    props.isOwner
  ) {
    tourMembersOptionWrapper.addStep({
      attachTo: { element: tourMembersOptions.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setMemberOptionsTour({ show: true, step: 1 });
            tourMembersOptionWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour4_2.title"),
      text: i18n.global.t("tour.tour4_2.text"),
    });
    tourMembersOptionWrapper.addStep({
      attachTo: { element: tourMembersDelete.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setMemberOptionsTour({ show: true, step: 1 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_members_options: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourMembersOptionWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour4_3.title"),
      text: i18n.global.t("tour.tour4_3.text"),
    });
    tourMembersOptionWrapper.start();
  } else {
    coreStore.setShowDropdown(false);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("ol", {
            ref_key: "tourMembersOptions",
            ref: tourMembersOptions,
            style: {"padding":"0px","margin-left":"20px"}
          }, [
            _createElementVNode("li", null, [
              _createVNode(_unref(IonItem), {
                button: true,
                detail: false,
                class: "options_actions",
                id: "group_detail",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:detail')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "Copy",
                    color: "gray"
                  }),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("group.detail")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_unref(IonItem), {
                button: true,
                detail: false,
                class: "options_actions",
                id: "group_edit",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:edit')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "Edit2",
                    color: "gray"
                  }),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("group.edit")), 1)
                ]),
                _: 1
              })
            ]),
            (__props.showDelete)
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                  _createElementVNode("div", {
                    ref_key: "tourMembersDelete",
                    ref: tourMembersDelete
                  }, [
                    _createVNode(_unref(IonItem), {
                      button: true,
                      detail: false,
                      class: "options_actions trash-text",
                      id: "group_delete",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (emitClick('click:delete')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(VsxIcon), {
                          iconName: "ProfileRemove",
                          class: "trash-icon"
                        }),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("group.delete")), 1)
                      ]),
                      _: 1
                    })
                  ], 512)
                ]))
              : _createCommentVNode("", true)
          ], 512)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})