import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vShow as _vShow, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "password-wrapper" }
const _hoisted_2 = { class: "password-wrapper__icon" }

import { IModel } from "@/interfaces/GenericModel";
import { VsxIcon } from "vue-iconsax";
import IonInput from "@/components/shared/form/InputComponent.vue";
import type { Ref } from "vue";
import { ref, toRefs, computed, onMounted, watch } from "vue";
import { Form } from "vee-validate";
import { IonButton, IonCol, IonRow } from "@ionic/vue";
import useCore from "@/composables/useCore";

import {
  getFieldValue,
  getFilters,
  setDefaultValues,
} from "@/utils/handleFieldValue";
import {
  handlePostModel,
  handlePutModel,
  handleReadModel,
} from "@/services/generic";

import {
  verifyInputCheckbox,
  verifyInputDate,
  verifyInputPassword,
  verifyInputPin,
  verifyInputSelect,
  verifyInputTel,
  verifyInputTextAndNumber,
  verifyInputTextArea,
  verifyInputAutocomplete,
  verifyInputToggle,
} from "@/utils";

interface Props {
  model: IModel;
  schema?: any;
  formMode?: string;
  hideHeaders?: Array<string>;
  idModel?: any;
  noRelations?: boolean;
  noCancelButton?: boolean;
  preventDefault?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormComponent',
  props: {
    model: {},
    schema: {},
    formMode: { default: "create" },
    hideHeaders: {},
    idModel: {},
    noRelations: { type: Boolean },
    noCancelButton: { type: Boolean },
    preventDefault: { type: Boolean }
  },
  emits: ["success", "cancel"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const form: Ref<any> = ref(null);
const initialValues: Ref<any> = ref(null);
const filtersArray: Ref<any[]> = ref([]);
const props = __props;
const modelRef = toRefs(props).model;
const showPassword = ref(false);
const showPasswordIcon = ref("Eye");

const { resetForm, setResetForm } = useCore();

const emit = __emit;

const handleFieldValue = (item: any, field: any) => {
  const filter = getFieldValue(props, item, field);
  if (filter.length) {
    filtersArray.value = filter;
  }
};

const handleGetFilters = (field: any) => {
  if (field?.filters) {
    return field.filters;
  } else {
    return getFilters(filtersArray.value, field);
  }
};

const handleSubmit = async (values: any) => {
  if (!props.preventDefault) {
    if (props.formMode === "create") {
      const payload = {
        model: props.model.name,
        fields: values,
      };
      const data = await handlePostModel(payload);
      emit("success", data);
    } else {
      const payload = {
        model: props.model.name,
        fields: values,
        id: props.idModel,
      };
      const data = await handlePutModel(payload);
      emit("success", data);
    }
  } else {
    emit("success", values);
  }
};

watch(resetForm, () => {
  form.value.resetForm();
  setResetForm(false);
})

const handleSetDefaultValues = () => {
  initialValues.value = setDefaultValues(props.model.fields);
};
handleSetDefaultValues();

const handleResetForm = () => {
  form.value.resetForm();
  emit("cancel");
};

const dynamicId = computed(() => {
  return `form-${props.model.name}`;
});

const handleGetDetailModel = async () => {
  if (props.formMode === "update") {
    const payload = {
      model: props.model.name,
      id: props.idModel,
    };
    const values = await handleReadModel(payload);
    const hasNameProperty = "birthdate" in values;
    if (hasNameProperty && values.birthdate == null) {
      delete values.birthdate;
    }
    initialValues.value = values;
  }
};

const viewPassword = () => {
  showPassword.value = !showPassword.value;
  if (showPassword.value == true) {
    showPasswordIcon.value = "EyeSlash";
  } else {
    showPasswordIcon.value = "Eye";
  }
};

onMounted(() => {
  handleGetDetailModel();
});

watch(modelRef, () => {
  handleGetDetailModel();
});
__expose({ handleResetForm });

return (_ctx: any,_cache: any) => {
  const _directive_numeric_only = _resolveDirective("numeric-only")!
  const _directive_float_numeric_only = _resolveDirective("float-numeric-only")!

  return (_ctx.model.fields.length)
    ? (_openBlock(), _createBlock(_unref(Form), {
        key: 0,
        ref_key: "form",
        ref: form,
        id: dynamicId.value,
        onSubmit: handleSubmit,
        initialValues: initialValues.value,
        "validation-schema": _ctx.schema
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRow), null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.fields, (field, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  (
            _unref(verifyInputTextAndNumber)(field.type) &&
            field.hide !== true &&
            !_ctx.hideHeaders?.includes(field.name)
          )
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 0,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": field.type,
                            name: field.name,
                            label: field.label,
                            placeholder: field.label,
                            type: field.type,
                            icon: field?.icon
                          }, null, 8, ["modelName", "input-type", "name", "label", "placeholder", "type", "icon"]), [
                            [_vShow, 'display' in field ? field.display : true],
                            [_directive_numeric_only, field.type === 'number' && !field.is_float],
                            [_directive_float_numeric_only, field.is_float]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputPassword)(field.type) && field.hide !== true)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 1,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _withDirectives(_createVNode(IonInput, {
                              modelName: _ctx.model.name,
                              "input-type": "password",
                              name: field.name,
                              label: field.label,
                              placeholder: field.label,
                              type: showPassword.value ? 'text' : field.type,
                              icon: field?.icon,
                              "clear-field": false
                            }, null, 8, ["modelName", "name", "label", "placeholder", "type", "icon"]), [
                              [_vShow, 'display' in field ? field.display : true]
                            ]),
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_unref(VsxIcon), {
                                iconName: showPasswordIcon.value,
                                type: "linear",
                                onClick: viewPassword
                              }, null, 8, ["iconName"])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputTextArea)(field.type) && field.hide !== true)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 2,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "textarea",
                            name: field.name,
                            label: field.label,
                            placeholder: field.label
                          }, null, 8, ["modelName", "name", "label", "placeholder"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputCheckbox)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 3,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "checkbox",
                            name: field.name,
                            label: field.label
                          }, _createSlots({ _: 2 }, [
                            _renderList(_ctx.$slots, (_, name) => {
                              return {
                                name: name,
                                fn: _withCtx((data) => [
                                  _renderSlot(_ctx.$slots, name, _mergeProps({ ref_for: true }, data))
                                ])
                              }
                            })
                          ]), 1032, ["modelName", "name", "label"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputToggle)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 4,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "toggle",
                            name: field.name,
                            label: field.label
                          }, null, 8, ["modelName", "name", "label"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputSelect)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 5,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "select",
                            options: field.enum || [],
                            name: field.name,
                            label: field.label
                          }, null, 8, ["modelName", "options", "name", "label"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputDate)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 6,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "date",
                            name: field.name,
                            label: field.label,
                            icon: field?.icon,
                            placeholder: field.label
                          }, null, 8, ["modelName", "name", "label", "icon", "placeholder"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputTel)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 7,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _withDirectives(_createVNode(IonInput, {
                              modelName: _ctx.model.name,
                              "input-type": "tel",
                              type: "tel",
                              name: field.name,
                              label: field.label,
                              icon: field?.icon,
                              placeholder: field.label
                            }, null, 8, ["modelName", "name", "label", "icon", "placeholder"]), [
                              [_vShow, 'display' in field ? field.display : true]
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputPin)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 8,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: _ctx.model.name,
                            "input-type": "pin",
                            name: field.name,
                            label: field.label,
                            placeholder: field.label
                          }, null, 8, ["modelName", "name", "label", "placeholder"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_unref(verifyInputAutocomplete)(field.type) && !field.hide)
                    ? (_openBlock(), _createBlock(_unref(IonCol), {
                        key: 9,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(IonInput, {
                            modelName: field.model || '',
                            "input-type": "autocomplete",
                            name: field.name,
                            label: field.label,
                            placeholder: field.label,
                            "field-search": field.field_search,
                            onUpdate: (value) => handleFieldValue(value, field),
                            filters: handleGetFilters(field),
                            icon: field.icon
                          }, null, 8, ["modelName", "name", "label", "placeholder", "field-search", "onUpdate", "filters", "icon"]), [
                            [_vShow, 'display' in field ? field.display : true]
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              _renderSlot(_ctx.$slots, "options"),
              _createVNode(_unref(IonCol), {
                size: "12",
                class: "ion-flex ion-justify-content-end"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "cancelButton", {}, () => [
                    (!_ctx.noCancelButton)
                      ? (_openBlock(), _createBlock(_unref(IonButton), {
                          key: 0,
                          mode: "ios",
                          color: "danger",
                          onClick: handleResetForm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("actions.cancel")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _renderSlot(_ctx.$slots, "submitButton", {}, () => [
                    _createVNode(_unref(IonButton), {
                      mode: "ios",
                      type: "submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("actions.save")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["id", "initialValues", "validation-schema"]))
    : _createCommentVNode("", true)
}
}

})