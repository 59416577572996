import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-toolbar" }
const _hoisted_2 = { class: "container-title" }
const _hoisted_3 = {
  key: 0,
  class: "container-time"
}

import { watch, computed, onMounted } from "vue";
import { VsxIcon } from "vue-iconsax";
import { IonHeader, IonToolbar } from "@ionic/vue";
import useCountdown from "@/composables/useCountdown";
import useRecord from "@/composables/useRecord";
import { useAudio } from "@/composables/useAudio";
import { CoreStore, useCoreStore } from "@/stores/core";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import i18n from "@/plugins/i18n";
import {usePlatform} from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'SnackBarComponent',
  setup(__props) {

const { recording, listening, nameUser, userId, mode, showSnackBar } = useRecord();
const { notificationMode, notificationReceiptId } = useCore();
const { startTimer, startCountdown, minutes, seconds } = useCountdown();
const { verifySpeaking } = useAudio();
const coreStore: CoreStore = useCoreStore();
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();

const message = computed(() => {
  return recording.value
    ? `${i18n.global.t("talkie.talkingTo")} ${nameUser.value} `
    : `${nameUser.value} ${i18n.global.t("talkie.isTalkingToYou")}`;
});


const isOnlyForUser = computed(() => {
  if (mode.value == NOTIFICATION_TYPE.USER && userId.value == user.value.id) {
    return true;
  } else {
    return false;
  }
});

watch(recording, () => {
  if (recording.value) {
    startTimer();
  }
});

onMounted(() => {
  verifySpeaking();
});




return (_ctx: any,_cache: any) => {
  return (_unref(showSnackBar))
    ? (_openBlock(), _createBlock(_unref(IonHeader), { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), {
            class: _normalizeClass([_unref(recording) ? 'toolbar-danger' : 'toolbar-success', _unref(isPlatformIOS) ? 'toolbar-ios' : ''])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(VsxIcon), {
                    iconName: _unref(recording) ? 'Sound' : 'Pause'
                  }, null, 8, ["iconName"]),
                  _createElementVNode("span", null, _toDisplayString(message.value), 1)
                ]),
                (_unref(recording))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(minutes)) + ":" + _toDisplayString(_unref(seconds) < 10 ? "0" + _unref(seconds) : _unref(seconds)), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})