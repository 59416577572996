import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ion-padding-top" }
const _hoisted_2 = { class: "ion-padding-top" }

import { onMounted, ref, watch } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import {
  IonPage,
  toastController,
  IonFab,
  IonFabButton,
  IonPopover,
  IonList,
  IonItem,
  IonButton,
  IonContent,
} from "@ionic/vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import TabListComponent from "@/components/admin/tasks/TabListComponent.vue";
import MainButtonBarComponent from "@/components/admin/MainButtonBarComponent.vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";

import { useRegister } from "@/composables/useRegister";
import { schemanewTask, schemanewAdvice } from "@/utils/getMapModel";
import i18n from "@/plugins/i18n";
import { MODEL, ROLES } from "@/utils/constants";
import useCore from "@/composables/useCore";
import router from "@/router";
import { usePlatform } from "@/composables/usePlatform";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";

import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { CoreStore, useCoreStore } from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'TaskListView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const tourStore: TourStore = useTourStore();
const codeStore: CoreStore = useCoreStore();

const { setRefreshTask, refreshTask } = useCore();

const showDrawerFormTask: Ref<boolean> = ref(false);
const showDrawerFormAdvice: Ref<boolean> = ref(false);
const schemaTask: Ref<any> = ref(schemanewTask);
const schemaAdvice: Ref<any> = ref(schemanewAdvice);
const { user, handleGetMe } = useRegister();
const { isPlatformIOS } = usePlatform();

const tabList: Ref<any> = ref(null);
const modelTask: Ref<any> = ref({
  name: MODEL.TASK,
  fields: [
    {
      name: "title",
      label: "Nombre de la tarea",
      type: "textarea",
    },
    {
      name: "description",
      label: "Escribe una descripción",
      type: "textarea",
    },
    {
      name: "assigned_user_id",
      label: "Asignar a (Opcional)",
      type: "autocomplete",
      model: "user",
      filters: [
        {
          model: "userlocation",
          foreign_key: "user_id",
          field: "location_id",
          value: user.value.location_id,
          op: "==",
        },
      ],
      display: user.value.role !== ROLES.EMPLOYEE,
      default: user.value.role === ROLES.EMPLOYEE ? user.value.id : "",
    },
    {
      name: "is_priority",
      label: "Tarea prioritaria",
      type: "toggle",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const modelAdvice: Ref<any> = ref({
  name: MODEL.ADVICE,
  fields: [
    {
      name: "name",
      label: "Nombre del aviso",
      type: "textarea",
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
    },
    {
      name: "date_end",
      label: "Fecha de finalización",
      type: "date",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const step3_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const handleUpdateListTask = async () => {
  showDrawerFormTask.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("global.changesSaved"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  tabList.value.updatePendingList();
};

const handleUpdateListAdvice = async () => {
  showDrawerFormAdvice.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("global.changesSaved"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  tabList.value.updateAdviceList();
};

onMounted(() => {
  if (!user.value.is_task && tourStore.task.step == 2) {
    tour.addStep({
      attachTo: { element: step3_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTaskTour({ show: true, step: 3 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour3_2.title"),
      text: i18n.global.t("tour.tour3_2.text"),
    });
    tour.addStep({
      attachTo: { element: step3_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTaskTour({ show: true, step: 4 });
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_task: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour3_3.title"),
      text: i18n.global.t("tour.tour3_3.text"),
    });
    tour.start();
  }
});

watch(refreshTask, async () => {
  setRefreshTask(false);
  codeStore.setRefreshLocation(false);
  codeStore.setRefreshUserLocation(false);
  codeStore.setRefreshDashboardOwner(false);
  codeStore.setRefreshDashboard(false);
  router.go(0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(WrapperPageComponent, null, {
        default: _withCtx(() => [
          _createVNode(TabListComponent, {
            ref_key: "tabList",
            ref: tabList
          }, null, 512),
          _createVNode(MainButtonBarComponent),
          _createVNode(_unref(IonPopover), {
            trigger: "popover-button",
            "dismiss-on-select": true,
            alignment: "start",
            side: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonContent), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonList), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonItem), {
                        class: "tab-item-menu",
                        button: true,
                        detail: false,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (showDrawerFormAdvice.value = true))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(VsxIcon), { iconName: "Notification" }),
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ion-padding-start" }, " Aviso ", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonItem), {
                        class: "tab-item-menu",
                        button: true,
                        detail: false,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (showDrawerFormTask.value = true))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(VsxIcon), { iconName: "NoteText" }),
                          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "ion-padding-start" }, " Tarea ", -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(IonFab), {
            slot: "fixed",
            vertical: "bottom",
            horizontal: "end"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonFabButton), { id: "popover-button" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref_key: "step3_2",
                    ref: step3_2
                  }, [
                    _createVNode(_unref(VsxIcon), { iconName: "Add" })
                  ], 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(ModalComponent, {
            noIconClose: "",
            modelValue: showDrawerFormTask.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showDrawerFormTask).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(HeaderNavigationComponent, {
                preventDefault: "",
                "onClick:back": _cache[2] || (_cache[2] = ($event: any) => (showDrawerFormTask.value = false)),
                title: _ctx.$t('tasks.addTask'),
                class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
              }, null, 8, ["title", "class"]),
              _createElementVNode("div", _hoisted_1, [
                (showDrawerFormTask.value)
                  ? (_openBlock(), _createBlock(FormComponent, {
                      key: 0,
                      "no-cancel-button": "",
                      model: modelTask.value,
                      "form-mode": "create",
                      schema: schemaTask.value,
                      onSuccess: handleUpdateListTask
                    }, {
                      submitButton: _withCtx(() => [
                        _createVNode(_unref(IonButton), {
                          mode: "ios",
                          expand: "block",
                          type: "submit"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Guardar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model", "schema"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(ModalComponent, {
            noIconClose: "",
            modelValue: showDrawerFormAdvice.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showDrawerFormAdvice).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(HeaderNavigationComponent, {
                preventDefault: "",
                "onClick:back": _cache[4] || (_cache[4] = ($event: any) => (showDrawerFormAdvice.value = false)),
                title: _ctx.$t('advice.addAdvice'),
                class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
              }, null, 8, ["title", "class"]),
              _createElementVNode("div", _hoisted_2, [
                (showDrawerFormAdvice.value)
                  ? (_openBlock(), _createBlock(FormComponent, {
                      key: 0,
                      "no-cancel-button": "",
                      model: modelAdvice.value,
                      "form-mode": "create",
                      schema: schemaAdvice.value,
                      onSuccess: handleUpdateListAdvice
                    }, {
                      submitButton: _withCtx(() => [
                        _createVNode(_unref(IonButton), {
                          mode: "ios",
                          expand: "block",
                          type: "submit"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Guardar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model", "schema"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})