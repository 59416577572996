import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start" }

import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { VsxIcon } from "vue-iconsax";
import { IonItem } from "@ionic/vue";

interface Props {
  textEdit: string;
  textDelete: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuTaskComponent',
  props: {
    textEdit: {},
    textDelete: {}
  },
  emits: ["click:edit", "click:delete"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click:edit')))
        }, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Edit2",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.textEdit)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click:delete'))),
          class: "item-delete"
        }, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), { iconName: "Trash" }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.textDelete)), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})