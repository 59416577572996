import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from "vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListView',
  setup(__props) {

const headers = [
  {
    label: "Booleano",
    name: "bool_field",
  },
  {
    label: "Date",
    name: "date_field",
  },
  {
    label: "Email",
    name: "email_field",
  },
  {
    label: "Status",
    name: "enum_field",
  },
];
const model = ref("dummy");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCard), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonCardHeader), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCardTitle), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Listado")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonCardSubtitle), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Ubicación: /components/shared/list/ListComponent.vue")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(ListWrapper, {
            model: model.value,
            headers: headers,
            "title-key": "email_field"
          }, {
            email_field_desktop: _withCtx((props) => [
              _createTextVNode(_toDisplayString(props.email_field) + " + 1 ", 1)
            ]),
            bool_field_desktop: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" es un boolean ")
            ])),
            _: 1
          }, 8, ["model"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})