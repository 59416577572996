import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onUpdate:modelValue", "autofocus", "onKeydown"]

import {onBeforeUnmount, reactive, Ref, ref, watch} from "vue";
import {IonNote} from "@ionic/vue";
import i18n from "@/plugins/i18n";
import useCore from "@/composables/useCore";


export default /*@__PURE__*/_defineComponent({
  __name: 'PinCode',
  props: {
  default: String,
  digitCount: {
    type: Number,
    required: true,
    default: 5,
  },
},
  emits: ["update:otp"],
  setup(__props, { emit: __emit }) {

const { resetPinDigit, setResetPinDigit } = useCore();

const props = __props;

const digits = reactive<string[]>([]);
const errorMessage = ref<string | undefined>(null);
const normalMessage = ref<string | undefined>(i18n.global.t("rules.pinCode"));

if (props.default) {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = props.default.charAt(i);
  }
  if (digits.join("").length === props.digitCount) {
    errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
  } else {
    errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
  }
} else {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = "";
  }
}

const PinCode: Ref<HTMLElement | undefined> = ref();
const emit = __emit;
const isDigitsFull = function () {
  for (const elem of digits) {
    if (elem == null || elem == undefined || elem == ".") {
      return false;
    }
  }
  return true;
};
const handleKeyDown = function (event: KeyboardEvent, index: number) {
  console.log(event.key);

  if (
      event.key !== "Tab" &&
      event.key !== "ArrowRight" &&
      event.key !== "ArrowLeft" &&
      event.key !== "Paste"
  ) {
    event.preventDefault();
  }

  if (event.key === "Backspace") {
    digits[index] = "";

    if (index != 0) {
      PinCode.value!.children[index - 1].focus();
    }
    errorMessage.value = i18n.global.t("rules.pinCode");
    return;
  }


  if (new RegExp("^([0-9])$").test(event.key)) {
    digits[index] = event.key;
    if (index != props.digitCount - 1) {
      PinCode.value!.children[index + 1].focus();
    }
    if (isDigitsFull()) {
      emit("update:otp", digits.join(""));
      if (digits.join("").length === props.digitCount) {
        errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
      } else {
        errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
      }
    }
  }
};

const handleInputChange = function (event: Event) {
  const value = (event.target as HTMLInputElement).value;
  console.log(value);
}

const handlePaste = (event: ClipboardEvent) => {
  const clipboardData = event.clipboardData || (window as any).clipboardData;
  if (clipboardData) {
    const pastedText = clipboardData.getData('text/plain').trim();

    if (/^[0-9]{5}$/.test(pastedText)) {
      for (let i = 0; i < 5; i++) {
        digits[i] = pastedText.charAt(i);
      }
    }

    // Prevent the default paste behavior
    event.preventDefault();
    
    if (isDigitsFull()) {
      emit("update:otp", digits.join(""));
      if (digits.join("").length === props.digitCount) {
        errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
      } else {
        errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
      }
    }
  }
};

watch(resetPinDigit, () => {
    for (let i = 0; i < props.digitCount; i++) {
      digits[i] = "";
    }
})

onBeforeUnmount(() => {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = "";
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref_key: "PinCode",
      ref: PinCode,
      class: "pin-container"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(digits, (el, ind) => {
        return _withDirectives((_openBlock(), _createElementBlock("input", {
          type: "tel",
          class: _normalizeClass(["pin-code", { bounce: digits[ind] !== null }]),
          key: el + ind,
          "onUpdate:modelValue": ($event: any) => ((digits[ind]) = $event),
          autofocus: ind === 0,
          placeholder: ".",
          maxlength: "1",
          onKeydown: ($event: any) => (handleKeyDown($event, ind)),
          onPaste: _cache[0] || (_cache[0] = ($event: any) => (handlePaste($event)))
        }, null, 42, _hoisted_1)), [
          [_vModelText, digits[ind]]
        ])
      }), 128))
    ], 512),
    (errorMessage.value)
      ? (_openBlock(), _createBlock(_unref(IonNote), {
          key: 0,
          slot: "helper",
          color: "neutral-80",
          class: "pin-code-description"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(errorMessage.value), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_unref(IonNote), {
          key: 1,
          color: "neutral-80",
          class: "pin-code-description"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(normalMessage.value), 1)
          ]),
          _: 1
        }))
  ]))
}
}

})