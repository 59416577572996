import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password-wrapper__back" }
const _hoisted_2 = { class: "forgot-password-wrapper__help" }
const _hoisted_3 = { class: "ion-padding" }

import { IonBackButton, IonContent, IonPage } from "@ionic/vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import i18n from "@/plugins/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPasswordWrapper',
  props: {
  title: {
    type: String,
    default: i18n.global.t("global.help"),
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding forgot-password-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: "",
              color: "primary",
              defaultHref: "/auth/login"
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(ProgressCircle, { "total-steps": 3 }),
            _createElementVNode("h3", _hoisted_3, _toDisplayString(__props.title), 1)
          ]),
          _renderSlot(_ctx.$slots, "step")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})