import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "photo-wrapper__body" }
const _hoisted_2 = { class: "photo-wrapper__logo" }
const _hoisted_3 = { class: "photo-wrapper__cropper__options" }
const _hoisted_4 = { class: "photo-wrapper__options" }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import {useRegister} from "@/composables/useRegister";
import {IonButton, IonIcon, toastController} from "@ionic/vue";
import {addOutline, removeOutline, scanOutline} from "ionicons/icons";
import {Cropper, CropperResult} from "vue-advanced-cropper";
import {onMounted, ref} from "vue";

import {RegisterStore, useRegisterStore} from "@/stores/register";
import {useRoute} from "vue-router";
import {usePlatform} from "@/composables/usePlatform";
import {getImageLetter} from "@/utils/getImageLetter";
import {Camera, CameraResultType} from "@capacitor/camera";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {CoreStore, useCoreStore} from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterUploadPhotoView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const {isPlatformIOS} = usePlatform();
const {photo, sendPhoto} = useRegister();
const route = useRoute();
const isSelecting = ref<boolean>(false);
const uploader = ref<HTMLInputElement>();
const cropper = ref<CropperResult>();
const goBack = ref<boolean>(false);
const newPhoto = ref(false);
const fileName = ref("");
const newPhotoBase64 = ref<string>();
const cropperWidth = ref(200);
const cropperHeight = ref(200);
const {isMobile} = useNativePlatform();
const img = ref<string | ArrayBuffer | null | undefined>("");

const photoSubmit = async () => {
  coreStore.toggleLoading(true);
  try {
    photo.value = newPhotoBase64.value ?? "";
    await sendPhoto(fileName.value, goBack.value);

    const toast = await toastController.create({
      message: "Imagen cargada con éxito.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  } catch (e) {
    const toast = await toastController.create({
      message: "Ocurrió un error al cargar la imagen, intente de nuevo.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }  finally {
    coreStore.toggleLoading(false);
  }
};
const increaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height + 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width + 10;
};
const decreaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height - 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width - 10;
};
const onChange = (canvas: CropperResult) => {
  newPhotoBase64.value = canvas.canvas?.toDataURL() ?? undefined;
};

const onFileChanged = (e: any) => {
  isSelecting.value = false;
  if (e.target.files && e.target.files[0]) {
    newPhoto.value = true;
    fileName.value = e.target.files[0].name;
    getBase64(e.target.files[0]);
  }
};

const getBase64 = (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img.value = reader.result;
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

const takePicture = async () => {
  const permissions = await Camera.requestPermissions();
  if (permissions.photos === "granted" || permissions.camera === "granted") {
    await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    }).then((photo) => {
      newPhoto.value = true;
      fileName.value = "photo.jpg";
      img.value = "data:image/jpeg;base64," + photo.base64String;
    });
  }
};

const handleFileImport = () => {
  isSelecting.value = true;
  window.addEventListener(
      "focus",
      () => {
        isSelecting.value = false;
      },
      {once: true}
  );
  if (isMobile) {
    takePicture();
  } else {
    uploader.value?.click();
  }
};

onMounted(() => {
  goBack.value = route.query.return == "true";
  img.value = getImageLetter(registerStore.user.last_name ?? 'LL');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-progress": false,
    "title-button-back": _ctx.$t('register.uploadPhoto'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Cropper), {
            ref_key: "cropper",
            ref: cropper,
            class: "photo-wrapper__background",
            src: img.value,
            "auto-zoom": true,
            "stencil-props": {
                handlers: {},
                movable: true,
                resizable: true,
            },
            "stencil-size": {
              width: cropperWidth.value,
              height: cropperHeight.value,
            },
            onChange: onChange
          }, null, 8, ["src", "stencil-size"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            fill: "clear",
            color: "neutral-40",
            onClick: decreaseCropper
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), { icon: _unref(removeOutline) }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            fill: "clear",
            color: "neutral-40",
            onClick: photoSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), { icon: _unref(scanOutline) }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            fill: "clear",
            color: "neutral-40",
            onClick: increaseCropper
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), { icon: _unref(addOutline) }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            fill: "outline",
            onClick: handleFileImport
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.changePhoto")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("input", {
            accept: "image/jpeg, image/png",
            ref_key: "uploader",
            ref: uploader,
            style: {"display":"none"},
            type: "file",
            onChange: onFileChanged
          }, null, 544),
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            onClick: photoSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.save")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})