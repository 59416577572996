import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-bold" }

import ModalDeleteComponent from "@/components/shared/modal/ModalDeleteComponent.vue";

interface Props {
  modelValue: boolean;
  name?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteComponent',
  props: {
    modelValue: { type: Boolean },
    name: {}
  },
  emits: ["update:modelValue", "confirm:delete"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const handleCloseModal = (value: boolean) => {
  emit("update:modelValue", value);
};

const handleClickDeleteModel = () => {
  emit("confirm:delete");
};

return (_ctx: any,_cache: any) => {
  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(ModalDeleteComponent, {
        key: 0,
        "name-item": _ctx.name,
        "model-value": _ctx.modelValue,
        "onUpdate:modelValue": handleCloseModal,
        "onConfirm:delete": handleClickDeleteModel
      }, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("h3", null, "¿Estás seguro? Confirma la eliminación de tu equipo", -1)
        ])),
        content: _withCtx(() => [
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createTextVNode(" Al eliminar el equipo ")),
            _createElementVNode("span", _hoisted_1, "\"" + _toDisplayString(_ctx.name) + "\"", 1),
            _cache[2] || (_cache[2] = _createTextVNode(" perderás toda la información y los miembros asociados a este, quedarán desvinculados. "))
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "ion-padding-top" }, [
            _createElementVNode("span", { class: "text-bold" }, " Para confirmar el borrado debes escribir el nombre del equipo "),
            _createTextVNode(" tal y como está registrado ")
          ], -1))
        ]),
        _: 1
      }, 8, ["name-item", "model-value"]))
    : _createCommentVNode("", true)
}
}

})