import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-task ion-padding" }
const _hoisted_2 = { class: "description-task" }
const _hoisted_3 = { class: "avatar-container-task" }
const _hoisted_4 = { class: "avatar-container-task" }
const _hoisted_5 = { class: "ion-padding-start" }
const _hoisted_6 = { class: "ion-padding-start" }
const _hoisted_7 = { class: "ion-margin-vertical" }
const _hoisted_8 = { class: "ion-padding-top" }

import { ref, computed } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import ModalDeleteComponent from "@/components/shared/modal/ModalSimpleDeleteComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { Task } from "@/interfaces/tasks/ITasks";
import AvatarDescriptionComponent from "./AvatarDescriptionComponent.vue";
import { handleDeleteModel, handlePutModel } from "@/services/generic";
import { MODEL, ENUM_TASK, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { schemanewTask } from "@/utils/getMapModel";
import { useRegister } from "@/composables/useRegister";
import CommentTask from "./CommentTask.vue";

import {
  IonPage,
  IonChip,
  IonContent,
  IonLabel,
  IonAvatar,
  IonButton,
  toastController,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";

interface Props {
  task: Task;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDetailTask',
  props: {
    task: {}
  },
  emits: ["click:back", "update:tasks"],
  setup(__props: any, { emit: __emit }) {

const schema: Ref<any> = ref(schemanewTask);
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const showModalDeleteTask: Ref<boolean> = ref(false);
const showModalUpdateTask: Ref<boolean> = ref(false);
const props = __props;
const emit = __emit;

const model: Ref<any> = ref({
  name: MODEL.TASK,
  fields: [
    {
      name: "title",
      label: "Nombre de la tarea",
      type: "textarea",
    },
    {
      name: "description",
      label: "Escribe una descripción",
      type: "textarea",
    },
    {
      name: "assigned_user_id",
      label: "Asignar a (Opcional)",
      type: "autocomplete",
      model: "user",
      filters: [
        {
          model: "userlocation",
          foreign_key: "user_id",
          field: "location_id",
          value: user.value.location_id,
          op: "==",
        },
      ],
    },
    {
      name: "is_priority",
      label: "Tarea prioritaria",
      type: "toggle",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
      default: new Date(),
      display: false,
    },
  ],
});

const finishTask = async () => {
  try {
    const payload = {
      model: MODEL.TASK,
      id: props.task.id,
      fields: {
        status: ENUM_TASK.finished,
      },
    };
    await handlePutModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("tasks.success_finished"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    emit("update:tasks");
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const deleteTask = async () => {
  try {
    const payload = {
      model: MODEL.TASK,
      id: props.task.id,
    };
    await handleDeleteModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("tasks.success_removed"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDeleteTask.value = false;
    setTimeout(() => {
      emit("update:tasks");
    }, 0);
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const updateTask = async () => {
  showModalUpdateTask.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("tasks.updated_task"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  emit("update:tasks");
};

const canOptionsMenu = computed(() => {
  return (
    props.task.status === ENUM_TASK.pending &&
    ((props.task.assigned_user_id === user.value.id || props.task.assigned_user_id != "") ||
      user.value.role !== ROLES.EMPLOYEE)
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(HeaderNavigationComponent, {
          "onClick:back": _cache[0] || (_cache[0] = ($event: any) => (emit('click:back'))),
          preventDefault: "",
          title: ""
        }),
        (canOptionsMenu.value)
          ? (_openBlock(), _createBlock(DropdownMenuTaskComponent, {
              key: 0,
              "text-edit": "tasks.edit",
              "text-delete": "tasks.delete",
              "onClick:delete": _cache[1] || (_cache[1] = ($event: any) => (showModalDeleteTask.value = true)),
              "onClick:edit": _cache[2] || (_cache[2] = ($event: any) => (showModalUpdateTask.value = true))
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_unref(IonContent), { class: "content-task ion-padding" }, {
        default: _withCtx(() => [
          (_ctx.task.is_priority)
            ? (_openBlock(), _createBlock(_unref(IonChip), { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonAvatar), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(VsxIcon), { iconName: "Flash" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('tasks.priorityTask')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("h3", null, _toDisplayString(_ctx.task.title), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.task.description), 1),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.task.assigned_user_id)
              ? (_openBlock(), _createBlock(AvatarDescriptionComponent, {
                  key: 0,
                  "user-id": _ctx.task.assigned_user_id,
                  label: _ctx.$t('tasks.assigned_to')
                }, null, 8, ["user-id", "label"]))
              : _createCommentVNode("", true),
            (_ctx.task.created_user_id)
              ? (_openBlock(), _createBlock(AvatarDescriptionComponent, {
                  key: 1,
                  "user-id": _ctx.task.created_user_id,
                  label: _ctx.$t('tasks.created_by')
                }, null, 8, ["user-id", "label"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.task.finalized_user_id)
              ? (_openBlock(), _createBlock(AvatarDescriptionComponent, {
                  key: 0,
                  "user-id": _ctx.task.finalized_user_id,
                  label: _ctx.$t('tasks.finalized_by')
                }, null, 8, ["user-id", "label"]))
              : _createCommentVNode("", true)
          ]),
          (canOptionsMenu.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.task.status === _unref(ENUM_TASK).pending)
                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 0,
                      fill: "outline",
                      expand: "block",
                      class: "button-finish ion-margin-top",
                      onClick: finishTask
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(VsxIcon), { iconName: "TickCircle" }),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("tasks.finish")), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 1,
                      fill: "outline",
                      expand: "block",
                      class: "button-finish ion-margin-top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(VsxIcon), { iconName: "TickCircle" }),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("tasks.finished_task")), 1)
                      ]),
                      _: 1
                    }))
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(CommentTask, {
              "task-id": _ctx.task?.id,
              "task-status": _ctx.task.status
            }, null, 8, ["task-id", "task-status"])
          ])
        ]),
        _: 1
      }),
      _createVNode(ModalDeleteComponent, {
        "onConfirm:delete": deleteTask,
        modelValue: showModalDeleteTask.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showModalDeleteTask).value = $event)),
        nameItem: _ctx.task.title
      }, null, 8, ["modelValue", "nameItem"]),
      _createVNode(ModalComponent, {
        noIconClose: "",
        modelValue: showModalUpdateTask.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showModalUpdateTask).value = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(HeaderNavigationComponent, {
            preventDefault: "",
            "onClick:back": _cache[4] || (_cache[4] = ($event: any) => (showModalUpdateTask.value = false)),
            title: 'Editar Tarea',
            class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
          }, null, 8, ["class"]),
          _createElementVNode("div", _hoisted_8, [
            (showModalUpdateTask.value)
              ? (_openBlock(), _createBlock(FormComponent, {
                  key: 0,
                  "no-cancel-button": "",
                  model: model.value,
                  "form-mode": "update",
                  "id-model": _ctx.task.id,
                  schema: schema.value,
                  onSuccess: updateTask
                }, {
                  submitButton: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      mode: "ios",
                      expand: "block",
                      type: "submit"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Guardar ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "id-model", "schema"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})