import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-search" }
const _hoisted_2 = { class: "filter-priority ion-padding-vertical" }
const _hoisted_3 = { class: "ion-flex ion-align-items-center" }
const _hoisted_4 = { class: "label-black" }
const _hoisted_5 = { class: "ion-flex ion-align-items-center" }
const _hoisted_6 = { class: "label-black" }
const _hoisted_7 = { class: "ion-flex ion-align-items-center" }
const _hoisted_8 = { class: "label-black" }
const _hoisted_9 = { class: "filter-priority ion-padding-vertical" }
const _hoisted_10 = { class: "ion-flex ion-align-items-center" }
const _hoisted_11 = { class: "label-black" }
const _hoisted_12 = { class: "ion-flex ion-align-items-center" }
const _hoisted_13 = { class: "label-black" }
const _hoisted_14 = { class: "ion-flex ion-align-items-center" }
const _hoisted_15 = { class: "label-black" }
const _hoisted_16 = { class: "ion-margin-vertical" }
const _hoisted_17 = { class: "buttons ion-flex ion-align-items-center ion-justify-content-center" }

import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import SelectAutocompleteComponent from "@/components/shared/form/SelectAutocompleteComponent.vue";
import { IonButton, IonItem, IonSearchbar, IonCheckbox } from "@ionic/vue";
import { useRegister } from "@/composables/useRegister";
import { useGenericStore } from "@/stores/generic";
import { ENUM_TASK, MODEL, TASKS_VIEW } from "@/utils/constants";
import { toRefs } from "vue";

interface Props {
  tab: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFiltersTask',
  props: {
    tab: {}
  },
  emits: ["update:filters"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const tabRef = toRefs(props).tab;
const search: Ref<any> = ref(null);
const is_priority_all: Ref<any> = ref(null);
const is_priority_true: Ref<any> = ref(null);
const is_priority_false: Ref<any> = ref(null);

const is_assigned_all: Ref<any> = ref(null);
const is_assigned_true: Ref<any> = ref(null);
const is_assigned_false: Ref<any> = ref(null);

const assigned_filter_value: Ref<any> = ref(null);

const { user } = useRegister();
const storeList = useGenericStore();

const assigned_filter: Ref<any> = ref({
  name: "assigned_user_id",
  label: "Asignado a ",
  icon: "SearchNormal",
  type: "autocomplete",
  model: "user",
  filters: [
    {
      model: "userlocation",
      foreign_key: "user_id",
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ],
});

const emit = __emit;

const getFilters = () => {
  const filters: any = [
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ];

  if (tabRef.value === TASKS_VIEW.PENDING) {
    const filterPriority = {
      field: "status",
      value: ENUM_TASK.pending,
      op: "==",
    };
    filters.push(filterPriority);
  } else {
    const filterPriority = {
      field: "status",
      value: ENUM_TASK.finished,
      op: "==",
    };
    filters.push(filterPriority);
  }

  if (is_priority_true.value) {
    const filterPriority = {
      field: "is_priority",
      op: "==",
      value: true,
    };
    filters.push(filterPriority);
  }
  if (is_priority_false.value) {
    const filterPriority = {
      field: "is_priority",
      op: "==",
      value: false,
    };
    filters.push(filterPriority);
  }

  if (is_assigned_true.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "!=",
      value: null,
    };
    filters.push(filterAssigned);
  }
  if (is_assigned_false.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "==",
      value: null,
    };
    filters.push(filterAssigned);
  }
  if (assigned_filter_value.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "==",
      value: assigned_filter_value.value,
    };
    filters.push(filterAssigned);
  }
  if (search.value) {
    const filterAssigned = {
      field: "title",
      op: "ilike",
      value: search.value,
    };
    filters.push(filterAssigned);
  }
  storeList.setFiltersModel(MODEL.TASK, filters);
  emit("update:filters");
};

const clearFilters = () => {
  is_priority_all.value = null;
  is_priority_true.value = null;
  is_priority_false.value = null;
  is_assigned_all.value = null;
  is_assigned_true.value = null;
  is_assigned_false.value = null;
  assigned_filter_value.value = null;
  search.value = null;
  storeList.setFiltersModel(MODEL.TASK, []);
  emit("update:filters");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(IonSearchbar), {
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        placeholder: _ctx.$t('global.searchByName')
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("tasks.priority")), 1),
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_priority_all.value,
            onIonChange: _cache[1] || (_cache[1] = (e) => is_priority_all.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tasks.all")), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_priority_true.value,
            onIonChange: _cache[2] || (_cache[2] = (e) => is_priority_true.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(VsxIcon), {
              iconName: "ArrowCircleUp2",
              class: "ion-margin-end"
            }),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("tasks.priorities")), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_priority_false.value,
            onIonChange: _cache[3] || (_cache[3] = (e) => is_priority_false.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(VsxIcon), {
              iconName: "MinusCirlce",
              class: "ion-margin-end"
            }),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("tasks.no_priorities")), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("tasks.assigned_tasks")), 1),
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_assigned_all.value,
            onIonChange: _cache[4] || (_cache[4] = (e) => is_assigned_all.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("tasks.all")), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_assigned_false.value,
            onIonChange: _cache[5] || (_cache[5] = (e) => is_assigned_false.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_unref(VsxIcon), {
              iconName: "Information",
              class: "ion-margin-end"
            }),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("tasks.no_assigned")), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), { class: "ion-padding-bottom" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCheckbox), {
            slot: "end",
            checked: is_assigned_true.value,
            onIonChange: _cache[6] || (_cache[6] = (e) => is_assigned_true.value = e.detail.checked)
          }, null, 8, ["checked"]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_unref(VsxIcon), {
              iconName: "TickCircle",
              class: "ion-margin-end"
            }),
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("tasks.assigned")), 1)
          ])
        ]),
        _: 1
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("tasks.assigned_to")), 1),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(SelectAutocompleteComponent, {
          modelValue: assigned_filter_value.value,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((assigned_filter_value).value = $event)),
          "item-title": "name",
          "item-value": "id",
          "field-search": assigned_filter.value.name,
          model: assigned_filter.value.model,
          filters: assigned_filter.value.filters,
          icon: assigned_filter.value.icon
        }, null, 8, ["modelValue", "field-search", "model", "filters", "icon"])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_unref(IonButton), {
        class: "button-clear",
        fill: "outline",
        onClick: clearFilters
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" Limpiar Filtros ")
        ])),
        _: 1
      }),
      _createVNode(_unref(IonButton), {
        class: "button-submit",
        onClick: getFilters
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(VsxIcon), { iconName: "Sort" }),
          _cache[9] || (_cache[9] = _createElementVNode("span", null, " Filtrar ", -1))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})