import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "join-wrapper__back" }
const _hoisted_2 = { class: "join-wrapper__body" }

import {
  IonBackButton,
  IonContent,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
} from "@ionic/vue";
import router from "@/router";
import { ref } from "vue";
import ApplicationRequestComponent from "@/components/shared/applications/ApplicationRequestComponent.vue";
import ApplicationActiveComponent from "@/components/shared/applications/ApplicationActiveComponent.vue";
import { APPLICATION_STATUS, MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";

import { useRegister } from "@/composables/useRegister";
import { handleMe } from "@/services/me";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useLocation } from "@/composables/useLocation";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinRequestListInitialView',
  setup(__props) {

const { user } = useRegister();
const registerStore: RegisterStore = useRegisterStore();
const { getLocation } = useLocation();
const { setLocation } = useRegister();

const selected = ref(APPLICATION_STATUS.WAITING);

const applicationSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? APPLICATION_STATUS.WAITING;
};

const handleRefresh = () => {
  const payload = {
    model: MODEL.APPLICATION,
    fields: ["id", "location_code", "user_id"],
  };
  const filter = [
    {
      field: "status",
      op: "in",
      value: ["accepted"],
    },
    {
      field: "user_id",
      op: "==",
      value: user.value.id,
    },
  ];
  handleSearchReadModel(payload, filter).then(async (data) => {
    if (data.items.length > 0) {
      handleMe().then(async (data: IUser) => {
        registerStore.setUser({
          user: data,
        });
        const location = await getLocation(
          registerStore.user.location_id ?? -1
        );
        if (location) {
          setLocation(location[0]);
        }
        await router.push({ name: "joinRequestList" });
      });
    } else {
      setTimeout(() => {
        router.go(0);
      }, 500);
    }
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRefresher), {
            slot: "fixed",
            onIonRefresh: handleRefresh
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonRefresherContent))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('joinTeam.myWorkspaces'),
              color: "neutral-80",
              defaultHref: "/join/sent"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_unref(IonSegment), {
              color: "primary",
              value: selected.value,
              mode: "md",
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (applicationSelected($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonSegmentButton), {
                  value: _unref(APPLICATION_STATUS).ACCEPTED
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonLabel), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.actives")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"]),
                _createVNode(_unref(IonSegmentButton), {
                  value: _unref(APPLICATION_STATUS).WAITING
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonLabel), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.requests")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _createElementVNode("section", null, [
            (selected.value == _unref(APPLICATION_STATUS).ACCEPTED)
              ? (_openBlock(), _createBlock(ApplicationActiveComponent, { key: 0 }))
              : _createCommentVNode("", true),
            (selected.value == _unref(APPLICATION_STATUS).WAITING)
              ? (_openBlock(), _createBlock(ApplicationRequestComponent, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})