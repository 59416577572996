import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "plan-wrapper__header" }
const _hoisted_2 = { class: "plan-wrapper__price" }
const _hoisted_3 = { class: "plan-wrapper__title" }
const _hoisted_4 = { class: "plan-wrapper__include caption" }
const _hoisted_5 = { class: "plan-wrapper__options" }
const _hoisted_6 = { class: "plan-wrapper__options__option" }

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/vue";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import { CoreStore, useCoreStore } from "@/stores/core";
import {PropType, ref} from "vue";
import {IPlanProduct} from "@/interfaces/plan_product/IPlanProduct";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanDetailUser',
  props: {
  planProducts: {
    type: Array as PropType<IPlanProduct[]>,
    required: true,
  },
},
  emits: ["selected"],
  setup(__props, { emit: __emit }) {

const planUserSelected = ref(1);
const coreStore: CoreStore = useCoreStore();
const emit = __emit;

const props = __props

const selectPlan = () => {
  emit("selected", props.planProducts[planUserSelected.value - 1]);
};

const plus = () => {
  planUserSelected.value++
  if (planUserSelected.value > props.planProducts.length) {
    planUserSelected.value = props.planProducts.length
  }
};

const minus = () => {
  planUserSelected.value--
  if (planUserSelected.value < 1) {
    planUserSelected.value = 1
  }
};


return (_ctx: any,_cache: any) => {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_unref(IonCard), { class: "plan-wrapper" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonCardContent), { class: "plan-wrapper__content" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "plan-wrapper__price__amount" }, "3€", -1)),
              _createTextVNode("/" + _toDisplayString(_ctx.$t("global.month")), 1)
            ]),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("register.planUsersTitle")), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("register.planIncludeNewUsers")), 1)
          ]),
          _createElementVNode("section", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createVNode(_unref(IonRow), { style: {"display":"flex","align-items":"center","justify-content":"center"} }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonCol), { size: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(caretUpOutline),
                          onClick: plus,
                          style: {"margin-right":"10px"}
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "7" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          value: planUserSelected.value,
                          readonly: "",
                          class: "plan-wrapper__options__input",
                          size: "3",
                          fill: "outline",
                          label: "Users",
                          type: "text",
                          placeholder: "1"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "2" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          size: "6",
                          icon: _unref(caretDownOutline),
                          onClick: minus
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--select ion-margin-top",
            type: "button",
            onClick: selectPlan
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.buyPlan")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})