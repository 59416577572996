import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal__actions" }

import ModalDeleteComponent from "@/components/shared/modal/ModalDeleteComponent.vue";
import {IonButton} from "@ionic/vue";

interface Props {
    modelValue: boolean;
    item?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteComponent',
  props: {
    modelValue: { type: Boolean },
    item: {}
  },
  emits: ["update:modelValue", "confirm:leave"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;


const handleCloseModal = () => {
    emit("update:modelValue", false);
};

const handleClickLeaveModel = () => {
    emit("confirm:leave", props.item);
};


return (_ctx: any,_cache: any) => {
  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(ModalDeleteComponent, {
        key: 0,
        "name-item": _ctx.item.name,
        "model-value": _ctx.modelValue,
        "onUpdate:modelValue": handleCloseModal,
        "onConfirm:delete": handleClickLeaveModel
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('joinTeam.leaveTeam', {'name': _ctx.item.name})), 1)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('joinTeam.leaveTeamDescription')), 1)
        ]),
        input: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", null, null, -1)
        ])),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonButton), {
              fill: "clear",
              onClick: handleCloseModal
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" No")
              ])),
              _: 1
            }),
            _createVNode(_unref(IonButton), {
              fill: "clear",
              onClick: handleClickLeaveModel
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Si, eliminar ")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["name-item", "model-value"]))
    : _createCommentVNode("", true)
}
}

})