import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password-wrapper__title form-padding" }
const _hoisted_2 = { class: "forgot-password-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPassword } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import { usePlatform } from "@/composables/usePlatform";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPasswordView',
  setup(__props) {

const { password, sendOwnerInfo } = useRegister();
const { isPlatformIOS } = usePlatform();

const model: Ref<any> = ref({
  name: "change_password",
  fields: [
    {
      name: "password",
      label: "Nueva contraseña",
      type: "password",
      icon: "key",
    },
    {
      name: "confirm_password",
      label: "Repite la contraseña",
      type: "password",
      icon: "key",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  password.value = values.password;
  await sendOwnerInfo();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-progress": false,
    "title-button-back": _ctx.$t('register.createProfile'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("register.changePassword")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("register.changePasswordMessage")), 1),
      _createVNode(FormComponent, {
        model: model.value,
        schema: _unref(schemaPassword),
        "no-cancel-button": "",
        onSuccess: passwordSubmit,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Guardar ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema"])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})