import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "join-wrapper__header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "join-wrapper__body" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "join-wrapper__title" }
const _hoisted_6 = { class: "join-wrapper__description caption" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import { IonButton, IonContent, IonPage } from "@ionic/vue";
import wehopBrand from "@/assets/wehop-brand.svg";
import joinWrong from "@/assets/join-wrong.svg";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinRequestWrongView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();

const goTo = () => {
  router.push({ name: "admin.home" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _unref(wehopBrand),
                alt: "WeHop Brand"
              }, null, 8, _hoisted_2)
            ]),
            _createElementVNode("div", null, [
              _createVNode(AvatarComponent, {
                "user-id": _unref(registerStore).user.id,
                "image-id": _unref(registerStore).user.image_id ?? -1
              }, null, 8, ["user-id", "image-id"])
            ])
          ]),
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("img", { src: _unref(joinWrong) }, null, 8, _hoisted_4),
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("joinTeam.requestWrong")), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("joinTeam.requestWrongMessage")), 1),
            _createVNode(_unref(IonButton), {
              class: "join-wrapper__button",
              fill: "solid",
              expand: "block",
              style: {"width":"100%"},
              onClick: goTo
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("actions.goToHome")), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})