import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-profile-wrapper__back" }
const _hoisted_2 = { class: "my-profile-wrapper__form" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import { schemaUpdateEntity } from "@/utils/getMapModel";
import { IonButton, IonBackButton, IonContent, IonPage } from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'BusinessUpdateInfoView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const { user } = useRegister();

const {
  name,
  commercial_name,
  address,
  vat,
  city_id,
  country_id,
  email,
  phone,
  number_employees,
  number_locals,
  updateEntityInfo,
} = useRegister();

const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "entity",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.businessName"),
      type: "text",
      icon: "Shop",
    },
    {
      name: "commercial_name",
      label: i18n.global.t("register.companyName"),
      type: "text",
      icon: "Edit",
    },
    {
      name: "vat",
      label: "CIF / NIF",
      type: "text",
      icon: "ClipboardText",
    },
    {
      name: "address",
      label: i18n.global.t("register.address"),
      type: "text",
      icon: "Location",
    },
    {
      icon: "Send2",
      name: "country_id",
      type: "autocomplete",
      label: i18n.global.t("register.country"),
      model: "country",
      field_search: "iso_code",
    },
    {
      icon: "Location",
      name: "city_id",
      type: "autocomplete",
      label: i18n.global.t("register.city"),
      model: "city",
      depends_on: "country",
      foreign_key: "iso_code",
    },
    {
      name: "phone_number",
      label: i18n.global.t("register.phone"),
      type: "text",
      icon: "Call",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "text",
      icon: "Sms",
    },
    {
      name: "number_locals",
      label: i18n.global.t("register.locals"),
      type: "text",
      icon: "Copy",
    },
    {
      name: "number_employees",
      label: i18n.global.t("register.employees"),
      type: "text",
      icon: "People",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  console.log(values);
  name.value = values.name;
  commercial_name!.value = values.commercial_name ?? undefined;
  address!.value = values.address;
  city_id!.value = values.city_id;
  country_id!.value = values.country_id;
  vat!.value = values.vat;
  email.value = values.email;
  phone.value = values.phone_number;
  number_employees.value = values.number_employees;
  number_locals.value = values.number_locals;
  await updateEntityInfo(user.value.entity_id ?? "");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding my-profile-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('myProfile.updateData'),
              color: "neutral-80",
              defaultHref: "/my/profile/owner"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(FormComponent, {
              ref_key: "form",
              ref: form,
              model: model.value,
              schema: _unref(schemaUpdateEntity),
              "form-mode": "update",
              "id-model": _unref(registerStore).user.entity_id,
              "no-cancel-button": "",
              onSuccess: businessInfoSubmit,
              "prevent-default": ""
            }, {
              submitButton: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  mode: "ios",
                  expand: "block",
                  class: "button--login ion-margin-top",
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("actions.update")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "schema", "id-model"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})