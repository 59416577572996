import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bar--record" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = { class: "tab tab--left" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "tab tab--fab" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "tab tab--right" }
const _hoisted_8 = { key: 0 }

import { useRoute, useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import { useRegister } from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainButtonBarComponent',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { user } = useRegister();

const tabSelected = (itemSelected: any) => {
  return route.path === itemSelected;
};

const handleMenuResend = () => {
  if (user.value.role === 'owner') {
    router.push({ name: 'admin.home' })
  } else {
    router.push({ name: 'employee.home' })
  }  
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'tab-selected': tabSelected('/home') }),
            onClick: handleMenuResend
          }, [
            _createVNode(_unref(VsxIcon), { iconName: "Home2" }),
            (tabSelected('/home'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("navigation.home")), 1))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'tab-selected': tabSelected('/talkie') }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'default.talkie.list' })))
          }, [
            _createVNode(_unref(VsxIcon), { iconName: "Microphone2" }),
            (tabSelected('/talkie'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("navigation.talkie")), 1))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'tab-selected': tabSelected('/tasks') }),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ name: 'default.tasks.list' })))
          }, [
            _createVNode(_unref(VsxIcon), { iconName: "ClipboardText" }),
            (tabSelected('/tasks'))
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("navigation.tasks")), 1))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}
}

})