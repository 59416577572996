import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "photo-edit-wrapper" }

import { IonAvatar } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import AvatarUserComponent from "@/components/shared/avatar/AvatarUserComponent.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import AvatarMemberComponent from "@/components/shared/avatar/AvatarMemberComponent.vue";
import {RegisterStore, useRegisterStore} from "@/stores/register";


export default /*@__PURE__*/_defineComponent({
  __name: 'PhotoEditComponent',
  props: {
  goBack: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();

const props = __props;

const uploadPhoto = () => {
  registerStore.setUserSelectedPhoto("");
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(coreStore).selectedUser)
      ? (_openBlock(), _createBlock(AvatarMemberComponent, {
          key: 0,
          width: 120,
          height: 120,
          "is-new": ""
        }))
      : (_openBlock(), _createBlock(AvatarUserComponent, {
          key: 1,
          width: 120,
          height: 120,
          "is-new": ""
        })),
    _createElementVNode("div", {
      class: "photo-edit-wrapper__icon",
      onClick: uploadPhoto
    }, [
      _createVNode(_component_router_link, {
        to: `/register/upload-photo?return=${__props.goBack}`
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(VsxIcon), { iconName: "Camera" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
}

})