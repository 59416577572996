import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "application-pending" }
const _hoisted_2 = { class: "ion-flex ion-justify-content-between ion-align-items-center group-member-wrapper" }

import { IonAlert, IonCol } from "@ionic/vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { ref, Ref, watch } from "vue";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import { useGenericStore } from "@/stores/generic";
import { handleDeleteModel, handleGetDescribeModel, handlePutModel, handleSearchReadModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import MemberWrapper from "@/components/admin/group/MemberWrapper.vue";
import DropdownMenuMemberComponent from "@/components/admin/group/DropdownMenuMemberComponent.vue";
import router from "@/router";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { useList } from "@/composables/useList";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupMemberComponent',
  setup(__props) {

const { isMobile } = useNativePlatform();
const storeList = useGenericStore();
const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { refreshUserLocation } = useCore();
const { getModelData } = useList();

const headers = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: i18n.global.t("joinTeam.userId"),
    name: "user_id",
  },
  {
    label: i18n.global.t("joinTeam.code"),
    name: "location_id",
  },
  {
    label: "",
    name: "role_id",
  },
  {
    label: "",
    name: "image_id",
  },
  {
    label: "",
    name: "actions",
  },
];

const model: Ref<any> = ref({
  name: MODEL.USERLOCATION,
  fields: [],
});

const isOpen = ref(false);
const locationId = ref("");
const userId = ref("");
const alertButtons = [
  {
    text: i18n.global.t("actions.no"),
  },
  {
    text: i18n.global.t("actions.confirmDelete"),
    cssClass: "alert-button-confirm",
    handler: async () => {
      await handleDeleteModel({
        model: MODEL.USERLOCATION,
        id: locationId.value,
      }).then(async () => {
        router.go(0);
      });
    },
  },
];

const userInfo = ref<IUser>();

storeList.setItemsModel(MODEL.USERLOCATION, []);
storeList.setFiltersModel(MODEL.USERLOCATION, [
  {
    field: "location_id",
    op: "==",
    value: registerStore.location.id,
  },
]);


const openDetail = (item: any) => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "id",
        "name",
        "last_name",
        "role",
        "prefix_number",
        "phone_number",
        "email",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      userInfo.value = data.items[0] as IUser;
      coreStore.setSelectedUser(userInfo.value);
      if (userInfo.value.role == ROLES.OWNER) {
        await router.push({
          name: "myProfileOwner",
          query: { mode: "detail" },
        });
      } else if (userInfo.value.role == ROLES.MANAGER) {
        await router.push({
          name: "myProfileManager",
          query: { mode: "detail" },
        });
      } else {
        await router.push({
          name: "myProfileEmployee",
          query: { mode: "detail" },
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
};

const openEdit = (item: any) => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "id",
        "name",
        "last_name",
        "role",
        "prefix_number",
        "phone_number",
        "email",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      userInfo.value = data.items[0] as IUser;
      coreStore.setSelectedUser(userInfo.value);
      if (userInfo.value.role == ROLES.OWNER) {
        await router.push({ name: "myProfileOwner", params: { mode: "edit" } });
      } else if (userInfo.value.role == ROLES.MANAGER) {
        await router.push({
          name: "myProfileManager",
          params: { mode: "edit" },
        });
      } else {
        await router.push({
          name: "myProfileEmployee",
          params: { mode: "edit" },
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
};

const setOpen = (state: boolean) => {
  isOpen.value = state;
};

const openDelete = async (item: any) => {
  locationId.value = item.id;
  userId.value = item.user_id;
  isOpen.value = true;
};

const getModel = (payload?: any) => {
  getModelData({
    headers: headers,
    modelName: MODEL.USERLOCATION,
    reset: payload?.reset,
  });
};

watch(refreshUserLocation, () => {
  storeList.setItemsModel(MODEL.USERLOCATION, []);
  storeList.setRefresh(true);
  if (refreshUserLocation.value) {
    storeList.setItemsModel(MODEL.USERLOCATION, []);
    getModel({ reset: true });
    storeList.$reset();
    coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IonCol), {
      size: "12",
      class: _normalizeClass({ 'ion-padding-top ion-no-padding': _unref(isMobile) })
    }, {
      default: _withCtx(() => [
        _createVNode(ListWrapper, {
          model: model.value.name,
          headers: headers
        }, {
          item_mobile: _withCtx((props ) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MemberWrapper, { item: props }, null, 8, ["item"]),
              props
                ? (_openBlock(), _createBlock(DropdownMenuMemberComponent, {
                    key: 0,
                    item: props,
                    "show-delete": parseInt(props.role_id) !== _unref(ROLE_ID).OWNER,
                    "is-owner": parseInt(props.role_id) === _unref(ROLE_ID).OWNER,
                    "onClick:detail": ($event: any) => (openDetail(props)),
                    "onClick:edit": ($event: any) => (openEdit(props)),
                    "onClick:delete": ($event: any) => (openDelete(props))
                  }, null, 8, ["item", "show-delete", "is-owner", "onClick:detail", "onClick:edit", "onClick:delete"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_unref(IonAlert), {
      "is-open": isOpen.value,
      header: _ctx.$t('group.deleteUserConfirm'),
      message: _ctx.$t('group.deleteUserConfirmMessage'),
      buttons: alertButtons,
      onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (setOpen(false)))
    }, null, 8, ["is-open", "header", "message"])
  ]))
}
}

})