import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "name"
}
const _hoisted_3 = {
  key: 0,
  class: "item-container__notification"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "name role-name"
}
const _hoisted_6 = {
  key: 0,
  class: "item-container__notification"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "name"
}

import type { Ref } from "vue";
import { computed, onMounted, ref, watch } from "vue";
import { getListUsers } from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import router from "@/router";
import useRecord from "@/composables/useRecord";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import useCore from "@/composables/useCore";
import { CoreStore, useCoreStore } from "@/stores/core";
import UserPhoto from "@/components/admin/talkie/UserPhoto.vue";

interface Props {
  listMode: boolean;
  search: string;
}

interface TouchInfo {
  startTime: number;
  timer: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarListComponent',
  props: {
    listMode: { type: Boolean },
    search: {}
  },
  setup(__props: any) {

const props = __props;

const registerStore: RegisterStore = useRegisterStore();

const items: Ref<any[]> = ref([]);

const itemRecording: Ref<number | null> = ref(null);
const touchInfo: TouchInfo = { startTime: 0, timer: null };
const { recording, nameUser, playing, toggleRecord } = useRecord();
const coreStore: CoreStore = useCoreStore();
const { 
  searchUserInput, 
  refreshUserLocation, 
  setRefreshNotification,
  refreshNotification } = useCore();
const { user, notifications, setNotifications } = useRegister();

const classItem = (item: any) => {
  const classes = [];
  if (item.id === itemRecording.value) {
    classes.push("recording");
  } else if (item.id !== itemRecording.value && itemRecording.value) {
    classes.push("no-recording");
  }

  if (item.status !== "active") {
    classes.push("inactive");
  }
  return classes.join(" ");
};

const onTouchStart = (item: any) => {
  itemRecording.value = item.id === itemRecording.value ? null : item.id;
  toggleRecord({
    nameUser: item.name,
    durationAudio: 2,
    mode: NOTIFICATION_TYPE.USER,
    userId: item.id,
  });
};

const listModeStyle = computed(() => {
  return !props.listMode
    ? "grid-template-columns: repeat(4, 1fr);"
    : "grid-template-columns: repeat(1, 1fr);";
});

const listDirectionStyle = computed(() => {
  return !props.listMode
    ? "flex-direction: column;justify-content: center;"
    : "flex-direction: row; justify-content: start;";
});

const listNameStyle = computed(() => {
  return !props.listMode
    ? "font-size: 12px; font-weight:400;"
    : "font-size: 16px; font-weight:600; color:#2E333D;";
});

const listAvatarStyle = computed(() => {
  return !props.listMode
    ? ""
    : "width: 55px !important;height: 55px !important;";
});

const showLocaleAudio = () => {
  router.push({ name: "default.talkie.locale.audio" });
};

const showPersonalAudio = (item: any) => {
  router.push({
    name: "default.talkie.personal.audio",
    params: { id: item.id },
  });
};

const notificationsLocation = computed(() => {
  const notification = notifications.value.find((notification) => {
    if ("location_id" in notification) {
      return notification;
    }
  });
  if (notification) {
    return notification["number notifications"] ?? 0;
  } else {
    return 0;
  }
});
const notificationsUser = (userId: number) => {
  const notification = notifications.value.find(
    (notification) => notification.user_id === userId
  );
  return notification ? notification["number notifications"] : 0;
};

onMounted(async () => {
  await setNotifications();
  items.value = await getListUsers(user.value.location_id);
});

watch(searchUserInput, async () => {
  items.value = await getListUsers(
    user.value.location_id,
    searchUserInput.value
  );
});

watch(refreshUserLocation, async () => {
  if (refreshUserLocation.value) {
    items.value = await getListUsers(
        user.value.location_id,
        searchUserInput.value
    );
    await setNotifications();
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
    coreStore.setRefreshTask(false);
    coreStore.setRefreshDashboardOwner(false);
    coreStore.setRefreshDashboard(false);
    router.go(0);
  }
});

watch(refreshNotification, async () => {
  await setNotifications();
  items.value = await getListUsers(user.value.location_id);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "grid-container",
    style: _normalizeStyle(listModeStyle.value)
  }, [
    (_ctx.listMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "item-container",
            style: _normalizeStyle(listDirectionStyle.value),
            onClick: showLocaleAudio
          }, [
            _createVNode(AvatarLocaleComponent, {
              "image-id": _unref(registerStore).location.image_id,
              "locale-id": _unref(registerStore).location.id ?? -1,
              "locale-name": _unref(registerStore).location.name ?? '',
              class: "photo-wrapper"
            }, null, 8, ["image-id", "locale-id", "locale-name"]),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", {
              class: _normalizeClass([{ 'ion-padding-start': _ctx.listMode }, "ion-flex ion-align-items-start"]),
              style: {"flex-direction":"column"}
            }, [
              _createElementVNode("span", {
                class: "name",
                style: _normalizeStyle(listNameStyle.value)
              }, _toDisplayString(_unref(registerStore).location.name), 5),
              (_ctx.listMode)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("talkie.generalChannel")), 1))
                : _createCommentVNode("", true)
            ], 2),
            (notificationsLocation.value > 0)
              ? (_openBlock(), _createElementBlock("em", _hoisted_3, _toDisplayString(notificationsLocation.value), 1))
              : _createCommentVNode("", true)
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id
      }, [
        (_ctx.listMode)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["item-container", classItem(item)]),
              style: _normalizeStyle(listDirectionStyle.value),
              onClick: ($event: any) => (showPersonalAudio(item))
            }, [
              _createVNode(UserPhoto, {
                "list-mode": _ctx.listMode,
                item: item,
                "item-recording": itemRecording.value ?? 0
              }, null, 8, ["list-mode", "item", "item-recording"]),
              _createElementVNode("div", {
                class: _normalizeClass([{ 'ion-padding-start': _ctx.listMode }, "ion-flex ion-align-items-start"]),
                style: {"flex-direction":"column"}
              }, [
                _createElementVNode("span", {
                  class: "name",
                  style: _normalizeStyle(listNameStyle.value)
                }, _toDisplayString(item.name), 5),
                (_ctx.listMode)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(`global.${item.role}`)), 1))
                  : _createCommentVNode("", true)
              ], 2),
              (notificationsUser(item.id) > 0)
                ? (_openBlock(), _createElementBlock("em", _hoisted_6, _toDisplayString(notificationsUser(item.id)), 1))
                : _createCommentVNode("", true)
            ], 14, _hoisted_4))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["item-container", classItem(item)]),
              ref_for: true,
              ref: "longPress",
              onClick: ($event: any) => (onTouchStart(item)),
              onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
              style: _normalizeStyle(listDirectionStyle.value)
            }, [
              _createVNode(UserPhoto, {
                "list-mode": _ctx.listMode,
                item: item,
                "item-recording": itemRecording.value ?? 0,
                "show-recording": true
              }, null, 8, ["list-mode", "item", "item-recording"]),
              _createElementVNode("div", {
                class: _normalizeClass([{ 'ion-padding-start': _ctx.listMode }, "ion-flex ion-align-items-start"]),
                style: {"flex-direction":"column"}
              }, [
                _createElementVNode("span", {
                  class: "name",
                  style: _normalizeStyle(listNameStyle.value)
                }, _toDisplayString(item.name), 5),
                (_ctx.listMode)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(`global.${item.role}`)), 1))
                  : _createCommentVNode("", true)
              ], 2)
            ], 46, _hoisted_7))
      ]))
    }), 128))
  ], 4))
}
}

})