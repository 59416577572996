import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "talkie-wrapper__back" }
const _hoisted_2 = {
  key: 0,
  class: "talkie-wrapper__locale"
}
const _hoisted_3 = { class: "locale-info-wrapper" }
const _hoisted_4 = {
  id: "locale_name",
  class: "ion-padding"
}
const _hoisted_5 = { class: "talkie-wrapper__options" }
const _hoisted_6 = {
  key: 0,
  class: "caption message-container__grouped__title"
}
const _hoisted_7 = {
  key: 0,
  class: "message-container__delete-message"
}

import {
  InfiniteScrollCustomEvent,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { computed, onMounted, ref } from "vue";
import { useMessage } from "@/composables/useMessage";
import MessageWrapper from "@/components/admin/talkie/MessageWrapper.vue";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import { useRoute } from "vue-router";
import DropdownMenuAudioPersonalComponent from "@/components/admin/talkie/DropdownMenuAudioPersonalComponent.vue";
import { useUser } from "@/composables/useUser";
import { IUser } from "@/interfaces/login/ILoginResponse";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import { handleRemoveNotification } from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'TalkiePersonalAudioView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { messages, notifications, grouped, getLocationMessagesByUser, setPage } =
  useMessage();
const { getUserInfo } = useUser();
const { setNotifications } = useRegister();

const showRecord = ref(false);
const route = useRoute();

const user = ref<IUser>();

const keys = computed(() => {
  return Object.keys(grouped.value);
});

const notificationList = computed(() => {
  return notifications.value;
});

/**
 * Close contextual menu
 */
const closeContextualMenu = () => {
  coreStore.setShowDropdown(false);
}

/**
 * Delete all notifications
 */
const deleteNotifications = () => {
  coreStore.setShowDropdown(false);
  notifications.value.forEach((notifications) => {
    handleRemoveNotification(notifications.message_id).then(() => {
      setNotifications();
    });
  });
};


/**
 * Infinite scroll handler
 * @param ev
 */
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
  setPage();
  await getLocationMessagesByUser(
    registerStore.location.id,
    user.value?.id.toString()
  );
  setTimeout(() => ev.target.complete(), 500);
};

onMounted(async () => {
  const userId = route.params.id!.toString() ?? null;
  const users = await getUserInfo(parseInt(userId));
  user.value = users.items[0];
  await getLocationMessagesByUser(registerStore.location.id, userId);
});

return (_ctx: any,_cache: any) => {
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createBlock(_unref(IonPage), {
    onClick: _withModifiers(closeContextualMenu, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonHeader), { class: "talkie-wrapper" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(IonBackButton), {
                  text: "",
                  color: "neutral-80",
                  defaultHref: "/talkie"
                })
              ]),
              (user.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(AvatarComponent, {
                      "image-id": user.value.image_id ?? -1,
                      "user-id": user.value.id
                    }, null, 8, ["image-id", "user-id"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("h3", _hoisted_4, _toDisplayString(user.value.last_name), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(DropdownMenuAudioPersonalComponent)
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonContent), { class: "message-container" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(keys.value, (key) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "message-container__grouped",
                  key: key
                }, [
                  (_unref(grouped)[key].length)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t(`global.${key}`)), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(grouped)[key], (message) => {
                    return (_openBlock(), _createBlock(MessageWrapper, {
                      notifications: _unref(notifications),
                      message: message,
                      key: message
                    }, null, 8, ["notifications", "message"]))
                  }), 128))
                ]))
              }), 128)),
              (notificationList.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("span", {
                      class: "register-link",
                      onClick: deleteNotifications
                    }, _toDisplayString(_ctx.$t("talkie.markAsListened")), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_infinite_scroll, { onIonInfinite: ionInfinite }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_infinite_scroll_content)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (showRecord.value)
            ? (_openBlock(), _createBlock(RecordBarComponent, {
                key: 0,
                "show-all-options": false
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})