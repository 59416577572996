import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "finish-wrapper" }
const _hoisted_2 = { class: "downgrade-title" }
const _hoisted_3 = ["innerHTML"]

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import { useRouter } from "vue-router";

import "cordova-plugin-purchase";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { computed } from "vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";

export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeTeamsFinishView',
  setup(__props) {

const { isPlatformIOS } = usePlatform();
const router = useRouter();

const registerStore: RegisterStore = useRegisterStore();
 const localeName = computed(() => {
   return registerStore.location.name ?? '';
 });

const goHome = async () => {
  await router.replace({ name: "admin.home" });
};


return (_ctx: any,_cache: any) => {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("global.ready")), 1),
        _createElementVNode("p", {
          class: "downgrade-description",
          innerHTML: 
            _ctx.$t('payment.downgradeTeamFinished', {locale_name: localeName.value})
          
        }, null, 8, _hoisted_3),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  expand: "block",
                  onClick: goHome
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("actions.goToDashboard")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})