import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dashboard-wrapper" }
const _hoisted_2 = { class: "notice-wrapper" }
const _hoisted_3 = { class: "notice-wrapper__icon" }
const _hoisted_4 = { class: "notice-wrapper__text" }
const _hoisted_5 = { class: "notice-wrapper" }
const _hoisted_6 = { class: "notice-wrapper__icon" }
const _hoisted_7 = { class: "notice-wrapper__text" }

import { RegisterStore, useRegisterStore } from "@/stores/register";
import { VsxIcon } from "vue-iconsax";
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
import { onMounted, ref, watch } from "vue";
import { handlerDashboardMember } from "@/services/dashboard";
import { IDashboardMember } from "@/interfaces/dashboard/IDashboardMember";
import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { IAdvice } from "@/interfaces/advice/IAdvice";
import { Task } from "@/interfaces/tasks/ITasks";
import { addOutline } from "ionicons/icons";
import router from "@/router";
import { APPLICATION_STATUS } from "@/utils/constants";
import useCore from "@/composables/useCore";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexView',
  setup(__props) {

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const registerStore: RegisterStore = useRegisterStore();

const dashboardMember = ref<IDashboardMember>();
const advices = ref<IAdvice[]>();
const tasks = ref<Task[]>();

const { setJoinRequestTabSelected, setRefreshDashboard, refreshDashboard } =
  useCore();

///Get Advices
const getAdvices = async () => {
  try {
    if (registerStore.location.id) {
      const payload = {
        model: MODEL.ADVICE,
        fields: ["id", "name", "date_start", "date_end"],
      };

      handleSearchReadModel(payload, [
        { field: "location_id", op: "==", value: registerStore.location.id },
      ]).then((data) => {
        advices.value = data.items as IAdvice[];
      });
    } else {
      advices.value = [];
    }
  } catch (err) {
    advices.value = [];
    console.error(err.message);
  }
};

watch(refreshDashboard, async () => {
  try {
    const data: IDashboardMember = await handlerDashboardMember();
    dashboardMember.value = data;
    if (dashboardMember.value.number_locations == 0) {
      await router.push({ name: "joinHome" });
    }
  } catch (e) {
    console.error(e);
  }
});

onMounted(async () => {
  try {
    const data: IDashboardMember = await handlerDashboardMember();
    dashboardMember.value = data;
    if (dashboardMember.value.number_locations == 0) {
      await router.push({ name: "joinHome" });
    } else {
      await getAdvices();
    }
  } catch (e) {
    console.error(e);
  }
});

const handleGetRoute = (to: any) => {
  if (to === "joinRequestList.waiting") {
    setJoinRequestTabSelected(APPLICATION_STATUS.WAITING);
    router.push({ name: "joinRequestList" });
  }
  if (to === "joinRequestList.accepted") {
    setJoinRequestTabSelected(APPLICATION_STATUS.ACCEPTED);
    router.push({ name: "joinRequestList" });
  }
  if (to === "default.talkie.list") {
    router.push({ name: to });
  }
  if (to === "default.tasks.list") {
    router.push({ name: to });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      (dashboardMember.value)
        ? (_openBlock(), _createBlock(WrapperPageComponent, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonContent), { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createElementVNode("section", _hoisted_1, [
                    (advices.value || tasks.value)
                      ? (_openBlock(), _createBlock(_unref(IonSlides), {
                          key: 0,
                          pager: true,
                          options: slideOpts
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(advices.value, (advice) => {
                              return (_openBlock(), _createBlock(_unref(IonSlide), { key: advice }, {
                                default: _withCtx(() => [
                                  _createElementVNode("section", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                      _createVNode(_unref(VsxIcon), {
                                        size: "20",
                                        class: "card-business-wrapper__icon",
                                        iconName: "Notification",
                                        type: "linear"
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_4, [
                                      _createElementVNode("p", null, _toDisplayString(advice.name), 1)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tasks.value, (task) => {
                              return (_openBlock(), _createBlock(_unref(IonSlide), { key: task }, {
                                default: _withCtx(() => [
                                  _createElementVNode("section", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                      _createVNode(_unref(VsxIcon), {
                                        size: "20",
                                        class: "card-business-wrapper__icon",
                                        iconName: "NoteText",
                                        type: "linear"
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("p", null, _toDisplayString(task.title), 1)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(IonRow), { class: "margin-top-20" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonCol), { size: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(CardBusinessInfo, {
                              icon: "Shop",
                              value: dashboardMember.value.number_locations,
                              text: _ctx.$t('global.yourTeams'),
                              "color-white": "",
                              center: "",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleGetRoute('joinRequestList.accepted')), ["prevent"]))
                            }, null, 8, ["value", "text"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonCol), { size: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(CardBusinessInfo, {
                              icon: "Microphone2",
                              value: dashboardMember.value.number_message,
                              text: _ctx.$t('global.unReadMessages'),
                              "color-white": "",
                              center: "",
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleGetRoute('default.talkie.list')), ["prevent"]))
                            }, null, 8, ["value", "text"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonCol), { size: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(CardBusinessInfo, {
                              icon: "Send2",
                              value: dashboardMember.value.number_invitations,
                              text: _ctx.$t('global.yourInvitations'),
                              "color-white": "",
                              center: "",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleGetRoute('joinRequestList.waiting')), ["prevent"]))
                            }, null, 8, ["value", "text"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonCol), { size: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(CardBusinessInfo, {
                              icon: "Note",
                              value: `${dashboardMember.value.number_tasks.pending}/${dashboardMember.value.number_tasks.total}`,
                              text: _ctx.$t('global.pendingTask'),
                              "color-white": "",
                              center: "",
                              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (handleGetRoute('default.tasks.list')), ["prevent"]))
                            }, null, 8, ["value", "text"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("section", null, [
                    _createVNode(_unref(IonButton), {
                      class: "join-wrapper__button",
                      fill: "clear",
                      expand: "block",
                      style: {"width":"100%"},
                      "router-link": "/join/new"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("actions.joinAnotherTeam")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})