import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import { IonCard, IonAvatar, IonItem, toastController } from "@ionic/vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import ModalDetailAdvice from "./ModalDetailAdvice.vue";
import ModalDeleteComponent from "@/components/shared/modal/ModalSimpleDeleteComponent.vue";
import { MODEL } from "@/utils/constants";
import { handleDeleteModel } from "@/services/generic";
import i18n from "@/plugins/i18n";

interface Props {
  advice: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemNotification',
  props: {
    advice: {}
  },
  emits: ["update:advices"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const showModalDeleteAdvice: Ref<boolean> = ref(false);

const showDetail: Ref<boolean> = ref(false);

const handleUpdateAdvices = () => {
  showDetail.value = false;
  emit("update:advices");
};

const deleteAdvice = async () => {
  try {
    const payload = {
      model: MODEL.ADVICE,
      id: props.advice.id,
    };
    await handleDeleteModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("advice.success_removed"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDeleteAdvice.value = false;
    setTimeout(() => {
      emit("update:advices");
    }, 0);
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("advice.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleDeleteAdvice = () => {
  showModalDeleteAdvice.value = true;
  setTimeout(() => {
    showDetail.value = false;
  }, 0);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonCard), {
      class: "ion-no-margin ion-margin-vertical",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showDetail.value = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonItem), { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonAvatar), {
              slot: "start",
              class: "icon-notification ion-margin-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(VsxIcon), { iconName: "Notification" })
              ]),
              _: 1
            }),
            _createElementVNode("p", null, _toDisplayString(_ctx.advice.name), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (showModalDeleteAdvice.value)
      ? (_openBlock(), _createBlock(ModalDeleteComponent, {
          key: 0,
          "onConfirm:delete": deleteAdvice,
          modelValue: showModalDeleteAdvice.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showModalDeleteAdvice).value = $event)),
          nameItem: _ctx.advice.name
        }, {
          title: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("h3", null, "Vas a eliminar el aviso del listado", -1)
          ])),
          _: 1
        }, 8, ["modelValue", "nameItem"]))
      : _createCommentVNode("", true),
    _createVNode(ModalComponent, {
      modelValue: showDetail.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showDetail).value = $event)),
      noIconClose: "",
      "initial-breakpoint": 0.4,
      breakpoints: [0, 0.25, 0.5, 0.6, 0.75]
    }, {
      default: _withCtx(() => [
        _createVNode(ModalDetailAdvice, {
          advice: _ctx.advice,
          "onClick:back": _cache[2] || (_cache[2] = ($event: any) => (showDetail.value = false)),
          "onUpdate:advices": handleUpdateAdvices,
          "onClick:delete": handleDeleteAdvice
        }, null, 8, ["advice"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})