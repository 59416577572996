import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import {
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { Item } from "@/interfaces/GenericModel";

interface Props {
  items: Array<Item>;
  hideHeaders: Array<string> | [] | undefined;
  titleKey?: string;
  descriptionKey?: string;
  page: number;
  total: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListComponentMobile',
  props: {
    items: {},
    hideHeaders: {},
    titleKey: { default: "name" },
    descriptionKey: {},
    page: {},
    total: {}
  },
  emits: ["page"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const ionInfinite = (ev: any) => {
  emit("page", { page: props.page + 1 });
  setTimeout(() => ev.target.complete(), 500);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return _renderSlot(_ctx.$slots, `item_mobile`, _mergeProps({
        key: index,
        ref_for: true
      }, item), () => [
        _createTextVNode(_toDisplayString(item[_ctx.titleKey]) + " " + _toDisplayString(_ctx.titleKey) + " ", 1),
        _createVNode(_unref(IonItem), {
          detail: "",
          button: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonLabel), null, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(item[_ctx.titleKey]), 1),
                (_ctx.descriptionKey)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(item[_ctx.descriptionKey]), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ])
    }), 128)),
    (_ctx.items.length < _ctx.total)
      ? (_openBlock(), _createBlock(_unref(IonInfiniteScroll), {
          key: 0,
          onIonInfinite: ionInfinite
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonInfiniteScrollContent))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})