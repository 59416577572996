import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-business-wrapper__value" }
const _hoisted_2 = { class: "card-business-wrapper__text" }

import { VsxIcon } from "vue-iconsax";

export default /*@__PURE__*/_defineComponent({
  __name: 'CardBusinessInfo',
  props: {
  icon: {
    type: String,
  },
  value: {
    type: [Number, String],
  },
  text: {
    type: String,
  },
  colorWhite: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["card-business-wrapper", { white: __props.colorWhite, center: __props.center }])
  }, [
    _createElementVNode("div", null, [
      _createVNode(_unref(VsxIcon), {
        class: "card-business-wrapper__icon",
        iconName: __props.icon,
        type: "linear"
      }, null, 8, ["iconName"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ center: __props.center })
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(__props.value), 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(__props.text), 1)
    ], 2)
  ], 2))
}
}

})