import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "container",
  ref: "menu"
}
const _hoisted_2 = {
  key: 0,
  class: "action-wrapper-waiting"
}
const _hoisted_3 = {
  key: 1,
  class: "action-wrapper-rejected"
}
const _hoisted_4 = { class: "ion-flex ion-justify-content-between ion-align-items-center ion-padding item__select" }

import {IonCol} from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {ref, Ref} from "vue";
import ItemMobileComponent from "@/components/shared/applications/ItemMobileComponent.vue";
import {APPLICATION_STATUS, MODEL} from "@/utils/constants";
import i18n from "@/plugins/i18n";
import WaitingComponent from "@/components/shared/applications/WaitingComponent.vue";
import {useGenericStore} from "@/stores/generic";
import {useRegister} from "@/composables/useRegister";


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationRequestComponent',
  setup(__props) {

const {isMobile} = useNativePlatform();
const storeList = useGenericStore();
const {user} = useRegister();

const headers = [
  {
    label: 'Id',
    name: 'id'
  },
  {
    label: i18n.global.t('joinTeam.code'),
    name: 'location_code'
  },
  {
    label: i18n.global.t('joinTeam.status'),
    name: "status",
  },
  {
    label: "",
    name: "actions",
  },
];

const model: Ref<any> = ref({
  name: MODEL.APPLICATION,
  fields: [],
});

storeList.setItemsModel(MODEL.APPLICATION, []);
storeList.setFiltersModel(MODEL.APPLICATION, [
  {
    field: "status",
    op: "in",
    value: ['waiting', 'rejected'],
  },
  {
    field: "user_id",
    op: "==",
    value: user.value.id,
  },
]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonCol), {
    size: "12",
    class: _normalizeClass({ 'ion-padding-top ion-no-padding': _unref(isMobile) })
  }, {
    default: _withCtx(() => [
      _createVNode(ListWrapper, {
        model: model.value.name,
        headers: headers
      }, {
        actions_desktop: _withCtx((props) => [
          _createElementVNode("div", _hoisted_1, [
            (props['status'] == _unref(APPLICATION_STATUS).WAITING)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(), _createBlock(WaitingComponent, {
                    key: props,
                    item: props
                  }, null, 8, ["item"]))
                ]))
              : _createCommentVNode("", true),
            (props['status'] == _unref(APPLICATION_STATUS).REJECTED)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_unref(VsxIcon), {
                    size: "15",
                    iconName: "CloseCircle",
                    slot: "start"
                  })
                ]))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        item_mobile: _withCtx((props) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(ItemMobileComponent, { item: props }, null, 8, ["item"]),
            (_openBlock(), _createBlock(WaitingComponent, {
              key: props,
              item: props
            }, null, 8, ["item"]))
          ])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})