import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__wrapper modal-content ion-padding" }
const _hoisted_2 = {
  key: 0,
  class: "ion-flex ion-justify-content-end"
}

import { IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";

interface Props {
  modelValue: boolean;
  noIconClose?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalComponent',
  props: {
    modelValue: { type: Boolean },
    noIconClose: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const closeModal = () => {
  emit("update:modelValue", false);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_unref(IonModal), _mergeProps({
        key: 0,
        mode: "ios",
        "is-open": _ctx.modelValue,
        ref: "modal",
        onWillDismiss: closeModal
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.noIconClose)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "CloseCircle",
                    class: "pointer",
                    color: "gray",
                    onClick: closeModal
                  })
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 16, ["is-open"]))
    : _createCommentVNode("", true)
}
}

})