import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import useCore from "@/composables/useCore";
import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuGroupComponent',
  emits: ["click:copy", "click:share"],
  setup(__props, { emit: __emit }) {

const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();
const { handleGetMe } = useRegister();

const emit = __emit;

const tourContextualCopy = ref(null);
const tourContextualShare = ref(null);

const tourContextualWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};

watch(showDropdown, () => {
  try {
    if (showDropdown.value && !user.value.is_members_contextual) {
      tourContextualWrapper.addStep({
        attachTo: { element: tourContextualCopy.value, on: "top" },
        buttons: [
          {
            text: i18n.global.t("actions.accept"),
            action() {
              tourStore.setTalkieContextualTour({ show: true, step: 1 });
              tourContextualWrapper.next();
            },
          },
        ],
        title: i18n.global.t("tour.tour4_4.title"),
        text: i18n.global.t("tour.tour4_4.text"),
      });
      tourContextualWrapper.addStep({
        attachTo: { element: tourContextualShare.value, on: "top" },
        buttons: [
          {
            text: i18n.global.t("actions.accept"),
            action() {
              tourStore.setTalkieContextualTour({ show: true, step: 2 });
              coreStore.setShowDropdown(false);
              handlePutModel({
                model: MODEL.USER,
                id: registerStore.user.id,
                fields: {
                  is_members_contextual: true,
                },
              }).then(async () => {
                await handleGetMe();
              });
              tourContextualWrapper.next();
            },
          },
        ],
        title: i18n.global.t("tour.tour4_5.title"),
        text: i18n.global.t("tour.tour4_5.text"),
      });
      tourContextualWrapper.start();
    }
  } catch (error) { /* empty */ }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "Pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", {
          ref_key: "tourContextualCopy",
          ref: tourContextualCopy
        }, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            id: "group_copy",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:copy')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Copy",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("group.copy")), 1)
            ]),
            _: 1
          })
        ], 512),
        _createElementVNode("li", {
          ref_key: "tourContextualShare",
          ref: tourContextualShare
        }, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            id: "group_share",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:share')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Hierarchy2",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("group.share")), 1)
            ]),
            _: 1
          })
        ], 512)
      ])
    ]),
    _: 1
  }))
}
}

})