import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "talkie-wrapper__back" }
const _hoisted_2 = { class: "talkie-wrapper__locale" }
const _hoisted_3 = { class: "locale-info-wrapper" }
const _hoisted_4 = {
  id: "locale_name",
  class: "ion-padding"
}
const _hoisted_5 = { class: "caption" }
const _hoisted_6 = { class: "talkie-wrapper__options" }
const _hoisted_7 = {
  key: 0,
  class: "caption message-container__grouped__title"
}
const _hoisted_8 = {
  key: 0,
  class: "message-container__delete-message"
}

import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  InfiniteScrollCustomEvent,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/vue";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { computed, onMounted, ref } from "vue";
import { useUserLocation } from "@/composables/useUserLocation";
import DropdownMenuAudioComponent from "@/components/admin/talkie/DropdownMenuAudioComponent.vue";
import { useMessage } from "@/composables/useMessage";
import MessageWrapper from "@/components/admin/talkie/MessageWrapper.vue";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import {
  handleRemoveNotifications,
} from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'TalkieLocaleAudioView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { total, getUserLocations } = useUserLocation();
const {
  notifications,
  grouped,
  getLocationMessages,
  incrementPage,
  resetPage,
} = useMessage();
const { setNotifications } = useRegister();

const keys = computed(() => {
  return Object.keys(grouped.value);
});

const notificationList = computed(() => {
  return notifications.value;
});

const showRecord = ref(false);

/**
 * Close contextual menu
 */
const closeContextualMenu = () => {
  coreStore.setShowDropdown(false);
};

/**
 * Show Button
 */
const showButton = () => {
  coreStore.setShowDropdown(false);
  showRecord.value = !showRecord.value;
};

/**
 * Delete Notifications
 */
const deleteNotifications = async () => {
  coreStore.setShowDropdown(false);
  await handleRemoveNotifications(notifications.value).then(async () => {
    if (registerStore.location.id) {
      // Reset page
      resetPage();
      await getLocationMessages(registerStore.location.id);
      coreStore.setRefreshLocation(true);
      coreStore.setRefreshUserLocation(true);
    }
  });
};

/**
 * Infinite Scroll
 * @param ev
 */
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
  if (registerStore.location.id) {
    incrementPage();
    await getLocationMessages(registerStore.location.id);
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
  }
};

onMounted(async () => {
  if (registerStore.location.id) {
    await getUserLocations(registerStore.location.id);
    await getLocationMessages(registerStore.location.id);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), {
    onClick: _withModifiers(closeContextualMenu, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonHeader), { class: "talkie-wrapper" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(IonBackButton), {
                  text: "",
                  color: "neutral-80",
                  defaultHref: "/talkie"
                })
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(AvatarLocaleComponent, {
                  "locale-name": _unref(registerStore).location.name,
                  "image-id": _unref(registerStore).location.image_id,
                  "locale-id": _unref(registerStore).location.id ?? -1
                }, null, 8, ["locale-name", "image-id", "locale-id"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(registerStore).location.name), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(total)) + " personas activas", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(DropdownMenuAudioComponent, {
                  "onClick:button": showButton,
                  "onClick:mark": deleteNotifications
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonContent), { class: "message-container" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(keys.value, (key) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "message-container__grouped",
                  key: key
                }, [
                  (_unref(grouped)[key].length)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t(`global.${key}`)), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(grouped)[key], (message) => {
                    return (_openBlock(), _createBlock(MessageWrapper, {
                      notifications: _unref(notifications),
                      message: message,
                      key: message
                    }, null, 8, ["notifications", "message"]))
                  }), 128))
                ]))
              }), 128)),
              (notificationList.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", {
                      class: "register-link",
                      onClick: deleteNotifications
                    }, _toDisplayString(_ctx.$t("talkie.markAsListened")), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(IonInfiniteScroll), { onIonInfinite: ionInfinite }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonInfiniteScrollContent))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (showRecord.value)
            ? (_openBlock(), _createBlock(RecordBarComponent, {
                key: 0,
                "show-all-options": false
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})