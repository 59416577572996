import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-flex ion-align-items-center" }
const _hoisted_3 = { class: "ion-no-margin ion-padding-horizontal" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ion-no-margin ion-padding-horizontal" }
const _hoisted_6 = {
  key: 0,
  class: "location-select-wrapper"
}
const _hoisted_7 = { class: "location-select-wrapper__options" }
const _hoisted_8 = { style: {"margin-right":"10px"} }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "box-title-item" }
const _hoisted_11 = { class: "title-item" }
const _hoisted_12 = { class: "box-address-item" }
const _hoisted_13 = { class: "address-item" }
const _hoisted_14 = {
  key: 0,
  class: "item-end"
}
const _hoisted_15 = { class: "ion-flex ion-justify-content-center" }
const _hoisted_16 = { class: "title-item-admin" }

import { IonButton, IonContent, IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import { useGeneric } from "@/composables/useGeneric";

import { computed, onMounted, ref, watch } from "vue";
import { useLocation } from "@/composables/useLocation";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handlePutModel } from "@/services/generic";
import { MODEL, ROLES } from "@/utils/constants";
import router from "@/router";
import { IUser } from "@/interfaces/login/ILoginResponse";


export default /*@__PURE__*/_defineComponent({
  __name: 'WrapperPageComponent',
  setup(__props) {

const { locations, getLocations, getLocation } = useLocation();
const { refreshLocation, refreshDashboardOwner } = useCore();
const { refresh, setRefresh } = useGeneric();

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { isMobile } = useNativePlatform();
const { isPlatformIOS } = usePlatform();
const { handleGetMe, getLocalePhoto } = useRegister();
const { user } = useRegister();
const isOpen = ref(false);

const locationList = computed(() => {
  return locations.value;
});

const isOwner = computed(() => {
  return user.value.role == ROLES.OWNER;
});

const setOpen = (open: boolean) => (isOpen.value = open);

const handleGoLocations = () => {
  coreStore.setShowDropdown(false);
  setOpen(false);
  router.push({ name: "admin.locations.list" });
};

/**
 * Handle open menu
 */
const handleOpenMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "block";
  menu.style.inset = 0;
  menu?.open();
};

onMounted(async () => {
  await refreshView();
});

const refreshView = async () => {
  await getLocations();
  let location = null;
  await handleGetMe().then(async () => {
    if (registerStore.user.location_id != null) {
      location = await getLocation(registerStore.user.location_id);
      if (location) {
        registerStore.setLocation(location[0]);
      }
    }
  });
};

watch(refreshDashboardOwner, async () => {
  await refreshView();
});

watch(refreshLocation, async () => {
  await refreshView();
  coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
  coreStore.setRefreshTask(!coreStore.refreshTask);
  coreStore.setRefreshDashboardOwner(!coreStore.refreshDashboardOwner);
  coreStore.setRefreshDashboard(!coreStore.refreshDashboard);
  coreStore.setRefreshRequest(!coreStore.refreshRequest);
  setRefresh(!refresh);
});

const setCurrentLocation = async (location: ILocation) => {
  await handlePutModel({
    model: MODEL.USER,
    id: registerStore.user.id,
    fields: {
      location_id: location.id,
    },
  }).then(async () => {
    registerStore.setLocation(location);
    setOpen(false);
    await handleGetMe();
    if (location.image_id) {
      const data = await getLocalePhoto(
        location.id ?? -1,
        location.image_id,
        true
      );
      if (data.url) {
        registerStore.setLocalePhoto(data.url);
      }
    }
    coreStore.setShowDropdown(false);
    coreStore.setRefreshLocation(!coreStore.refreshLocation);
    coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
    coreStore.setRefreshTask(!coreStore.refreshTask);
    coreStore.setRefreshDashboardOwner(!coreStore.refreshDashboardOwner);
    coreStore.setRefreshDashboard(!coreStore.refreshDashboard);
    coreStore.setRefreshRequest(!coreStore.refreshRequest);
    setRefresh(!refresh.value);
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["ion-padding ion-flex ion-justify-content-between wrapper-page-header", _unref(isPlatformIOS) ? 'safe-area' : ''])
    }, [
      (_unref(isMobile))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(VsxIcon), {
                iconName: "Category",
                color: "#787F8C",
                onClick: handleOpenMenu
              }),
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_unref(registerStore).location.id
                ? _unref(registerStore).location.name
                : _ctx.$t("global.home")), 1),
              _createVNode(_unref(IonButton), {
                fill: "clear",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setOpen(!isOpen.value)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "ArrowDown2",
                    color: "#787F8C"
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_unref(registerStore).location.id
              ? _unref(registerStore).location.name
              : _ctx.$t("global.home")), 1)
          ])),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "options")
      ])
    ], 2),
    _createVNode(_unref(IonContent), { class: "wrapper-content" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("ol", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationList.value, (location, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: location.id,
                      class: _normalizeClass({ 'item-start': index === 0 })
                    }, [
                      _createVNode(_unref(IonItem), {
                        button: true,
                        detail: false,
                        onClick: ($event: any) => (setCurrentLocation(location))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, [
                            _createVNode(AvatarLocaleComponent, {
                              "locale-id": location.id ?? -1,
                              "image-id": location.image_id,
                              "locale-name": location.name,
                              width: 35,
                              height: 35
                            }, null, 8, ["locale-id", "image-id", "locale-name"])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(location.name), 1)
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(location.address), 1)
                            ])
                          ]),
                          (_unref(registerStore).user.location_id === location.id)
                            ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                                key: 0,
                                iconName: "TickCircle",
                                color: "#4178F5",
                                slot: "end",
                                type: "bold"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ], 2))
                  }), 128)),
                  (isOwner.value)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                        _createVNode(_unref(IonItem), {
                          button: true,
                          detail: false,
                          onClick: handleGoLocations
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_unref(VsxIcon), {
                                iconName: "Setting4",
                                color: "#4178F5",
                                slot: "end"
                              }),
                              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("locale.adminLocales")), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})