import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "downgrade-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "downgrade-users" }

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import { computed, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handleUsersByLocationData } from "@/services/user_location";
import { ILocationResult } from "@/interfaces/ILocationResult";
import { handleUsersByIdsData } from "@/services/user";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { ITeamMember } from "@/interfaces/ITeamMember";
import { getTeamMembers } from "@/services/subscription";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import MemberInfo from "@/components/shared/MemberInfo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeUsersView',
  setup(__props) {

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();


const users = computed<ITeamMember>(() => coreStore.teamMembers);

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goFinish = async () => {
  await router.replace({ name: "registerPlanUsersFinishDowngrade" });
};

onMounted(async () => {
  if (registerStore.user.location_id) {
    await getTeamMembers(registerStore.user.location_id);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      (users.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("payment.planChangeUsersTitle")), 1),
            (users.value.items)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "downgrade-description caption",
                  innerHTML: 
            _ctx.$t('payment.planChangeTitleDescriptionOption3', {
              users: users.value.items.length,
            })
          
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (users.value.items)
              ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value.items, (user) => {
                      return (_openBlock(), _createElementBlock("li", { key: user }, [
                        _createVNode(MemberInfo, {
                          user: user,
                          "location-id": _unref(registerStore).user?.location_id ?? -1
                        }, null, 8, ["user", "location-id"])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      color: "primary",
                      disabled: _unref(coreStore).subscriptionAvailability?.users_status?.number_users > 5,
                      expand: "block",
                      onClick: goFinish
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("actions.finish")), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})