import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "ion-padding-start" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { ref, watch } from "vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { TourStore, useTourStore } from "@/stores/tour";

interface Props {
  item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuComponent',
  props: {
    item: {}
  },
  emits: [
  "click:edit",
  "click:delete",
  "click:detail",
  "click:copy",
  "click:share",
],
  setup(__props: any, { emit: __emit }) {

const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();
const { user } = useRegister();
const { handleGetMe } = useRegister();

const props = __props;
const emit = __emit;

const tour2_3 = ref(null);
const tour2_4 = ref(null);
const tour2_5 = ref(null);
const tour2_6 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const emitClick = (event: any) => {
  emit(event, props.item);
};

watch(showDropdown, () => {

  if (showDropdown.value && !user.value.is_teams_contextual) {
    tour.addStep({
      attachTo: { element: tour2_3.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 4 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_3.title"),
      text: i18n.global.t("tour.tour2_3.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_4.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 5 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_4.title"),
      text: i18n.global.t("tour.tour2_4.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_5.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 6 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_5.title"),
      text: i18n.global.t("tour.tour2_5.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_6.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tour.next();
            tourStore.setTeamTour({ show: true, step: 7 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_teams_contextual: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
          },
        },
      ],
      title: i18n.global.t("tour.tour2_6.title"),
      text: i18n.global.t("tour.tour2_6.text"),
    });
    tour.start();
  } else {
    coreStore.setShowDropdown(false);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("ol", {
            ref_key: "tour2_3",
            ref: tour2_3,
            style: {"padding":"0px","margin-left":"20px"}
          }, [
            _createElementVNode("li", null, [
              _createElementVNode("div", null, [
                _createVNode(_unref(IonItem), {
                  button: true,
                  detail: false,
                  class: "options_actions",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:detail')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "InfoCircle",
                      color: "gray"
                    }),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "ion-padding-start" }, " Ver detalle ", -1))
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("li", null, [
              _createVNode(_unref(IonItem), {
                button: true,
                detail: false,
                class: "options_actions",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:edit')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(VsxIcon), {
                    iconName: "Edit2",
                    color: "gray"
                  }),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ion-padding-start" }, " Editar ", -1))
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", {
                ref_key: "tour2_4",
                ref: tour2_4
              }, [
                _createVNode(_unref(IonItem), {
                  button: true,
                  detail: false,
                  class: "options_actions",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emitClick('click:copy')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "Copy",
                      color: "gray"
                    }),
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("group.copy")), 1)
                  ]),
                  _: 1
                })
              ], 512)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", {
                ref_key: "tour2_5",
                ref: tour2_5
              }, [
                _createVNode(_unref(IonItem), {
                  button: true,
                  detail: false,
                  class: "options_actions",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (emitClick('click:share')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "Hierarchy2",
                      color: "gray"
                    }),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("group.share")), 1)
                  ]),
                  _: 1
                })
              ], 512)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", {
                ref_key: "tour2_6",
                ref: tour2_6
              }, [
                _createVNode(_unref(IonItem), {
                  button: true,
                  detail: false,
                  class: "options_actions",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (emitClick('click:delete')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(VsxIcon), {
                      iconName: "Trash",
                      class: "trash-icon"
                    }),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "ion-padding-start trash-text" }, " Eliminar ", -1))
                  ]),
                  _: 1
                })
              ], 512)
            ])
          ], 512)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})