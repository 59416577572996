import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "plan-wrapper__content__body" }
const _hoisted_3 = { class: "plan-wrapper__content__body__wrapper" }
const _hoisted_4 = { class: "plan-wrapper__content__body__title" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "plan-wrapper__price" }
const _hoisted_7 = { class: "plan-wrapper__price__amount" }

import {IonCard, IonCardContent, IonButton, IonIcon} from "@ionic/vue";
import {arrowUp, arrowDown} from "ionicons/icons";
import PlanFree from "@/assets/plan_free.svg";
import PlanPro from "@/assets/plan_pro.svg";
import PlanSelected from "@/assets/plan_selected.svg";
import router from "@/router";
import {MODEL, PLAN_TYPE_NAME} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {IPlan} from "@/interfaces/plan/IPlan";
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {ISubscription} from "@/interfaces/subscription/ISubscription";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";



export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentPlan',
  setup(__props) {

const {user} = useRegister()
const {refreshPlan, setRefreshPlan} = useCore()

const plan = ref<IPlan>()

const selectPlan = async () => {

  if (plan.value?.name == PLAN_TYPE_NAME.FREE) {
    await router.replace({ name: "registerPlan" });
  } else {
    await router.replace({ name: "registerPlanDowngrade" });
  }
}


const getSubscriptionPlan = async () => {
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION,
      fields: ['id', 'subscription_plan_id'],
    };
    const filter = [
      {
        field: 'user_id',
        op: "==",
        value: user.value.id,
      },
      {
        field: 'entity_id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then((data) => {
      const subscription = data.items[0] as ISubscription;

      if (subscription) {
        const payload = {
          model: MODEL.SUBSCRIPTION_PLAN,
          fields: ['id', 'name', 'price', 'location_max', 'user_max', 'audio_life_time', 'task_max', 'best_plan'],
        };
        const filter = [
          {
            field: 'id',
            op: "==",
            value: subscription.subscription_plan_id,
          },
        ];
        handleSearchReadModel(payload, filter).then((data) => {
          plan.value = data.items[0] as IPlan;
        })
      }

    })
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getSubscriptionPlan()
})

watch(refreshPlan, () => {
  if (refreshPlan.value) {
    getSubscriptionPlan();
    setRefreshPlan(false);
  }
});



return (_ctx: any,_cache: any) => {
  return (plan.value)
    ? (_openBlock(), _createBlock(_unref(IonCard), {
        key: 0,
        class: "plan-wrapper"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonCardContent), { class: "plan-wrapper__content" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _unref(PlanSelected),
                alt: ""
              }, null, 8, _hoisted_1),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: plan.value.name == _unref(PLAN_TYPE_NAME).FREE ? _unref(PlanFree) : _unref(PlanPro),
                      alt: ""
                    }, null, 8, _hoisted_5),
                    _cache[0] || (_cache[0] = _createTextVNode()),
                    _cache[1] || (_cache[1] = _createElementVNode("span", null, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(plan.value.price) + "€", 1),
                    _createTextVNode("/" + _toDisplayString(_ctx.$t('global.month')), 1)
                  ])
                ]),
                _createVNode(_unref(IonButton), {
                  mode: "ios",
                  fill: "outline",
                  class: "button--select ion-margin-top",
                  type: "button",
                  expand: "block",
                  onClick: selectPlan
                }, {
                  default: _withCtx(() => [
                    (plan.value.name == _unref(PLAN_TYPE_NAME).FREE)
                      ? (_openBlock(), _createBlock(_unref(IonIcon), {
                          key: 0,
                          icon: _unref(arrowUp)
                        }, null, 8, ["icon"]))
                      : (_openBlock(), _createBlock(_unref(IonIcon), {
                          key: 1,
                          icon: _unref(arrowDown)
                        }, null, 8, ["icon"])),
                    _createTextVNode(" " + _toDisplayString(plan.value.name == _unref(PLAN_TYPE_NAME).FREE ? _ctx.$t('actions.improvePlan') : _ctx.$t('actions.downgradePlan')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})