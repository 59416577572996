import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-flex ion-align-items-center member-wrapper"
}
const _hoisted_2 = { class: "ion-padding-start" }

import {IonLabel} from "@ionic/vue";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {onMounted, ref, Ref} from "vue";
import { getRoleName, MODEL } from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {IUser} from "@/interfaces/login/ILoginResponse";

interface Props {
    item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberWrapper',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const user: Ref<IUser | undefined> = ref<IUser>();
const role = ref();

onMounted(() => {
    try {
        const payload = {
            model: MODEL.USER,
            fields: ['name', 'last_name', 'phone_number', 'image_id', 'role'],
        };
        const filter = [
            {
                field: 'id',
                op: "==",
                value: props.item.user_id,
            },
        ];
        handleSearchReadModel(payload, filter).then(async (data) => {
            user.value = data.items[0] as IUser;
            role.value = getRoleName(props.item.role_id)
        })
    } catch (err) {
        console.error(err);
    }
})


return (_ctx: any,_cache: any) => {
  return (user.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(AvatarComponent, {
          "image-id": user.value.image_id ?? -1,
          "user-id": user.value.id
        }, null, 8, ["image-id", "user-id"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(user.value.name + " " + user.value.last_name) + " ", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(`global.${role.value}`)), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})