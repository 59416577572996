import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "my-profile-wrapper__back" }
const _hoisted_2 = { class: "my-profile-wrapper__body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "my-profile-wrapper__terms caption" }

import {
  IonBackButton,
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { PROFILE_VIEW } from "@/utils/constants";
import MyProfileOwnerComponent from "@/components/shared/my_profile/MyProfileOwnerComponent.vue";
import MyBusinessOwnerComponent from "@/components/shared/my_profile/MyBusinessOwnerComponent.vue";
import { useRoute } from "vue-router";
import MyProfileOwnerDetailComponent from "@/components/shared/my_profile/MyProfileOwnerDetailComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileOwnerView',
  setup(__props) {

const route = useRoute();

const selected = ref(PROFILE_VIEW.MY_PROFILE);

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? PROFILE_VIEW.MY_PROFILE;
};

const mode = ref<string>("edit");

onMounted(() => {
  const paramMode = route.query.mode?.toString() ?? null;
  if (paramMode == "detail") {
    mode.value = "detail";
  } else {
    mode.value = "edit";
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding my-profile-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('myProfile.myProfile'),
              color: "neutral-80",
              defaultHref: "/home"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_unref(IonSegment), {
              color: "primary",
              value: selected.value,
              mode: "md",
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (optionSelected($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonSegmentButton), {
                  value: _unref(PROFILE_VIEW).MY_PROFILE
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonLabel), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("myProfile.myData")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"]),
                _createVNode(_unref(IonSegmentButton), {
                  value: _unref(PROFILE_VIEW).MY_BUSINESS
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonLabel), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("myProfile.myTeams")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _createElementVNode("section", null, [
            (selected.value == _unref(PROFILE_VIEW).MY_PROFILE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (mode.value == 'edit')
                    ? (_openBlock(), _createBlock(MyProfileOwnerComponent, { key: 0 }))
                    : (_openBlock(), _createBlock(MyProfileOwnerDetailComponent, { key: 1 }))
                ]))
              : _createCommentVNode("", true),
            (selected.value == _unref(PROFILE_VIEW).MY_BUSINESS)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(MyBusinessOwnerComponent)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("section", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/register/terms",
              class: "link small margin-top-20"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("register.conditions")), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})