import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "autocomplete" }
const _hoisted_2 = ["id", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "autocomplete-results list-group"
}
const _hoisted_4 = ["onClick"]

import { handleReadModel } from "@/services/generic";
import { ref, computed, watch, onMounted, toRefs } from "vue";
import { IonItem } from "@ionic/vue"

interface Props {
  icon?: string;
  modelValue: any;
  placeholder?: string;
  searchFunction: any;
  itemTitle: string;
  itemValue?: string;
  onSelect: any;
  model: string;
}
const iconColor = "#787F8C"

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectComponent',
  props: {
    icon: {},
    modelValue: {},
    placeholder: {},
    searchFunction: {},
    itemTitle: {},
    itemValue: {},
    onSelect: {},
    model: {}
  },
  setup(__props: any) {

const focused = ref(false);
const props = __props;
const modelValueRef = toRefs(props).modelValue;
const results = ref<any[]>([]);
const selected = ref<any>("");
const showResults = computed(() => results.value.length > 0);

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value) {
    props.searchFunction(value).then((data: any[]) => {
      results.value = data;
    });
  } else {
    results.value = [];
  }
}

function changeFocus(_focused: boolean) {
  // the settimeout is so that the click on the options is triggered before the assignment
  setTimeout(() => {
    focused.value = _focused
  }, 500);
}

function handleSelect(result: any) {
  results.value = [];
  selected.value = result[props.itemTitle];
  props.onSelect(result);
}
watch(modelValueRef, () => {
  getModelDefault();
});
const getModelDefault = async () => {
  if (props.modelValue) {
    const model = await handleReadModel({
      model: props.model,
      id: props.modelValue,
    });
    selected.value = model[props.itemTitle];
  }
};
onMounted(() => {
  getModelDefault();
});

return (_ctx: any,_cache: any) => {
  const _component_VsxIcon = _resolveComponent("VsxIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IonItem), {
      fill: "solid",
      class: "input"
    }, {
      default: _withCtx(() => [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_VsxIcon, {
              key: 0,
              iconName: _ctx.icon,
              slot: "start",
              class: "ion-margin-end",
              color: iconColor
            }, null, 8, ["iconName"]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          autocomplete: "off",
          id: 'autocomplete-input-' + _ctx.model,
          class: "input",
          placeholder: _ctx.placeholder,
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
          onFocus: _cache[1] || (_cache[1] = () => changeFocus(true)),
          onBlur: _cache[2] || (_cache[2] = ()=> changeFocus(false)),
          onInput: handleInput
        }, null, 40, _hoisted_2), [
          [_vModelText, selected.value]
        ])
      ]),
      _: 1
    }),
    (showResults.value && focused.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results.value, (result) => {
            return (_openBlock(), _createElementBlock("li", {
              key: result.id,
              onClick: ($event: any) => (handleSelect(result)),
              class: "list-group-item"
            }, _toDisplayString(result.name), 9, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})