import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useOnClickOutside } from "@/composables/useOnClickOutside";

import { ref } from "vue";
import type { Ref } from "vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownComponent',
  setup(__props) {

const isOpen = ref(false);
const dropdown = ref(null);
const menu: Ref<any> = ref(null);

const { isMobile } = useNativePlatform();

const coreStore: CoreStore = useCoreStore();

const toggleOpen = () => {
  coreStore.setShowDropdown(true);
  if (coreStore.showDropdown) {
    isOpen.value = true;
  } else {
    isOpen.value = !isOpen.value;
  }
};

useOnClickOutside(dropdown, () => {
  if (!coreStore.showDropdown) {
    if (isOpen.value) {
      coreStore.setShowDropdown(false);
      isOpen.value = false;
    }
  }
});

const onClickInside = (e: Event) => {
  if (!coreStore.showDropdown) {
    if (menu.value.contains(e.target)) {
      coreStore.setShowDropdown(false);
      isOpen.value = false;
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown",
    ref_key: "dropdown",
    ref: dropdown
  }, [
    _createElementVNode("div", {
      id: "dropdown",
      onClick: toggleOpen
    }, [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_unref(isMobile) ? 'dropdown-menu-mobile' : 'dropdown-menu'),
      ref_key: "menu",
      ref: menu,
      onClick: onClickInside
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2), [
      [_vShow, isOpen.value]
    ])
  ], 512))
}
}

})