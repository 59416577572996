import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import { ellipse, square, triangle } from "ionicons/icons";

export default /*@__PURE__*/_defineComponent({
  __name: 'TabsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "tab1",
                href: "/tabs/tab1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(triangle) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Tab 1")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab2",
                href: "/tabs/tab2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(ellipse) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Tab 2")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tab3",
                href: "/tabs/tab3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(square) }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Tab 3")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})