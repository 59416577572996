import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { style: {"margin-botton":"10px"} }

import { IonButton, IonPage, IonRow, IonCol, alertController } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import MainButtonBarComponent from "@/components/admin/MainButtonBarComponent.vue";
import { onMounted, ref, watch } from "vue";
import useCore from "@/composables/useCore";
import router from "@/router";
import CurrentPlan from "@/components/shared/CurrentPlan.vue";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
import { handlerDashboardOwner } from "@/services/dashboard";
import { IDashboardOwner } from "@/interfaces/dashboard/IDashboardOwner";
import { GROUP_OWNER_VIEW } from "@/utils/constants";
import {CoreStore, useCoreStore} from "@/stores/core";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";



export default /*@__PURE__*/_defineComponent({
  __name: 'IndexView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();
const { user } = useRegister();

const { showTask, refreshDashboardOwner, setGroupTabSelected } = useCore();

const dashboardOwner = ref<IDashboardOwner>();

watch(showTask, () => {
  if (showTask.value) {
    router.push({ name: "default.tasks.list" });
  }
});

watch(refreshDashboardOwner, async () => {
  try {
    if (refreshDashboardOwner.value) {
      const data: IDashboardOwner = await handlerDashboardOwner();
      dashboardOwner.value = data;
      coreStore.setRefreshLocation(false);
      coreStore.setRefreshUserLocation(false);
      coreStore.setRefreshTask(false);
      coreStore.setRefreshDashboardOwner(false);
      coreStore.setRefreshDashboard(false);
    }
  } catch (e) {
    console.error(e);
  }
});

onMounted(async () => {
  try {
    const data: IDashboardOwner = await handlerDashboardOwner();
    dashboardOwner.value = data;
    if (user.value.is_owner) {
      await handlerIncompleteOwnerOnboarding();
    }
  } catch (e) {
    console.error(e);
  }
});

const handleGetRoute = (to: any) => {
  if (to === "admin.groups.list.members") {
    setGroupTabSelected(GROUP_OWNER_VIEW.MEMBERS);
    router.push({ name: "admin.groups.list" });
  }
  if (to === "admin.groups.list.requests") {
    setGroupTabSelected(GROUP_OWNER_VIEW.REQUESTS);
    router.push({ name: "admin.groups.list" });
  }
  if (to === "default.tasks.list") {
    router.push({ name: to });
  }
  if (to === "admin.locations.list") {
    router.push({ name: to });
  }
};

const handlerIncompleteOwnerOnboarding = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.atention"),
    message: i18n.global.t("global.createTeamMessage"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("register.createTeam"),
        cssClass: "wehop-alert-cancel",
        handler: async () => {
          try {
            await router.push({ name: "admin.locations.list" });  
          } catch (e) {
            console.error(e);
          }
        },
      },
    ],
  });
  await alert.present();

  console.log(alert);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      (dashboardOwner.value)
        ? (_openBlock(), _createBlock(WrapperPageComponent, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(IonRow), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonCol), { size: "10" }, {
                      default: _withCtx(() => [
                        _createVNode(CurrentPlan)
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_unref(IonButton), {
                            mode: "ios",
                            expand: "block",
                            class: "button--login ion-margin-top",
                            type: "button",
                            style: {"width":"100%"},
                            "router-link": "/location"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(VsxIcon), { iconName: "ShopAdd" })
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_unref(IonButton), {
                            mode: "ios",
                            expand: "block",
                            class: "button--login ion-margin-top",
                            type: "button",
                            style: {"width":"100%"},
                            "router-link": "/tasks"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(VsxIcon), { iconName: "Notification" })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(IonRow), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonCol), { size: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(CardBusinessInfo, {
                          icon: "Shop",
                          value: dashboardOwner.value.number_locations,
                          text: _ctx.$t('global.locals'),
                          "color-white": "",
                          center: "",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleGetRoute('admin.locations.list')), ["prevent"]))
                        }, null, 8, ["value", "text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(CardBusinessInfo, {
                          icon: "People",
                          value: dashboardOwner.value.number_users,
                          text: _ctx.$t('global.users'),
                          "color-white": "",
                          center: "",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleGetRoute('admin.groups.list.members')), ["prevent"]))
                        }, null, 8, ["value", "text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(CardBusinessInfo, {
                          icon: "Send2",
                          value: dashboardOwner.value.number_invitations,
                          text: _ctx.$t('global.invitations'),
                          "color-white": "",
                          center: "",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleGetRoute('admin.groups.list.requests')), ["prevent"]))
                        }, null, 8, ["value", "text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonCol), { size: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(CardBusinessInfo, {
                          icon: "Note",
                          value: `${dashboardOwner.value.number_tasks.pending}/${dashboardOwner.value.number_tasks.total}`,
                          text: _ctx.$t('global.tasks'),
                          "color-white": "",
                          center: "",
                          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (handleGetRoute('default.tasks.list')), ["prevent"]))
                        }, null, 8, ["value", "text"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(MainButtonBarComponent)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})