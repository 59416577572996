import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-wrapper" }
const _hoisted_2 = { class: "user-wrapper__name" }

import { PropType, ref } from "vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { CoreStore, useCoreStore } from "@/stores/core";
import { IonCheckbox, toastController } from "@ionic/vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handleDeleteModel, handlePostModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { getSubscriptionAvailability } from "@/services/subscription";


export default /*@__PURE__*/_defineComponent({
  __name: 'TeamInfo',
  props: {
  team: {
    type: Object as PropType<ILocation>,
    required: true,
  },
  locationId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const props = __props;

const isChecked = ref<boolean>(props.team?.id != null);
const isDeleted = ref<boolean>(false);
const locationId = ref<number|undefined>(props.team?.id);

const removeTeam = async () => {
    ///Remove User (Only allow remove when team number is greater than 1)
  if (coreStore.subscriptionAvailability && coreStore.subscriptionAvailability?.teams_status?.number_teams > 1) {
    await handleDeleteModel({
      model: MODEL.LOCATION,
      id: locationId.value,
    }).then(async () => {
      isChecked.value = false;
      isDeleted.value = true;
      await getSubscriptionAvailability();
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["user-wrapper__checkbox", {isDeleted: 'disabled'}])
    }, [
      _createVNode(_unref(IonCheckbox), {
        disabled: isDeleted.value,
        checked: isChecked.value,
        onClick: removeTeam
      }, null, 8, ["disabled", "checked"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.team.name), 1)
  ]))
}
}

})