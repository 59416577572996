import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "request-wrapper"
}
const _hoisted_2 = { class: "request-wrapper__user" }
const _hoisted_3 = { class: "request-wrapper__user__info" }
const _hoisted_4 = { class: "caption" }
const _hoisted_5 = { class: "request-wrapper__actions" }
const _hoisted_6 = {
  class: "request-wrapper__actions__action",
  style: {"padding-right":"2px"}
}
const _hoisted_7 = {
  class: "request-wrapper__actions__action",
  style: {"padding-left":"2px"}
}
const _hoisted_8 = { class: "request-wrapper__confirm" }
const _hoisted_9 = { class: "request-wrapper__confirm__info" }
const _hoisted_10 = { class: "caption" }
const _hoisted_11 = { class: "request-wrapper__confirm__body" }
const _hoisted_12 = { class: "caption" }

import { onMounted, ref, Ref } from "vue";
import { MODEL, ROLES } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { IUser } from "@/interfaces/login/ILoginResponse";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {
  IonButton,
  IonModal,
  IonContent,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  RadioGroupCustomEvent,
  actionSheetController,
} from "@ionic/vue";
import { useJoin } from "@/composables/useJoin";
import { getSubscriptionAvailability, getTeamMembers, handlerUserAvailability } from "@/services/subscription";
import i18n from "@/plugins/i18n";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import router from "@/router";
import { CoreStore, useCoreStore } from "@/stores/core";
import { RegisterStore, useRegisterStore } from "@/stores/register";

interface Props {
  item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RequestWrapper',
  props: {
    item: {}
  },
  emits: ["click:update"],
  setup(__props: any, { emit: __emit }) {

const role = ref(ROLES.EMPLOYEE);

const props = __props;

const user: Ref<IUser | undefined> = ref<IUser>();
const isOpen: Ref<boolean> = ref(false);
const { rejectSent, rejectRequest, confirmRequest } = useJoin();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const addLocationResult: Ref<IActionShareResult | undefined> = ref();

const emit = __emit;

const reject = async () => {
  await rejectRequest(props.item.id);
  emit("click:update");
  if (rejectSent.value == true) {
    await getApplications();
  }
};

const showActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: i18n.global.t("global.notMoreUsers"),
    subHeader: i18n.global.t("global.notMoreUsersDescription"),
    cssClass: "wehop-class",
    id: "wehop-id",
    buttons: [
      {
        text: i18n.global.t("global.addUsers"),
        cssClass: "wehop-class__flex",
        data: {
          action: "addUsers",
        },
      },
      {
        text: i18n.global.t("global.changePlan"),
        cssClass: "wehop-class__flex",
        data: {
          action: "changePlan",
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        cssClass: "wehop-class__cancel_button",
        data: {
          action: "cancel",
        },
      },
    ],
  });

  await actionSheet.present();

  const res = await actionSheet.onDidDismiss();
  addLocationResult.value = res as IActionShareResult;
  if (addLocationResult.value.data.action === "addUsers") {
    coreStore.setUpdateLocationPlan(false);
    coreStore.setUpdateUserPlan(true);
    await router.replace({ name: "registerPlan" });
  }
  if (addLocationResult.value.data.action === "changePlan") {
    coreStore.setUpdateLocationPlan(true);
    coreStore.setUpdateUserPlan(false);
    await router.replace({ name: "registerPlan" });
  }
};

const toggleModal = async (status: boolean) => {
  isOpen.value = false;
  if (await handlerUserAvailability()) {
    coreStore.setUpdateUserPlan(false);
    coreStore.setUpdateLocationPlan(true);
    isOpen.value = status;
  } else {
    coreStore.setUpdateLocationPlan(false);
    coreStore.setUpdateUserPlan(true);
    await showActionSheet();
  }
};

const getEmployee = async (event: RadioGroupCustomEvent) => {
  role.value = event.target.value;
};

const confirm = async () => {
  await toggleModal(false);
  await confirmRequest(props.item.id, role.value).then(async () => {
    if (registerStore.user.location_id) {
      //Get Team Members (Only for Owners)
      await getTeamMembers(registerStore.user.location_id);
      //Get Subscription Availability (Only for Owners)
      await getSubscriptionAvailability();
    }
    //Get Applications
    await getApplications();
    emit("click:update");

  });
};

const getApplications = async () => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "name",
        "last_name",
        "phone_number",
        "prefix_number",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: props.item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      user.value = data.items[0] as IUser;
    });
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getApplications();
});

return (_ctx: any,_cache: any) => {
  return (user.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AvatarComponent, {
            class: "request-wrapper__user__photo",
            "image-id": user.value.image_id ?? -1,
            "user-id": user.value.id
          }, null, 8, ["image-id", "user-id"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h6", null, _toDisplayString(user.value.name), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(user.value?.prefix_number) + _toDisplayString(user.value.phone_number), 1)
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "request-wrapper__division" }, null, -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(IonButton), {
              id: "reject_request",
              mode: "ios",
              fill: "outline",
              expand: "block",
              type: "button",
              onClick: reject
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("actions.reject")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(IonButton), {
              mode: "ios",
              expand: "block",
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleModal(true))),
              name: "confirm"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("actions.confirm")), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(IonModal), {
              "is-open": isOpen.value,
              "initial-breakpoint": 0.6,
              breakpoints: [0, 0.25, 0.5, 0.6, 0.75]
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonContent), { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(AvatarComponent, {
                        class: "request-wrapper__confirm__photo",
                        "image-id": user.value.image_id ?? -1,
                        "user-id": user.value.id
                      }, null, 8, ["image-id", "user-id"]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("h6", null, _toDisplayString(user.value.name), 1),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(user.value.prefix_number) + _toDisplayString(user.value.phone_number), 1)
                      ]),
                      _createElementVNode("section", _hoisted_11, [
                        _createElementVNode("h6", null, _toDisplayString(_ctx.$t("joinTeam.assignRole")), 1),
                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("joinTeam.assignRoleDescription")), 1)
                      ])
                    ]),
                    _createVNode(_unref(IonRadioGroup), {
                      value: role.value,
                      style: {"margin-top":"20px"},
                      mode: "md",
                      onIonChange: _cache[1] || (_cache[1] = ($event: any) => (getEmployee($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonLabel), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.employee")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonRadio), {
                              slot: "end",
                              value: "employee"
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(IonItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonLabel), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("joinTeam.manager")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(IonRadio), {
                              slot: "end",
                              value: "manager"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _createVNode(_unref(IonButton), {
                      style: {"margin-top":"40px"},
                      mode: "ios",
                      expand: "block",
                      type: "button",
                      onClick: confirm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("actions.save")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["is-open"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})