import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ion-flex ion-justify-content-between" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "toggle-list ion-flex ion-align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "item-toggle-list__notification"
}
const _hoisted_5 = {
  key: 0,
  class: "splash-record"
}

import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { computed, onMounted, ref } from "vue";
import { IonContent, IonPage, IonSearchbar } from "@ionic/vue";
import AvatarListComponent from "@/components/admin/talkie/AvatarListComponent.vue";
import useRecord from "@/composables/useRecord";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import DropdownMenuButtonNative from "@/components/admin/talkie/DropdownMenuButtonNative.vue";
import { RecordButton } from "capacitor-record-button/src";
import i18n from "@/plugins/i18n";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";

import { useShepherd } from "vue-shepherd";
import { usePlatform } from "@/composables/usePlatform";

export default /*@__PURE__*/_defineComponent({
  __name: 'TalkieListView',
  setup(__props) {

const { notifications, setNotifications } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { isPlatformAndroid } = usePlatform();

const listMode: Ref<boolean> = ref(false);
const { recording } = useRecord();
const { user, handleGetMe } = useRegister();

const notificationsLocation = computed(() => {
  const notification = notifications.value.find((notification) => {
    if ("location_id" in notification) {
      return notification;
    }
  });
  if (notification) {
    const total: number = notification["number notifications"] ?? 0;
    return total;
  } else {
    return 0;
  }
});

const filterList = (searchValue: any) => {
  coreStore.searchUser(searchValue.target.value);
};

const closeMenuContextual = () => {
  coreStore.setShowDropdown(false);
};

const openButton = () => {
  RecordButton.openButtonRecord();
  coreStore.setShowDropdown(false);
};

const tourChat = ref(null);
const tourChatView = ref(null);

const tourChatWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const changeMenu = () => {
  if (!user.value.is_talkie_chat && !listMode.value) {
    tourChatWrapper.addStep({
      attachTo: { element: tourChat.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieChatTour({ show: true, step: 1 });
            listMode.value = !listMode.value;
            tourChatWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_7.title"),
      text: i18n.global.t("tour.tour5_7.text"),
    });
    tourChatWrapper.addStep({
      attachTo: { element: tourChatView.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieChatTour({ show: true, step: 1 });
            listMode.value = !listMode.value;
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_chat: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourChatWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_8.title"),
      text: i18n.global.t("tour.tour5_8.text"),
    });
    tourChatWrapper.start();
  } else {
    listMode.value = !listMode.value;
  }
};

onMounted(() => {
  setNotifications();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), { onClick: closeMenuContextual }, {
    default: _withCtx(() => [
      _createVNode(WrapperPageComponent, null, {
        options: _withCtx(() => [
          (_unref(isPlatformAndroid))
            ? (_openBlock(), _createBlock(DropdownMenuButtonNative, {
                key: 0,
                "onClick:button": openButton
              }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(IonContent), { class: "ion-padding-horizontal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_unref(IonSearchbar), {
                  onIonInput: filterList,
                  placeholder: _ctx.$t('talkie.searchByName'),
                  mode: "ios"
                }, null, 8, ["placeholder"])
              ]),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("talkie.yourTalks")), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {listMode.value = !listMode.value;}),
                    class: _normalizeClass(["item-toggle-list", { 'item-toggle-list-active item-left': !listMode.value }])
                  }, [
                    _createVNode(_unref(VsxIcon), { iconName: "Category" })
                  ], 2),
                  _createElementVNode("div", {
                    ref_key: "tourChat",
                    ref: tourChat,
                    onClick: changeMenu,
                    class: _normalizeClass(["item-toggle-list", { 'item-toggle-list-active item-right': listMode.value }])
                  }, [
                    (notificationsLocation.value > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(VsxIcon), { iconName: "Message" })
                  ], 2)
                ])
              ]),
              _createElementVNode("div", {
                class: "ion-padding-top",
                ref_key: "tourChatView",
                ref: tourChatView
              }, [
                _createVNode(AvatarListComponent, { listMode: listMode.value }, null, 8, ["listMode"])
              ], 512)
            ]),
            _: 1
          }),
          (_unref(recording))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
            : _createCommentVNode("", true),
          _createVNode(RecordBarComponent)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})