import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

import { computed, toRef } from "vue";
import { VsxIcon } from "vue-iconsax";
import { useField } from "vee-validate";
import { TextFieldTypes } from "@/interfaces/GenericModel";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import dayjsTimezone from "dayjs-timezone-iana-plugin";
import { Capacitor } from "@capacitor/core";
import PinCode from "@/components/shared/PinCode.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import SelectAutocompleteComponent from "@/components/shared/form/SelectAutocompleteComponent.vue";

import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonTextarea,
} from "@ionic/vue";
import PhoneNumberComponent from "@/components/shared/PhoneNumberComponent.vue";

interface Props {
  inputType: string;
  type?: TextFieldTypes;
  value?: string;
  options?: string[];
  name: string;
  label: string;
  icon?: string;
  iconColor?: string;
  successMessage?: string;
  placeholder?: string;
  modelName: string;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  filters?: any[];
  fieldSearch?: string;
  clearField?: true;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InputComponent',
  props: {
    inputType: {},
    type: { default: "text" },
    value: {},
    options: {},
    name: {},
    label: {},
    icon: {},
    iconColor: { default: "#787F8C" },
    successMessage: {},
    placeholder: {},
    modelName: {},
    minDate: { default: dayjs().subtract(80, "year").format("YYYY-MM-DDTHH:mm:ss") },
    maxDate: { default: dayjs().add(80, "year").format("YYYY-MM-DDTHH:mm:ss") },
    filters: {},
    fieldSearch: {},
    clearField: { type: Boolean, default: true }
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

dayjs.extend(dayjsPluginUTC);
dayjs.extend(dayjsTimezone);

const props = __props;
const emit = __emit;
const nameRef = toRef(props, "name");

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField<any>(nameRef, undefined, {
  initialValue: props.value,
});

const handleClickSelect = (value: any) => {
  emit("update", {
    model: props?.modelName,
    field: props.name,
    value: value[props.fieldSearch || "id"],
  });
};

const handleClickChange = (e: any) => {
  handleChange(e);
  if (props.inputType === "tel") {
    inputValue.value = e.prefix + "|" + e.phone;
  }
  if (props.inputType === "checkbox") {
    inputValue.value = e.detail.checked;
  }
  if (props.inputType === "number") {
    inputValue.value = e.target.value ? parseInt(e.target.value) : "";
  }
  if (props.inputType === "float") {
    inputValue.value = e.target.value ? parseFloat(e.target.value) : "";
  }
  if (props.inputType === "date") {
    if (e) {
      const date = dayjs(e).tz("Europe/Madrid").format("YYYY-MM-DD");
      inputValue.value = date;
    } else {
      inputValue.value = null;
    }
  }
  if (props.inputType === "toggle") {
    inputValue.value =
      e.target.value == "on" || e.target.value == "false" ? true : false;
  }
};
const handleClickBlur = (e: any) => {
  handleBlur(e);
};

const showType = (type: any) => {
  if (type === "textarea") {
    return "text";
  }
  if (type === "float") {
    return Capacitor.isNativePlatform() ? "number" : "text";
  }
  return type;
};

const dynamicId = computed(() => {
  return `input-${props.modelName}-${props.name}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (
        !['pin', 'checkbox', 'autocomplete', 'tel', 'toggle', 'date'].includes(
          _ctx.inputType
        )
      )
      ? (_openBlock(), _createBlock(_unref(IonItem), {
          key: 0,
          fill: "solid",
          class: "input"
        }, {
          default: _withCtx(() => [
            (_ctx.icon)
              ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                  key: 0,
                  iconName: _ctx.icon,
                  type: "linear",
                  slot: "start",
                  size: 24,
                  color: _ctx.iconColor,
                  class: "ion-margin-end"
                }, null, 8, ["iconName", "color"]))
              : _createCommentVNode("", true),
            (
          ['text', 'password', 'email', 'number', 'float', 'pin'].includes(
            _ctx.inputType
          )
        )
              ? (_openBlock(), _createBlock(_unref(IonInput), {
                  key: 1,
                  id: dynamicId.value,
                  class: "input",
                  placeholder: _ctx.placeholder ?? '',
                  type: showType(_ctx.type),
                  value: _unref(inputValue),
                  onIonInput: handleClickChange,
                  onIonChange: handleClickBlur,
                  step: "0.01",
                  onIonBlur: _unref(handleBlur),
                  "clear-input": _ctx.clearField
                }, null, 8, ["id", "placeholder", "type", "value", "onIonBlur", "clear-input"]))
              : _createCommentVNode("", true),
            (['textarea'].includes(_ctx.inputType))
              ? (_openBlock(), _createBlock(_unref(IonTextarea), {
                  key: 2,
                  id: dynamicId.value,
                  rows: 2,
                  class: "input",
                  placeholder: _ctx.placeholder ?? '',
                  value: _unref(inputValue),
                  onIonInput: handleClickChange,
                  onIonChange: handleClickBlur,
                  onIonBlur: _unref(handleBlur)
                }, null, 8, ["id", "placeholder", "value", "onIonBlur"]))
              : _createCommentVNode("", true),
            (_ctx.inputType === 'select')
              ? (_openBlock(), _createBlock(_unref(IonSelect), {
                  key: 3,
                  id: dynamicId.value,
                  placeholder: _ctx.label,
                  value: _unref(inputValue),
                  onIonChange: handleClickChange,
                  onIonBlur: handleClickBlur
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
                      return (_openBlock(), _createBlock(_unref(IonSelectOption), {
                        value: item,
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["id", "placeholder", "value"]))
              : _createCommentVNode("", true),
            (_unref(errorMessage) || _unref(meta).valid)
              ? (_openBlock(), _createBlock(_unref(IonNote), {
                  key: 4,
                  slot: "helper",
                  color: "danger-80"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(errorMessage) || _ctx.successMessage), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.inputType === 'checkbox')
      ? (_openBlock(), _createBlock(_unref(IonItem), { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonCheckbox), {
              id: dynamicId.value,
              checked: _unref(inputValue),
              value: _unref(inputValue),
              onIonChange: handleClickChange,
              onIonBlur: handleClickBlur,
              slot: "start"
            }, null, 8, ["id", "checked", "value"]),
            _renderSlot(_ctx.$slots, "checkbox", {}, () => [
              _createVNode(_unref(IonLabel), { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              })
            ]),
            (_unref(errorMessage) || _unref(meta).valid)
              ? (_openBlock(), _createBlock(_unref(IonNote), {
                  key: 0,
                  slot: "helper",
                  color: "danger-80"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(errorMessage) || _ctx.successMessage), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    (_ctx.inputType === 'toggle')
      ? (_openBlock(), _createBlock(_unref(IonItem), {
          key: 2,
          class: "ion-toggle-item"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonToggle), {
              id: dynamicId.value,
              checked: _unref(inputValue),
              value: _unref(inputValue),
              onIonChange: handleClickChange,
              onIonBlur: handleClickBlur
            }, null, 8, ["id", "checked", "value"]),
            _createVNode(_unref(IonLabel), { class: "ion-margin-start" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.inputType === 'pin')
      ? (_openBlock(), _createBlock(PinCode, {
          key: 3,
          "digit-count": 5,
          "onUpdate:otp": _unref(handleChange)
        }, null, 8, ["onUpdate:otp"]))
      : _createCommentVNode("", true),
    (_ctx.inputType == 'tel')
      ? (_openBlock(), _createBlock(PhoneNumberComponent, {
          key: 4,
          value: _unref(inputValue),
          placeholder: _ctx.placeholder ?? '',
          "onUpdate:phoneNumber": _unref(handleChange)
        }, null, 8, ["value", "placeholder", "onUpdate:phoneNumber"]))
      : _createCommentVNode("", true),
    (_ctx.inputType === 'autocomplete')
      ? (_openBlock(), _createBlock(SelectAutocompleteComponent, {
          key: 5,
          placeholder: _ctx.placeholder,
          value: _unref(inputValue),
          "onUpdate:modelValue": handleClickChange,
          onSelect: handleClickSelect,
          "item-title": "name",
          "item-value": "id",
          "field-search": _ctx.fieldSearch,
          model: _ctx.modelName,
          "model-value": _unref(inputValue),
          filters: _ctx.filters,
          icon: _ctx.icon
        }, null, 8, ["placeholder", "value", "field-search", "model", "model-value", "filters", "icon"]))
      : _createCommentVNode("", true),
    (_ctx.inputType === 'date')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
          _createVNode(_unref(VueDatePicker), {
            "model-value": _unref(inputValue),
            "onUpdate:modelValue": handleClickChange,
            onCleared: handleClickChange,
            "auto-apply": "",
            placeholder: _ctx.placeholder,
            "model-type": "yyyy-MM-dd",
            locale: "es",
            "enable-time-picker": false
          }, null, 8, ["model-value", "placeholder"]),
          (_unref(errorMessage) || _unref(meta).valid)
            ? (_openBlock(), _createBlock(_unref(IonNote), {
                key: 0,
                slot: "helper",
                color: "danger-80",
                class: "caption",
                style: {"padding-left":"20px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(errorMessage) || _ctx.successMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})