import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box-wrapper"
}
const _hoisted_2 = { class: "cards-business-wrapper" }

import {IonCardContent, IonButton} from "@ionic/vue";
import CurrentPlan from "@/components/shared/CurrentPlan.vue";
import router from "@/router";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {computed, onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";
import {ILocation} from "@/interfaces/locale/ILocation";
import {IEntityResponse} from "@/interfaces/entity/IEntityResponse";
import {useLocation} from "@/composables/useLocation";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyBusinessOwnerComponent',
  setup(__props) {

const updateBusinessInfo = () => {
  router.push({name: 'businessUpdateInfoView'})
}

const {user} = useRegister();
const { getCountries, getCities, countries, cities } = useLocation();

const country = computed(() => {
  return countries.value[0] ?? null;
});
const city = computed(() => {
  return cities.value[0] ?? null;
});

const locales = ref();
const localesIds = ref();
const users = ref();
const entityInfo = ref<IEntityResponse>();

const getLocales = async () => {
  try {
    const payload = {
      model: MODEL.LOCATION,
      fields: ['id', 'name', 'code', 'image_id'],
    };
    const filter = [
      {
        field: 'entity_id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then((data) => {
      locales.value = data.items.length;
      localesIds.value = data.items.map((item:ILocation) => item.id);
      const payload = {
        model: MODEL.USERLOCATION,
        fields: ['id', 'name', 'code'],
      };
      const filter = [
        {
          field: 'location_id',
          op: "in",
          value: localesIds.value,
        },
      ];
      handleSearchReadModel(payload, filter).then((data) => {
        users.value = data.items.length;
      })
    })
  } catch (err) {
    console.error(err);
  }
};

const getEntity = async () => {
  try {
    const payload = {
      model: MODEL.ENTITY,
      fields: ['id', 'name', 'commercial_name', 'address', 'country_id', 'city_id', 'vat', 'phone_number', 'email'],
    };
    const filter = [
      {
        field: 'id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      entityInfo.value = data.items[0] as IEntityResponse;
      await getCountries(entityInfo.value.country_id);
      await getCities(entityInfo.value.city_id);
    })
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getLocales();
  getEntity();
})


return (_ctx: any,_cache: any) => {
  return (entityInfo.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('myProfile.myCurrentPlan')) + ":", 1),
        _createVNode(CurrentPlan),
        _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(CardBusinessInfo, {
            icon: "shop",
            value: locales.value,
            text: _ctx.$t('global.locals')
          }, null, 8, ["value", "text"]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"width":"20px"} }, null, -1)),
          _createVNode(CardBusinessInfo, {
            icon: "people",
            value: users.value,
            text: _ctx.$t('global.users')
          }, null, 8, ["value", "text"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})