import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%","height":"100%","align-items":"center","justify-content":"center"} }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "payment-failed__title" }
const _hoisted_5 = { class: "payment-failed__description" }

import { IonContent, IonPage } from "@ionic/vue";
import failedImage from "@/assets/failed_payment.png";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'FailedPaymentView',
  setup(__props) {

const router = useRouter();
const goBack = () => {
  router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding payment-failed" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _unref(failedImage),
                alt: ""
              }, null, 8, _hoisted_3),
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("payment.failed_title")), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("payment.failed_description")), 1),
              _createVNode(_component_ion_button, {
                mode: "ios",
                expand: "block",
                class: "button--select ion-margin-top",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack())),
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("payment.action_retry")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})