import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "message-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "message-wrapper__photo"
}
const _hoisted_3 = {
  key: 0,
  class: "message-wrapper__body__new"
}
const _hoisted_4 = { class: "message-wrapper__user caption" }
const _hoisted_5 = { class: "message-wrapper__audio" }
const _hoisted_6 = { class: "message-wrapper__audio__control" }
const _hoisted_7 = { class: "message-wrapper__audio__wave" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "message-wrapper__audio__wave__info" }
const _hoisted_10 = { class: "message-wrapper__audio__wave__info__time" }
const _hoisted_11 = { class: "message-wrapper__audio__wave__info__text" }
const _hoisted_12 = {
  key: 1,
  class: "message-wrapper__photo"
}

import { onMounted, PropType, ref, watch } from "vue";
import { VsxIcon } from "vue-iconsax";
import { useAudio } from "@/composables/useAudio";
import wave from "@/assets/wave.svg";
import waveBlue from "@/assets/wave-blue.svg";
import { useUser } from "@/composables/useUser";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { handleRemoveNotification } from "@/services/talkie";
import { INotification } from "@/interfaces/notification/INotification";
import { IMessageSummary, ISender } from "@/interfaces/IMessageSummary";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageWrapper',
  props: {
  message: Object as PropType<IMessageSummary>,
  notifications: Array as PropType<INotification[]>,
},
  setup(__props) {

const { getAudio } = useAudio();
const { getUserInfo } = useUser();
const { user, setNotifications } = useRegister();
const { listeningAudio, listeningAudioId, audioSelected } = useCore();
const registerStore: RegisterStore = useRegisterStore();

const props = __props;

const audio: HTMLAudioElement = new Audio();
const isPlaying = ref(false);
const isPause = ref(false);
const progress = ref("00:00");
const duration = ref("00:00");
const userMessage = ref<ISender>();
const photoLeft = ref(true);
const photoRight = ref(false);
const enableWrapper = ref(true);
const isListen = ref(props.message?.is_listen);

const playAudio = async () => {
  isListen.value = true;

  const item = await getAudio({
    model_id: props.message?.id,
    id: props.message?.file_id,
  });
  if (!isPause.value) {
    if (item) {
      audio.src = `data:audio/wav;base64,${item}`;
      await audio.play();
      isPlaying.value = true;

      audioSelected({mode: true, id: props.message?.id ?? -1});
      audio.addEventListener("timeupdate", () => {
        progress.value = new Date(audio.currentTime * 1000)
            .toISOString()
            .slice(14, 19)
            .toString();
      });
    }
    audio.onended = async function () {
      isPlaying.value = false;
      progress.value = duration.value;
      if (props.message && !props.message?.is_listen) {
        await handleRemoveNotification(props.message.id).then(() => {
          isPlaying.value = true;
        });
      }
      audioSelected({mode: false, id: 0});
    };
  } else {
    await audio.play();
    isPlaying.value = true;
    isPause.value = false;
  }
};

const pauseAudio = () => {
  audio.pause();
  isPlaying.value = false;
  isPause.value = true;
  audioSelected({mode: false, id: props.message?.id ?? -1});
};

const hasNotificationPending = (messageId: number) => {
  if (props.notifications?.length) {
    return (
      props.notifications.filter((n) => n.message_id === messageId).length > 0
    );
  } else {
    return [];
  }
};

onMounted(async () => {
  duration.value = props.message?.duration
    ? new Date(props.message.duration * 1000).toISOString().slice(14, 19)
    : "00:00";
  userMessage.value = props.message?.sender as ISender;
  if (userMessage.value?.is_user) {
    photoLeft.value = false;
    photoRight.value = true;
  }
});

watch(listeningAudio, () => {
  if (listeningAudio.value && listeningAudioId.value == props.message?.id) {
    enableWrapper.value = true;
  } else if (listeningAudio.value) {
    enableWrapper.value = false;
  } else {
    enableWrapper.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (photoLeft.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(AvatarComponent, {
            class: "photo-wrapper left",
            "image-id": userMessage.value?.image_id ?? -1,
            "user-id": userMessage.value?.id ?? -1
          }, null, 8, ["image-id", "user-id"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["message-wrapper__body", photoRight.value ? 'background-blue' : ''])
    }, [
      (!isListen.value)
        ? (_openBlock(), _createElementBlock("em", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, _toDisplayString(userMessage.value?.name), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (enableWrapper.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!isPlaying.value)
                  ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                      key: 0,
                      onClick: playAudio,
                      class: "pointer",
                      iconName: 'Play',
                      slot: "end"
                    }))
                  : _createCommentVNode("", true),
                (isPlaying.value)
                  ? (_openBlock(), _createBlock(_unref(VsxIcon), {
                      key: 1,
                      onClick: pauseAudio,
                      class: "pointer",
                      iconName: "Pause",
                      slot: "end"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createBlock(_unref(VsxIcon), {
                key: 1,
                iconName: "CloseCircle",
                slot: "end"
              }))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: userMessage.value?.is_user ? _unref(waveBlue) : _unref(wave),
            class: "message-wrapper__audio__wave__img"
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(progress.value), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(duration.value), 1)
          ])
        ])
      ])
    ], 2),
    (photoRight.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(AvatarComponent, {
            class: "photo-wrapper right",
            "image-id": _unref(user)?.image_id ?? -1,
            "user-id": _unref(user)?.id ?? -1
          }, null, 8, ["image-id", "user-id"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})