import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text" }

import RadialProgress from "vue3-radial-progress";
import {CoreStore, useCoreStore} from "@/stores/core";
import {storeToRefs} from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressCircle',
  props: {
      diameter: {
        type: Number,
        default: 45
      },
      totalSteps: {
        type: Number,
        default: 4
      },
      strokeWidth: {
        type: Number,
        default: 3
      },
      innerStrokeWidth: {
        type: Number,
        default: 3
      },
      startColor: {
        type: String,
        default: "#4178F5"
      },
      stopColor: {
        type: String,
        default: "#64CDE1"
      },
      innerStrokeColor: {
        type: String,
        default: "#C7CCD6"
      }
    },
  setup(__props) {

const coreStore: CoreStore = useCoreStore()
const {helpStep} = storeToRefs(coreStore);




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RadialProgress), {
    diameter: __props.diameter,
    "completed-steps": _unref(helpStep),
    "total-steps": __props.totalSteps,
    "stroke-width": __props.strokeWidth,
    "inner-stroke-width": __props.innerStrokeWidth,
    "start-color": __props.startColor,
    "stop-color": __props.stopColor,
    "inner-stroke-color": __props.innerStrokeColor
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(helpStep)) + " / " + _toDisplayString(__props.totalSteps), 1)
    ]),
    _: 1
  }, 8, ["diameter", "completed-steps", "total-steps", "stroke-width", "inner-stroke-width", "start-color", "stop-color", "inner-stroke-color"]))
}
}

})