import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "admin__panel" }
const _hoisted_2 = { class: "admin__navigation__drawer" }
const _hoisted_3 = { class: "admin__user__item" }
const _hoisted_4 = { class: "admin__user__item__dropdown" }
const _hoisted_5 = { class: "admin__items" }
const _hoisted_6 = { class: "admin__content" }

import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import links from "@/layouts/navigation/links";
import SnackBarComponent from "@/components/shared/SnackBarComponent.vue";
import NavigationComponent from "@/components/shared/NavigationComponent.vue";
import { VoiceRecorder } from "capacitor-voice-recorder";
import DropdownLocationMenuComponent from "@/components/shared/DropdownLocationMenuComponent.vue";
import { Item } from "@/interfaces/GenericModel";
import { useRegister } from "@/composables/useRegister";
import { getNotificationsQueue } from "@/services/talkie";
import useRecord from "@/composables/useRecord";
import { RecordButton } from "capacitor-record-button/src";

import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterOutlet,
} from "@ionic/vue";
import { NOTIFICATION_TYPE, ROLES } from "@/utils/constants";
import employee_links from "@/layouts/navigation/employee_links";
import {useTokenNotification} from "@/composables/useTokenNotification";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const routesEnable: Ref<boolean> = ref(false);
const { handleGetMe } = useRegister();
const { user } = useRegister();
const items: Ref<Array<Item>> = ref(
  user.value.role == ROLES.OWNER ? links : employee_links
);

const { playing, toggleRecord } = useRecord();
const { initPushNotifications } = useTokenNotification();

const verifyRoutes = (route: any) => {
  routesEnable.value = [
    "admin.home",
    "employee.home",
    "admin.groups.list",
    "default.talkie.list",
  ].includes(route as string);
};
const intervalId: any = ref();
const closeMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "";
  menu.style.inset = "";
  menu?.close();
};

watch(playing, () => {
  if (playing.value) {
    getNotificationsQueue();
  }
});

watch(route, (to) => {
  if (to.name != "login") {
    if (localStorage.getItem("access_token")) {
      handleGetMe();
    }
  }
  verifyRoutes(to.name);
});
handleGetMe();
verifyRoutes(route.name);

const requestRecord = () => {
  VoiceRecorder.requestAudioRecordingPermission();
};

const handleRefresh = () => {
  setTimeout(() => {
    router.go(0);
  }, 500);
};

requestRecord();

onMounted(async () => {
  RecordButton.addListener("toggleRecord", (event: any) => {
    console.log("event", event);
    toggleRecord(
        {
          nameUser: NOTIFICATION_TYPE.LOCATION,
          durationAudio: 2,
          mode: "all",
          userId: user.value.id,
        },
        {
          button: true,
        }
    );
  });
  RecordButton.addListener("buttonPressed", () => {
    toggleRecord({
      nameUser: NOTIFICATION_TYPE.LOCATION,
      durationAudio: 2,
      mode: "all",
      userId: user.value.id,
    });
  });

  await initPushNotifications();
});

onBeforeUnmount(() => {
  RecordButton.removeAllListeners();
});

return (_ctx: any,_cache: any) => {
  return (_unref(user).id)
    ? (_openBlock(), _createBlock(_unref(IonPage), { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonContent), null, {
            default: _withCtx(() => [
              _createVNode(SnackBarComponent),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(DropdownLocationMenuComponent, { "onTo:locations": closeMenu })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                        return (_openBlock(), _createBlock(_unref(RouterLink), {
                          "exact-active-class": "active",
                          key: index,
                          to: { name: item.to }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(VsxIcon), {
                              iconName: item.icon
                            }, null, 8, ["iconName"]),
                            _createElementVNode("span", null, _toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128))
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createVNode(NavigationComponent, null, null, 512), [
                    [_vShow, routesEnable.value]
                  ]),
                  _createVNode(_unref(IonContent), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonRefresher), {
                        slot: "fixed",
                        onIonRefresh: handleRefresh
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonRefresherContent))
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(IonRouterOutlet), { id: "main-content" })
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})