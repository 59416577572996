import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password-wrapper__title" }
const _hoisted_2 = { class: "forgot-password-wrapper__subtitle text-body-s" }

import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPinCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { useForgotPassword } from "@/composables/useForgotPassword";
import ForgotPasswordWrapper from "@/views/forgot_password/ForgotPasswordWrapper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PinCodeView',
  setup(__props) {

const { pin, savePin } = useForgotPassword();

const model: Ref<any> = ref({
  name: "pin_code",
  fields: [
    {
      name: "pin",
      label: "Escribe el código de verificación",
      type: "pin",
    },
  ],
});

const pinCodeSubmit = async (values: any) => {
  pin.value = values.pin;
  await savePin();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ForgotPasswordWrapper, null, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("register.verificationCode")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("register.verificationCodeDescription")), 1),
      _createVNode(FormComponent, {
        model: model.value,
        schema: _unref(schemaPinCode),
        "no-cancel-button": "",
        onSuccess: pinCodeSubmit,
        "prevent-default": ""
      }, {
        submitButton: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("register.continue")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "schema"])
    ]),
    _: 1
  }))
}
}

})