import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-profile-wrapper__back" }
const _hoisted_2 = { class: "my-profile-wrapper__terms" }

import {
  IonBackButton,
  IonContent,
  IonPage,
  SegmentCustomEvent,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { PROFILE_VIEW } from "@/utils/constants";
import MyProfileEmployeeComponent from "@/components/shared/my_profile/MyProfileEmployeeComponent.vue";
import { useRoute } from "vue-router";
import MyProfileEmployeeDetailComponent from "@/components/shared/my_profile/MyProfileEmployeeDetailComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileEmployeeView',
  setup(__props) {

const route = useRoute();

const selected = ref(PROFILE_VIEW.MY_PROFILE);

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? PROFILE_VIEW.MY_PROFILE;
};

const mode = ref<string>("edit");

onMounted(() => {
  const paramMode = route.query.mode?.toString() ?? null;
  if (paramMode == "detail") {
    mode.value = "detail";
  } else {
    mode.value = "edit";
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding my-profile-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('myProfile.myProfile'),
              color: "neutral-80",
              defaultHref: "/join"
            }, null, 8, ["text"])
          ]),
          _createElementVNode("section", null, [
            (mode.value == 'edit')
              ? (_openBlock(), _createBlock(MyProfileEmployeeComponent, { key: 0 }))
              : (_openBlock(), _createBlock(MyProfileEmployeeDetailComponent, { key: 1 }))
          ]),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/register/terms",
              class: "link margin-top-20"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("register.conditions")), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})