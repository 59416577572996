import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = { class: "ion-padding-start" }
const _hoisted_3 = { class: "ion-padding-start" }
const _hoisted_4 = { class: "ion-padding-start" }

import {IonItem} from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {RegisterStore, useRegisterStore} from "@/stores/register";

interface Props {
    item: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuComponent',
  props: {
    item: {}
  },
  emits: ["click:profile", "click:setting", "click:logout"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const registerStore: RegisterStore = useRegisterStore();

const emitClick = (event: any) => {
    emit(event, props.item);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(AvatarComponent, {
          "user-id": _unref(registerStore).user.id,
          "image-id": _unref(registerStore).user.image_id ?? -1
        }, null, 8, ["user-id", "image-id"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:profile')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Profile",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('myProfile.profile')), 1),
              _createVNode(_unref(VsxIcon), {
                iconName: "ArrowRight2",
                color: "gray",
                style: {"position":"absolute","right":"10px"}
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emitClick('click:setting')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Setting2",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('myProfile.setting')), 1),
              _createVNode(_unref(VsxIcon), {
                iconName: "ArrowRight2",
                color: "gray",
                style: {"position":"absolute","right":"10px"}
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_unref(IonItem), {
            button: true,
            detail: false,
            class: "options_actions",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emitClick('click:logout')))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(VsxIcon), {
                iconName: "Logout",
                color: "gray"
              }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('myProfile.logout')), 1),
              _createVNode(_unref(VsxIcon), {
                iconName: "ArrowRight2",
                color: "gray",
                style: {"position":"absolute","right":"10px"}
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})