import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import PlanDetail from "@/components/PlanDetail.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { usePlan } from "@/composables/usePlan";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { MODEL, PLAN_TYPE_CODE, PLAN_TYPE_NAME } from "@/utils/constants";
import { IPlanProduct } from "@/interfaces/plan_product/IPlanProduct";
import { IPlan } from "@/interfaces/plan/IPlan";
import { post } from "@/services/api";
import { IPaymentResult } from "@/interfaces/payment/IPaymentResult";
import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { getSubscriptionAvailability, handleSubscriptionByEntity } from "@/services/subscription";
import { ISubscription } from "@/interfaces/subscription/ISubscription";

// eslint-disable-next-line no-use-before-define
declare let CdvPurchase: any;


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPlanDowngradeView',
  setup(__props) {

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const planProducts = ref<IPlanProduct[]>([]);

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goHome = async () => {
  await router.replace({ name: "admin.home" });
};
const planSelected = async (planProduct: any) => {
  if (planProduct.store_id == 'free') {

    handleSubscriptionByEntity().then(async (data) => {
      const subscription = data as ISubscription;
      await handlePutModel({
        model: MODEL.SUBSCRIPTION,
        id: subscription.id,
        fields: {
          status: 'cancel'
        },
      }).then(async () => {
        await handlePutModel({
          model: MODEL.USER,
          id: registerStore.user.id,
          fields: {
            subscription_id: null,
          },
        }).then(async () => {
          await getSubscriptionAvailability().then(() => {
            if (coreStore.subscriptionAvailability) {
              const numberTeams = coreStore.subscriptionAvailability.teams_status.number_teams;
              const numberUsers = coreStore.subscriptionAvailability.users_status.number_users;

              if ( numberTeams == 1) {
                if (numberUsers <= 5) {
                  return router.replace({ name: "registerPlanOptionOneDowngrade" });
                } else {
                  return router.replace({ name: "registerPlanOptionTwoDowngrade" });
                }
              } else {
                return router.replace({ name: "registerPlanTeamsDowngrade" });
              }
            }
          })
        });
      });
    })
  }
};

const { plans, getSubscriptionPlans } = usePlan();

CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

function registerProducts(plans: IPlan[]) {
  const paidPlan = plans.filter((plan, _) => plan.store_id);
  CdvPurchase.store.verbosity = CdvPurchase.store.DEBUG;
  CdvPurchase.store.register(paidPlan.map(plan => ({
    id: plan.store_id!,
    type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
    platform: isPlatformAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE
  })));
  CdvPurchase.store.initialize();
  CdvPurchase.store.update();
  CdvPurchase.store.restorePurchases();
  mergeProducts();
}

CdvPurchase.store.ready(() => {
  mergeProducts();
});

// eslint-disable-next-line no-use-before-define
const verifyAndroidTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  const transactionToVerify = {
    platform: isPlatformAndroid ? 'android' : CdvPurchase.Platform.APPLE_APPSTORE,
    store_id: transaction.products[0].id,
    purchase_token: transaction.purchaseId
  }
  setTransaction(transactionToVerify);
  verifyTransaction(transactionToVerify)
}

const verifyTransaction = async (payload: any) => {
  try {
    post({url: '/payment/check', payload}).then(async (response: IPaymentResult) => {
      await router.replace({ name: "registerPlanOptionsDowngrade" });
    })
  } catch {
    await router.push({name: 'unverifiedPayment'});
  }
}

// eslint-disable-next-line no-use-before-define
const onApprovedTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  verifyAndroidTransaction(transaction)
}

CdvPurchase.store.when()
  .finished(transaction => alert("state" + transaction.state))
  .approved(onApprovedTransaction);

const mergeProducts = () => {
  planProducts.value = planProducts.value.map(planProduct => ({
    ...planProduct,
    product: CdvPurchase.store.products.find(product => product.id === planProduct.plan.store_id && planProduct.plan.store_id)
  }));
}

onMounted(() => {
  getSubscriptionPlans();
});

onUnmounted(() => {
  CdvPurchase.store.off(onApprovedTransaction)
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-button-back": false,
    "show-progress": false,
    "title-button-back": _ctx.$t('register.downgradePlan'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("div", null, [
        (_unref(plans).length)
          ? (_openBlock(), _createBlock(_unref(IonSlides), {
              key: 0,
              pager: false,
              options: slideOpts
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(plans), (plan, index) => {
                  return (_openBlock(), _createBlock(_unref(IonSlide), {
                    key: index,
                    class: _normalizeClass({ center: _unref(updateUserPlan) || _unref(updateLocationPlan) })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(PlanDetail, {
                        "plan-disabled": plan.name == _unref(PLAN_TYPE_NAME).PRO,
                        plan: plan,
                        onSelected: planSelected,
                        class: _normalizeClass(plan.name == _unref(PLAN_TYPE_NAME).PRO ? 'plan-blocked' : '')
                      }, null, 8, ["plan-disabled", "plan", "class"])
                    ]),
                    _: 2
                  }, 1032, ["class"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_unref(IonButton), {
          fill: "outline",
          expand: "block",
          onClick: goHome
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("global.goBack")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})