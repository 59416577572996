import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "join-wrapper__header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "join-wrapper__body" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "join-wrapper__title" }

import { RegisterStore, useRegisterStore } from "@/stores/register";

import { IonButton, IonContent, IonPage, IonIcon } from "@ionic/vue";
import { addOutline } from "ionicons/icons";
import wehopBrand from "@/assets/wehop-brand.svg";
import joinTeam from "@/assets/join-team.svg";
import DropdownMenuComponent from "@/components/shared/avatar/DropdownMenuComponent.vue";
import router from "@/router";
import { handleLogout } from "@/services/auth";
import { onMounted, ref } from "vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import useCore from "@/composables/useCore";


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinNewHomeView',
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();

const { setResetPinDigit, setResetForm } = useCore();

const tour1_4 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const openProfile = () => {
  router.push({ name: "myProfileEmployee" });
};
const openSetting = () => {
  router.push({ name: "mySettingEmployee" });
};
const openLogout = async () => {
  try {
    await handleLogout().then(async () => {
      setResetPinDigit(true);
      setResetForm(true);
      localStorage.clear();
      setTimeout(async () => {
        await router.push({ name: "login" });
      }, 500);
    });
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_4.value, on: "top" },
    title: i18n.global.t("tour.tour1_4.title"),
    text: i18n.global.t("tour.tour1_4.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding join-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _unref(wehopBrand),
                alt: "WeHop Brand"
              }, null, 8, _hoisted_2)
            ]),
            _createElementVNode("div", null, [
              _createVNode(DropdownMenuComponent, {
                "onClick:profile": openProfile,
                "onClick:settting": openSetting,
                "onClick:logout": openLogout
              })
            ])
          ]),
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("img", { src: _unref(joinTeam) }, null, 8, _hoisted_4),
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("global.hi")) + " " + _toDisplayString(_unref(registerStore).user.name), 1),
            _createElementVNode("div", {
              ref_key: "tour1_4",
              ref: tour1_4,
              style: {"width":"100%","height":"100px","padding":"0","margin":"0"}
            }, [
              _createVNode(_unref(IonButton), {
                class: "join-wrapper__button",
                fill: "outline",
                expand: "block",
                style: {"width":"100%"},
                "router-link": "/join/validate"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), { icon: _unref(addOutline) }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("actions.joinTeam")), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(IonButton), {
                class: "join-wrapper__button",
                fill: "clear",
                expand: "block",
                style: {"width":"100%"},
                "router-link": "/dashboard"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("actions.goToHome")), 1)
                ]),
                _: 1
              })
            ], 512)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})