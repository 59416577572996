import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"100%","height":"30px"},
  id: "tour_contextual"
}
const _hoisted_2 = { class: "ion-padding-start" }

import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { ref, watch } from "vue";
import i18n from "@/plugins/i18n";
import { useShepherd } from "vue-shepherd";
import { CoreStore, useCoreStore } from "@/stores/core";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuButtonNative',
  emits: ["click:button"],
  setup(__props, { emit: __emit }) {

const { handleGetMe } = useRegister();

const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();

const emit = __emit;

const tourContextual = ref(null);

const tourContextualWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};
watch(showDropdown, () => {
  if (showDropdown.value && !user.value.is_talkie_contextual) {
    tourContextualWrapper.addStep({
      attachTo: { element: tourContextual.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieContextualTour({ show: true, step: 1 });
            tourContextualWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_5.title"),
      text: i18n.global.t("tour.tour5_5.text"),
    });
    tourContextualWrapper.addStep({
      attachTo: { element: tourContextual.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieContextualTour({ show: true, step: 2 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_contextual: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourContextualWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_6.title"),
      text: i18n.global.t("tour.tour5_6.text"),
    });
    tourContextualWrapper.start();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, null, {
    button: _withCtx(() => [
      _createVNode(_unref(VsxIcon), {
        class: "pointer",
        iconName: "More",
        slot: "end"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("ol", null, [
        _createElementVNode("li", {
          ref_key: "tourContextual",
          ref: tourContextual
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonItem), {
              button: true,
              detail: false,
              class: "options_actions",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emitClick('click:button')))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(VsxIcon), {
                  iconName: "Maximize",
                  color: "gray"
                }),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("talkie.showButton")), 1)
              ]),
              _: 1
            })
          ])
        ], 512)
      ])
    ]),
    _: 1
  }))
}
}

})