import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "term-wrapper__back" }

import {
  IonPage,
  IonContent,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonBackButton,
} from "@ionic/vue";
import { computed } from "vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import { USER_TYPES } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'TermConditionView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();

const route = computed(() => {
  return coreStore.userType == USER_TYPES.EMPLOYEE
    ? "/register/employee"
    : "/register/store";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "term-wrapper ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('register.legalNotice'),
              color: "neutral-80",
              defaultHref: route.value
            }, null, 8, ["text", "defaultHref"])
          ]),
          _createVNode(_unref(IonAccordionGroup), {
            mode: "ios",
            value: ['1']
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonAccordion), { value: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.objective")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[0] || (_cache[0] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Mediante el presente documento, le informamos de las Condiciones Generales de Uso de los servicios que presta SUMMUMAPP, S.L. a través del cual se da cumplimiento a lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y del Comercio Electrónico (en adelante LSSI). "),
                    _createElementVNode("p", null, " Le rogamos que lea de forma detenida las condiciones de uso antes de utilizar nuestras página web www.wehop.app y/o aplicación móvil (en adelante app) WEHOP. "),
                    _createElementVNode("p", null, " A través de este contrato, se regula la prestación de los servicios ofrecidos a través del mencionado sitio web y/o app. "),
                    _createElementVNode("p", null, " Estos términos y condiciones de uso de la web y de la app contienen información relevante sobre sus derechos y obligaciones. "),
                    _createElementVNode("p", null, " Si Vd. accede o se da de alta en la página web y/o app de WEHOP, o de cualquier otra forma utiliza la plataforma WEHOP, estará aceptando los términos y condiciones de WEHOP, lo cual constituye una vinculación contractual con WEHOP. "),
                    _createElementVNode("p", null, " Si Vd. no está de acuerdo con los términos y condiciones de uso de WEHOP, le rogamos que deje inmediatamente de utilizar y/o acceder a la plataforma WEHOP, bien a través de su página web o a través de la app. "),
                    _createElementVNode("p", null, " A tenor de lo dispuesto en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y del Comercio Electrónico, relativo al deber de información, se indica que SUMMUMAPP, S.L., es la entidad titular de este sitio web y app y la información contenida en el mismo y en este aviso legal ha sido elaborado de conformidad con la LSSI, que incorpora al ordenamiento jurídico español la Directiva 2000/31/CE, del Parlamento Europeo y del Consejo, de 8 de junio. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. es una sociedad española de responsabilidad limitada provista de CIF B-01827716 y con domicilio social en Avenida Andalucía nº 139, 29740 Vélez-Málaga (Málaga) España; inscrita en el Registro Mercantil de Malaga. "),
                    _createElementVNode("p", null, "Nuestro correo electrónico de contacto es: info@wehop.app")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.aboutUs")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[1] || (_cache[1] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " A tenor de lo dispuesto en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y del Comercio Electrónico, relativo al deber de información, se indica que SUMMUMAPP, S.L., es la entidad titular de este sitio web y app y la información contenida en el mismo y en este aviso legal ha sido elaborado de conformidad con la LSSI, que incorpora al ordenamiento jurídico español la Directiva 2000/31/CE, del Parlamento Europeo y del Consejo, de 8 de junio. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. es una sociedad española de responsabilidad limitada provista de CIF B-01827716 y con domicilio social en Avenida Andalucía nº 139, 29740 Vélez-Málaga (Málaga) España; inscrita en el Registro Mercantil de Málaga, Tomo XXXXX, Folio XXXXXX, Hoja XXXXXXX. "),
                    _createElementVNode("p", null, "Nuestro correo electrónico de contacto es: info@wehop.app")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.whatIsWeHop")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, "SUMMUMAPP, S.L. edita y divulga la app y web WEHOP."),
                    _createElementVNode("p", null, " WEHOP es una plataforma web y app que integra distintas tecnologías y funcionalidades para mejorar la gestión y el control de los negocios de manera remota. "),
                    _createElementVNode("p", null, " WEHOP es la herramienta que permite observar, acceder y dirigir un negocio desde cualquier lugar. "),
                    _createElementVNode("p", null, " A través de WEHOP, el usuario podrá tener comunicación directa con el personal de su empresa, así como dispositivos de vigilancia a los que podrá acceder de manera remota. "),
                    _createElementVNode("p", null, " El objetivo de WEHOP es que el usuario pueda conocer en tiempo real todo lo que está sucediendo en su negocio, ya sea a través de contacto directo con sus miembros o a través de las cámaras de videovigilancia. "),
                    _createElementVNode("p", null, " WEHOP dispone principalmente de 3 funcionalidades bien distinguidas: "),
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createTextVNode(" Comunicación directa con trabajadores. El usuario puede entablar comunicación directa con todos los trabajadores a la vez, o de manera individualizada. "),
                        _createElementVNode("br"),
                        _createElementVNode("br"),
                        _createTextVNode(" Para esto, el usuario se encargará de que cada miembro disponga de un dispositivo con WEHOP instalado para poder entablar las comunicaciones. Las comunicaciones pueden ser a través de chat interno o mediante notas de voz. ")
                      ]),
                      _createElementVNode("li", null, " Videovigilancia. El usuario, a través de WEHOP puede acceder en tiempo real y de manera remota a las imágenes recogidas por las cámaras de videovigilancia de las que disponga. "),
                      _createElementVNode("li", null, " Conexión de dispositivos. El usuario podrá acceder a los dispositivos (televisión, tpv, etc.) asociados a WEHOP. De esta manera el usuario tendrá conocimiento en tiempo real del funcionamiento de los mismos. ")
                    ])
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.conditions")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[3] || (_cache[3] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " A través de este aviso legal se pretende regular el acceso y uso en la relación de este portal y los usuarios. "),
                    _createElementVNode("p", null, " El acceso a este website y/o app atribuye la condición de usuario a la persona que accede al mismo y su navegación por este portal y/o app es responsabilidad exclusiva de los usuarios. "),
                    _createElementVNode("p", null, " Con el uso de nuestra página web o por el hecho de contratar nuestros servicios a través de la misma, acepta quedar vinculado por las condiciones que se describen a continuación y, por lo tanto, consiente y declara estar informado sobre ellas, las cuales se han puesto a su entera disposición, entendiéndolas de forma clara y en consecuencia, consiente quedar vinculado a las mismas como usuario. "),
                    _createElementVNode("p", null, [
                      _createTextVNode(" Mediante la utilización de "),
                      _createElementVNode("a", { href: "www.wehop.app" }, "www.wehop.app"),
                      _createTextVNode(" y la app, el usuario podrá contratar los servicios de WEHOP. ")
                    ]),
                    _createElementVNode("p", null, " Se compromete a hacer un uso legalmente válido del portal web y/o app que dispone WEHOP. Por lo tanto, se compromete a no realizar ningún acto fraudulento. Tampoco realizará un uso indebido de la misma mediante la introducción de virus u otros ataques cibernéticos para WEHOP ni intentará tener acceso no autorizado a la misma. Le informamos de que en caso de que así fuera, WEHOP se verá obligada a llevar a cabo las acciones legales que considere pertinentes, informando a las autoridades competentes y, en consecuencia, usted dejará de estar autorizado al acceso de la misma. "),
                    _createElementVNode("p", null, " El usuario es responsable de facilitar sus datos e información de carácter personal de forma veraz, con la finalidad de hacer un buen uso de la página web y app y adquirir el servicio. Estos datos serán tratados por SUMMUMAPP, S.L. bajo su consentimiento para ponerse en contacto con usted en caso de que fuera necesario y para gestionar el servicio contratado. Para más información en relación al tratamiento de datos, por favor, lea de forma detenida nuestra Política de Privacidad https://wehop.app/politicadeprivacidad ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "5" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.contract")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[4] || (_cache[4] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Con la finalidad de contratar nuestros servicios, deberá seguir el procedimiento descrito en la app y la web ¿se puede contratar por la web?, aceptando las presentes condiciones de contratación y uso. "),
                    _createElementVNode("p", null, " Para poder ser usuario de la plataforma WEHOP, el usuario deberá registrarse previamente cumplimentando los campos obligatorios que se le solicite. "),
                    _createElementVNode("p", null, " Para completar el registro, el usuario deberá aceptar expresamente los términos y condiciones de uso, la política de privacidad y la política de cookies. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.features")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[5] || (_cache[5] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " WEHOP ofrece principalmente tres funcionalidades de control remoto: videovigilancia, telecomunicaciones y control de dispositivos. "),
                    _createElementVNode("p", null, " Con respecto a los servicios de videovigilancia, el usuario, a través de WEHOP tendrá acceso a las imágenes que capten las cámaras de videovigilancia en tiempo real. Estas cámaras de videovigilancia habrán sido conectadas por el usuario, no siendo responsable SUMMUMAPP, S.L. del mal uso que el usuario pueda hacer de ellas. "),
                    _createElementVNode("p", null, " Será el usuario el que en todo caso debe cumplir con la normativa relacionada con la protección de datos con respecto a las cámaras de videovigilancia que tenga instaladas en su negocio. "),
                    _createElementVNode("p", null, " Con respecto a los servicios de telecomunicaciones, el usuario, a través de WEHOP podrá comunicarse con sus miembros con mensajes instantáneos a través de un chat y a través de notas de voz. "),
                    _createElementVNode("p", null, " Será el usuario el que debe cumplir con las normas relacionadas con la protección de datos para estos mensajes de voz, y la relación con sus miembros, entre otros. "),
                    _createElementVNode("p", null, " Para el control de dispositivos desde WEHOP, el usuario deberá introducir en la app los dispositivos que desea vincular a WEHOP para tener un control de estos. "),
                    _createElementVNode("p", null, " El usuario deberá garantizar el cumplimiento de la normativa de protección de datos en su negocio, no siendo responsable SUMMUMAPP, S.L. de este no cumplimiento por parte del usuario. "),
                    _createElementVNode("p", null, " Con respecto al cumplimiento por parte de SUMMUMAPP, S.L. con la normativa de protección de datos, diríjase a nuestra política de privacidad ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "7" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.employee")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[6] || (_cache[6] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Teniendo en cuenta que WEHOP es una app destinada al control remoto de negocios ya sea por videovigilancia, comunicación por chat o de voz así como de control de dispositivos de manera remota, es probable que el usuario que la utilice, regente un negocio con uno o más miembros. "),
                    _createElementVNode("p", null, " En este caso, será el usuario que contrate WEHOP quien forme a sus miembros en el uso de WEHOP. "),
                    _createElementVNode("p", null, " El usuario contratante será el encargado de crear perfiles a sus miembros dentro de WEHOP para que pueda comunicarse con éstos. "),
                    _createElementVNode("p", null, " El usuario deberá mantener un registro de los perfiles creados a sus trabajadores, así como debe mantener en un lugar seguro las claves de acceso a WEHOP. "),
                    _createElementVNode("p", null, " El usuario es el responsable de mantener las claves de acceso de manera confidencial, ya que es en todo caso el usuario el responsable de todas y cada una de las actividades que ocurran bajo su contraseña y/o cuenta. "),
                    _createElementVNode("p", null, " El usuario notificará de inmediato a SUMMUMAPP, S.L. sobre cualquier uso no autorizado de su contraseña o cuenta o cualquier otra violación de seguridad. "),
                    _createElementVNode("p", null, " El usuario es el responsable de eliminar la cuenta de un miembro cuando éste deje prestar los servicios para el usuario. "),
                    _createElementVNode("p", null, " Cuando se elimine la cuenta de un miembro, los datos de éste miembro serán eliminados por SUMMUMAPP, S.L. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. no será responsable de ninguna pérdida o daño que surja del incumplimiento por parte del usuario de este apartado. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "8" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.price")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[7] || (_cache[7] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " A través de WEHOP se realizarán pagos por el usuario mediante la pasarela de pagos. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "9" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.responsibility")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Los datos contenidos en este sitio web y app se facilitan de forma objetiva. Toda la información que aparece en dicha página web y app es vigente en la fecha de su última actualización. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. realiza los máximos esfuerzos para evitar cualquier error en los contenidos que pudieran aparecer en su web y app. Sin embargo, SUMMUMAPP, S.L. no garantiza que el sitio web funcione libre de errores y que esté operativo en todo momento, de forma ininterrumpida, ni del mantenimiento de la información o servicios existentes de los vínculos a otros sitios de la web. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. velará porque la información contenida en su sitio web y app sea completa, exacta y esté actualizada. No obstante, no garantiza tales exactitudes y actualizaciones de la información, quedando SUMMUMAPP, S.L. exonerada de cualquier responsabilidad que pudiera derivarse al respecto. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. sólo responde de la veracidad de la información extraída de los documentos que obran en este portal si los mismos son utilizados íntegramente. La utilización por parte de terceros de fragmentos de cualquier información exonera a WEHOP de cualquier responsabilidad, es por ello que la utilización del contenido de dichos documentos por terceros obliga a los mismos a hacer constar su fuente y la presente advertencia. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. declina toda responsabilidad a causa de una utilización inadecuada del servicio o a circunstancias imprevistas en el uso del mismo. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. no se responsabiliza de la información que pudiese visualizarse mediante enlaces electrónicos (links), directa o indirectamente, a través de este sitio web y/o app. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. adopta las medidas de seguridad razonablemente adecuadas. No obstante, dado que las medidas de seguridad en Internet no son enteramente fiables, SUMMUMAPP, S.L. no garantiza la inexistencia de elementos que puedan alterar los sistemas o documentos del usuario, siendo responsabilidad del usuario adoptar todas aquellas medidas técnicas adecuadas para el control de estos riesgos y en evitación de pérdida de datos, sustracción de información y daños en sus equipos. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. no será responsable de posibles daños o perjuicios que se pudieran derivar de interferencias, interrupciones, virus informáticos, averías telefónicas desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a SUMMUMAPP, S.L.; ni tampoco de daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas. "),
                    _createElementVNode("p", null, " Los contenidos de la página web y app a las que los usuarios puedan acceder no son controlados por SUMMUMAPP, S.L., por lo que no se hace responsable de los mismos. El acceso por el usuario a sitios web de terceras partes vinculados, se realiza por riesgo y cuenta propia del usuario, siendo el responsable de los contenidos de las páginas web accesibles a través de los correspondientes enlaces únicamente los propietarios y/o autores de tales páginas web. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. procederá a la retirada de aquellos contenidos que vayan en contra de la ley tan pronto tenga conocimiento de los mismos. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. no se hace responsable de las posibles informaciones y opiniones publicadas por terceros a través de este website y app. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "10" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.resolution")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[9] || (_cache[9] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " El usuario, sin previo aviso, podrá resolver el contrato que le vincula a SUMMUMAPP, S.L. dándose de baja de la plataforma. Para ello deberá hacerlo a través de la app ¿y/o web? en el apartado ”eliminar cuenta”. "),
                    _createElementVNode("p", null, " Una vez eliminada la cuenta, el usuario no podrá acceder ni utilizar la plataforma, independientemente de la tarifa escogida por el usuario. "),
                    _createElementVNode("p", null, " El impago o no renovación de las tarifas contratadas por el usuario, se considerará una resolución automática e inmediata de los servicios ofrecidos por WEHOP, sin notificación alguna al usuario. "),
                    _createElementVNode("p", null, " Asimismo SUMMUMAPP, S.L. podrá cancelar, si así lo determina, algunos de los servicios ofrecidos si considera que éstos son utilizados por el usuario fuera de la legalidad o no son realizadas de buena fe y de acuerdo a las buenas costumbres. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "11" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.dataProtection")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Sus datos de carácter personal o información que nos facilite a través de nuestro sitio web, app y/o vías de contacto, serán tratados de acuerdo con la normativa vigente y con arreglo a lo dispuesto en las Políticas de Protección de Datos (añadir enlace). Mediante el uso de la presente página web y/o app consiente el tratamiento de dicha información y datos por parte de SUMMUMAPP, S.L. como responsable del tratamiento de dichos datos. A su vez, usted declara la veracidad de los mismos. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.industrialProtection")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[11] || (_cache[11] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. es propietario de todos los derechos de Propiedad Intelectual e Industrial contenidos en esta página web y app así como los contenidos vertidos en ellas. El uso de datos, textos, informaciones, imágenes, fotografías o cualquier otro contenido divulgado en la web es objeto de protección a título de Propiedad Intelectual. "),
                    _createElementVNode("p", null, " El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de SUMMUMAPP, S.L. "),
                    _createElementVNode("p", null, " Toda reproducción, representación, utilización o adaptación, por cualquier medio, de todo o partes de elementos de la web, sin autorización escrita de SUMMUMAPP, S.L. constituye un acto de violación sancionado por la Ley de Propiedad Intelectual. El incumplimiento de lo anterior legitimará a SUMMUMAPP, S.L. para interponer las acciones legales pertinentes. SUMMUMAPP, S.L. es titular de la marca registrada WEHOP. Todas las marcas (nombres y logos) y los demás signos distintivos que aparecen en la web son propiedad de SUMMUMAPP, S.L. o de terceros, estando SUMMUMAPP, S.L. autorizada para el uso del signo de terceros. Por consiguiente, toda reproducción y/o representación, y todo uso de estos símbolos distintivos está prohibida, salvo autorización escrita de su titular. "),
                    _createElementVNode("p", null, " Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la plataforma WEHOP son propiedad de SUMMUMAPP, S.L. o de terceros, sin que pueda entenderse que el uso o acceso al Portal y/o a los Servicios atribuya al usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos. "),
                    _createElementVNode("p", null, " Asimismo, los contenidos son Propiedad Intelectual de SUMMUMAPP, S.L. o de terceros, sin que puedan entenderse cedidos al usuario, en virtud de lo establecido en el presente documento, ninguno de los derechos de explotación que existen o puedan existir sobre dichos contenidos más allá de lo estrictamente necesario para el correcto uso del portal y de los servicios. "),
                    _createElementVNode("p", null, " El usuario puede visualizar el contenido de la página web y app, imprimirlo, copiarlo y almacenarlo única y exclusivamente para su uso personal y privado, quedando expresamente prohibida su utilización y distribución con fines comerciales, así como su modificación, alteración o decompilación. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "13" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.modifyConditions")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. se reserva el derecho en cualquier momento de actualizar, modificar, limitar o eliminar de forma unilateral, ya sea de forma temporal o definitiva, la presentación, contenidos y configuración de la página web y/o app, así como los términos y condiciones plasmados en el presente documento. Dichos cambios serán publicados en la web y app ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "14" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "term-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.term.law")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[13] || (_cache[13] = _createElementVNode("div", {
                    class: "ion-padding term-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Las condiciones de uso y contratación son conformes a la legislación española vigente. "),
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. y el usuario, acuerdan someter cualquier controversia que pudiera suscitarse en cuanto a la prestación de los productos, a los Juzgados y Tribunales de Málaga. ")
                  ], -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})