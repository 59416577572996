import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "register-wrapper__back" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "register-wrapper__help"
}
const _hoisted_4 = { class: "ion-padding" }

import { IonBackButton, IonContent, IonPage } from "@ionic/vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import i18n from "@/plugins/i18n";
import { useTokenNotification } from "@/composables/useTokenNotification";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterWrapper',
  props: {
  totalSteps: {
    type: Number,
    default: 3,
  },
  title: {
    type: String,
    default: i18n.global.t("register.createProfile"),
  },
  showProgress: {
    type: Boolean,
    default: true,
  },
  titleButtonBack: {
    type: String,
    default: "",
  },
  showButtonBack: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {



const { initPushNotifications } = useTokenNotification();
onMounted(() => {
  initPushNotifications();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding register-wrapper" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (__props.showButtonBack)
              ? (_openBlock(), _createBlock(_unref(IonBackButton), {
                  key: 0,
                  text: __props.titleButtonBack,
                  color: "neutral-80",
                  defaultHref: "/register"
                }, null, 8, ["text"]))
              : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(__props.titleButtonBack), 1))
          ]),
          (__props.showProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(ProgressCircle, { "total-steps": __props.totalSteps }, null, 8, ["total-steps"]),
                _createElementVNode("h3", _hoisted_4, _toDisplayString(__props.title), 1)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "step")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})