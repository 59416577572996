import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__delete" }
const _hoisted_2 = { class: "modal__title" }
const _hoisted_3 = { class: "modal__content" }
const _hoisted_4 = { class: "text-bold" }
const _hoisted_5 = { class: "modal__actions" }

import {IonInput, IonItem, IonButton} from "@ionic/vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import type {Ref} from "vue";
import {ref} from "vue";

interface Props {
    modelValue: boolean;
    nameItem?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteComponent',
  props: {
    modelValue: { type: Boolean },
    nameItem: {}
  },
  emits: ["update:modelValue", "confirm:delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const valid: Ref<boolean> = ref(false);
const name: Ref<string> = ref("");

const closeModal = () => {
    emit("update:modelValue", false);
};

const verifyName = () => {
    valid.value = name.value === props.nameItem;
};

const confirmDelete = () => {
    emit("confirm:delete");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalComponent, {
    id: "modal-delete",
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _cache[1] || (_cache[1] = _createElementVNode("h3", null, "¿Estás seguro? Confirma la eliminación", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            _createElementVNode("div", null, [
              _cache[2] || (_cache[2] = _createTextVNode(" Al eliminar el elemento ")),
              _createElementVNode("span", _hoisted_4, "\"" + _toDisplayString(_ctx.nameItem) + "\"", 1),
              _cache[3] || (_cache[3] = _createTextVNode(" perderás toda la información relacionada con el y no podrás recuperarlo. "))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ion-padding-top" }, [
              _createElementVNode("span", { class: "text-bold" }, " Para confirmar el borrado debes escribir el nombre del elemento "),
              _createTextVNode(" tal y como está registrado ")
            ], -1))
          ])
        ]),
        _renderSlot(_ctx.$slots, "input", {}, () => [
          _createVNode(_unref(IonItem), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonInput), {
                modelValue: name.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
                onIonChange: verifyName,
                placeholder: "Confirmar borrado"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ])
      ]),
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(IonButton), {
            fill: "clear",
            onClick: closeModal
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" No")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            fill: "clear",
            color: !valid.value ? '' : 'danger',
            disabled: !valid.value,
            onClick: confirmDelete
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Si, eliminar ")
            ])),
            _: 1
          }, 8, ["color", "disabled"])
        ])
      ])
    ]),
    _: 3
  }, 8, ["model-value"]))
}
}

})