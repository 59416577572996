import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "privacy-wrapper__back" }

import {
  IonPage,
  IonContent,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonBackButton,
} from "@ionic/vue";
import { computed } from "vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import { USER_TYPES } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'PrivacyPolicyView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();

const route = computed(() => {
  return coreStore.userType == USER_TYPES.OWNER
    ? "/register/store"
    : "/register/employee";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "privacy-wrapper ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonBackButton), {
              text: _ctx.$t('register.privacyPolicy'),
              color: "neutral-80",
              defaultHref: route.value
            }, null, 8, ["text", "defaultHref"])
          ]),
          _createVNode(_unref(IonAccordionGroup), {
            mode: "ios",
            value: ['1']
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonAccordion), { value: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.privacyPolicy")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[0] || (_cache[0] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " De acuerdo con la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y del Comercio Electrónico (en adelante LSSI), el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos (en adelante RGPD), y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante LOPDGDD) se informa al usuario de que SUMMUMAPP, S.L. es la titular de la página web www.wehop.app y de la aplicación informática (app) WEHOP. "),
                    _createElementVNode("p", null, " A través de esta política de privacidad y cookies se facilitará al usuario toda la información sobre el uso que SUMMUMAPP, S.L. hace de sus datos personales a través de la plataforma WEHOP. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.responsible")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[1] || (_cache[1] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " SUMMUMAPP, S.L., con CIF B-01827716, con domicilio en Avenida Andalucía, 139, 29740 Vélez-Málaga (Málaga), con teléfono de contacto 676403985 y correo electrónico protecciondedatos@wehop.app ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.data")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " WEHOP recopila, entre otros, datos que han sido facilitados previamente por el usuario, ya sea a través de la app o de la web. "),
                    _createElementVNode("p", null, " El usuario se compromete a que los datos facilitados por el mismo son veraces y exactos, que serán siempre tratados por SUMMUMAPP, S.L. de manera confidencial. "),
                    _createElementVNode("p", null, " Por lo general los datos que SUMMUMAPP, S.L puede recopilar, entre otros, son: "),
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, " Datos identificativos (nombre, apellidos, teléfono de contacto, email, fecha, población, etc). "),
                      _createElementVNode("li", null, "Datos bancarios para el pago de la cuota de WEHOP"),
                      _createElementVNode("li", null, "Imágenes captadas por cámaras de videovigilancia"),
                      _createElementVNode("li", null, " Voz captada por los servicios de telecomunicaciones de WEHOP ")
                    ])
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.treatment")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[3] || (_cache[3] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " La finalidad de este tratamiento es la gestión del usuario en la aplicación, así como cualquier cuestión en relación con el servicio contratado y poder gestionar el pago del servicio. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "5" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.legitimation")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[4] || (_cache[4] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Teniendo en cuenta la finalidad del tratamiento, la base jurídica es el consentimiento del usuario que se registra en la app, de conformidad con el artículo 6.1 del RGPD. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.dataRetentionPeriod")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[5] || (_cache[5] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " Los datos del usuario se conservarán durante el tiempo que sea usuario registrado en la app y hasta el plazo necesario para cumplir con las obligaciones legales después de la baja de la app. En todo caso, la conservación se realizará de conformidad con la Norma de conservación y destrucción de datos de WEHOP ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "7" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.transfers")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[6] || (_cache[6] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. no realiza transferencias internacionales de tus datos personales para ninguna de las finalidades indicadas. "),
                    _createElementVNode("p", null, " En caso de que este hecho cambie, te informaremos de ello actualizando la presente política de privacidad y adoptando los mecanismos necesarios para legitimar tales tratamientos de tus datos. ")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "8" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.security")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[7] || (_cache[7] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " El tratamiento de sus datos se realizará adoptando las medidas técnicas y organizativas necesarias para evitar la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma o la comunicación o acceso no autorizados a dichos datos, en función de las tecnologías disponibles, la naturaleza de los datos y el análisis de riesgos efectuado. Si conociera o sospechase de un incidente que pudiera afectar a sus datos personales, ruego nos envíe un email a: "),
                    _createElementVNode("a", { href: "mailto:incidentedeseguridad@wehop.app" }, "incidentedeseguridad@wehop.app")
                  ], -1))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonAccordion), { value: "9" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    slot: "header",
                    color: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), { class: "privacy-wrapper__title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("register.policy.userRights")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createElementVNode("div", {
                    class: "ion-padding privacy-wrapper__content",
                    slot: "content"
                  }, [
                    _createElementVNode("p", null, " SUMMUMAPP, S.L. garantiza el ejercicio de los derechos de los usuarios. Para ello, el usuario podrá dirigirse al responsable sin que suponga ningún gasto para él a la dirección protecciondedatos@wehop.app. En esta comunicación el usuario deberá indicar el motivo de la solicitud y el derecho que desea ejercitar. "),
                    _createElementVNode("p", null, "De este modo, el usuario tiene derecho a:"),
                    _createElementVNode("ol", null, [
                      _createElementVNode("li", null, " Acceder a los datos que SUMMUMAPP, S.L disponga del usuario. El usuario podrá solicitar al responsable una copia de toda la información personal que SUMMUMAPP, S.L tenga sobre él. "),
                      _createElementVNode("li", null, " Rectificar los datos que SUMMUMAPP, S.L disponga del usuario. Si bien el usuario se compromete a proporcionar al responsable datos ciertos y veraces, el interesado podrá solicitar a SUMMUMAPP, S.L. la rectificación de los mismos. "),
                      _createElementVNode("li", null, " Suprimir los datos facilitados a SUMMUMAPP, S.L, cuando ya no sean necesarios para la finalidad perseguida o cuando no exista una base jurídica para el tratamiento. "),
                      _createElementVNode("li", null, " Oponerse al tratamiento de los datos en cualquier momento. "),
                      _createElementVNode("li", null, " Limitar el tratamiento de los datos, solicitando a SUMMUMAPP, S.L que suspenda temporalmente el tratamiento de los datos. "),
                      _createElementVNode("li", null, " La portabilidad de los datos. El usuario podrá solicitar a SUMMUMAPP, S.L recibir una copia de sus datos personales en un formato estructurado y pedir que envíe dicha información a otra entidad. ")
                    ]),
                    _createElementVNode("p", null, [
                      _createTextVNode(" Asimismo, si usted considera que no se han atendido sus derechos podrá presentar una reclamación ante la autoridad de control pertinente: la Agencia Española de Protección de Datos ("),
                      _createElementVNode("a", { href: "https://www.aepd.es" }, "https://www.aepd.es"),
                      _createTextVNode(") en C/ Jorge Juan, 6, 28001 Madrid, con número de teléfono 901100099 y 912663517. ")
                    ]),
                    _createElementVNode("p", null, "Última actualización: 19 abril de 2023")
                  ], -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})