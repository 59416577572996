import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%","height":"100%","align-items":"center","justify-content":"center"} }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "payment-unverified__title" }
const _hoisted_5 = { class: "payment-unverified__description" }

import { IonContent, IonPage, IonButton, toastController } from "@ionic/vue";
import unverifiedImage from "@/assets/unverified_payment.png";
import { usePaymentStore } from "@/stores/payment";
import { useRouter } from "vue-router";
import { post } from "@/services/api";
import i18n from "@/plugins/i18n";
import { ref } from "vue";
import { useCoreStore } from "@/stores/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'UnverifiedPayment',
  setup(__props) {

const router = useRouter();
const { transaction } = usePaymentStore();
const coreStore = useCoreStore();
const loading = ref();

const verifyTransaction = async () => {
  loading.value = true;
  try {
    await post({ url: "/payment/check", payload: transaction });
    await router.push({ name: "successPayment" });
  } catch {
    const toast = await toastController.create({
      message: i18n.global.t("payment.retry_verification"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  } finally {
    loading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding payment-unverified" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _unref(unverifiedImage),
                alt: ""
              }, null, 8, _hoisted_3),
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("payment.unverified_title")), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("payment.unverified_description")), 1),
              _createVNode(_unref(IonButton), {
                mode: "ios",
                expand: "block",
                disabled: loading.value,
                class: "button--select ion-margin-top",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (verifyTransaction())),
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("payment.action_retry")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})