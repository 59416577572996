import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","padding":"0px","margin":"0px"} }

import { IonButton, IonLabel } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaRegisterOwner } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";
import "shepherd.js/dist/css/shepherd.css";

import { useShepherd } from "vue-shepherd";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterStoreView',
  setup(__props) {

const { phone, phonePrefix, name, email, birthday, last_name, saveOwnerInfo } =
  useRegister();
const { isPlatformIOS } = usePlatform();
const tour1_1 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("register.phone"),
      type: "tel",
      icon: "call",
    },
    {
      name: "name",
      label: i18n.global.t("register.fullName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t("register.lastName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "email",
      icon: "sms",
    },
    {
      name: "birthday",
      label: i18n.global.t("register.birthday"),
      type: "date",
      icon: "calendar",
    },
    {
      name: "accept",
      label: i18n.global.t("register.privacyPolicyMessage"),
      type: "checkbox",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  const phoneNumber = values.phone.split("|");

  phonePrefix.value = phoneNumber[0];
  phone.value = phoneNumber[1];
  name.value = values.name;
  last_name.value = values.last_name;
  email.value = values.email;
  birthday.value = values.birthday;
  await saveOwnerInfo().then(() => {
    form.value.handleResetForm();
  });
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_1.value, on: "bottom" },
    title: i18n.global.t("tour.tour1_1.title"),
    text: i18n.global.t("tour.tour1_1.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(RegisterWrapper, {
    "show-progress": false,
    "title-button-back": _ctx.$t('register.createProfile'),
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "tour1_1",
        ref: tour1_1
      }, [
        _createVNode(FormComponent, {
          class: "form-padding",
          ref_key: "form",
          ref: form,
          model: model.value,
          schema: _unref(schemaRegisterOwner),
          "no-cancel-button": "",
          onSuccess: passwordSubmit,
          "prevent-default": ""
        }, {
          checkbox: _withCtx(() => [
            _createVNode(_unref(IonLabel), { class: "ion-text-wrap" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("register.iAccept")) + " ", 1),
                _createVNode(_component_router_link, {
                  to: "/register/policy-privacy",
                  class: "link margin-top-20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.thePrivacyPolicy")), 1)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("register.and")) + " ", 1),
                _createVNode(_component_router_link, {
                  to: "/register/terms",
                  class: "link margin-top-20"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("register.conditions")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          submitButton: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(IonButton), {
                id: "register-button",
                mode: "ios",
                expand: "block",
                class: "button--login ion-margin-top onBoarding",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("actions.continue")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "schema"])
      ], 512)
    ]),
    _: 1
  }, 8, ["title-button-back", "class"]))
}
}

})