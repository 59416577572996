import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-flex ion-justify-content-start ion-align-items-center ion-padding-top ion-padding-start" }
const _hoisted_2 = { class: "ion-no-margin ion-padding-start" }

import { useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import {CoreStore, useCoreStore} from "@/stores/core";

interface Props {
  title: string;
  preventDefault?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderNavigationComponent',
  props: {
    title: {},
    preventDefault: { type: Boolean }
  },
  emits: ["click:back"],
  setup(__props: any, { emit: __emit }) {

const coreStore: CoreStore = useCoreStore();

const props = __props;

const router = useRouter();
const emit = __emit;

const backClickManually = () => {
  if (props.preventDefault) {
    emit("click:back");
  } else {
    //router.go(-1);
    coreStore.setNewLocal(false);
    router.push({name: 'admin.home'})
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { onClick: backClickManually }, [
      _createVNode(_unref(VsxIcon), { iconName: "ArrowLeft2" })
    ]),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
  ]))
}
}

})