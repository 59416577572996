import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-wrapper" }
const _hoisted_2 = { class: "user-wrapper__checkbox" }
const _hoisted_3 = { class: "user-wrapper__name" }

import { computed, PropType, ref } from "vue";
import { IUserMember } from "@/interfaces/ITeamMember";
import {
  handleDeleteModel,
  handlePostModel,
  handlePutModel,
} from "@/services/generic";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { getSubscriptionAvailability } from "@/services/subscription";
import { CoreStore, useCoreStore } from "@/stores/core";
import { toastController, IonCheckbox } from "@ionic/vue";
import i18n from "@/plugins/i18n";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberInfo',
  props: {
  user: {
    type: Object as PropType<IUserMember>,
    required: true,
  },
  locationId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const props = __props;

const isChecked = ref<boolean>(props.user?.user_location_id != null);
const userLocationId = ref<number>(props.user?.user_location_id);

const removeUser = async () => {
  if (isChecked.value == false) {
    ///Add User
    if (coreStore.subscriptionAvailability?.users_status) {
      if (coreStore.subscriptionAvailability?.users_status?.number_users >= 6) {
        isChecked.value = false;
        const toast = await toastController.create({
          message: i18n.global.t("group.rememberLimitUsers"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
        });
        await toast.present();
      } else {
        await handlePostModel({
          model: MODEL.USERLOCATION,
          fields: {
            location_id: props.locationId,
            role_id: ROLE_ID.EMPLOYEE,
            user_id: props.user?.user_id,
            workdays: "Horario de Lunes a Viernes de 09:00 a 16:00",
            shift_days: "Turno: Lunes, Miércoles, Viernes",
          },
        }).then(async (data: IUserLocation) => {
          if (data.id) {
            userLocationId.value = data.id;
          } else {
            userLocationId.value = 0;
          }
          isChecked.value = true;
          await getSubscriptionAvailability();
        });
      }
    }
  } else {
    ///Remove User
    await handleDeleteModel({
      model: MODEL.USERLOCATION,
      id: userLocationId.value,
    }).then(async () => {
      isChecked.value = false;
      await getSubscriptionAvailability();
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(IonCheckbox), {
        checked: isChecked.value,
        onClick: removeUser
      }, null, 8, ["checked"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(__props.user.user_name), 1)
  ]))
}
}

})