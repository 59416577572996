import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "photo-wrapper__title" }
const _hoisted_2 = { class: "photo-wrapper__subtitle text-body-s" }
const _hoisted_3 = { class: "photo-wrapper__body" }
const _hoisted_4 = { class: "photo-wrapper__logo" }
const _hoisted_5 = { class: "photo-wrapper__options" }

import { useRouter } from "vue-router";
import { CoreStore, useCoreStore } from "@/stores/core";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton } from "@ionic/vue";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";
import { ROLES } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPhotoView',
  setup(__props) {

const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const { isPlatformIOS } = usePlatform();

const router = useRouter();

const skipStep = async () => {
  coreStore.resetHelpStep();
  if (registerStore.user.role === ROLES.EMPLOYEE) {
    await router.push({ name: "joinHome" });
  } else {
    await router.push({ name: "registerProfileCreated" });
  }
};

const photoSubmit = async () => {
  if (registerStore.user.role === ROLES.EMPLOYEE) {
    await router.push({ name: "joinHome" });
  } else {
    await router.push({ name: "registerProfileCreated" });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RegisterWrapper, {
    class: _normalizeClass(_unref(isPlatformIOS) ? 'safe-area' : '')
  }, {
    step: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("register.addPhotoToProfile")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("register.addPhotoToProfileDescription")), 1),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(PhotoEditComponent, {
            "image-id": _unref(registerStore).user.image_id ?? -1,
            "user-id": _unref(registerStore).user.id
          }, null, 8, ["image-id", "user-id"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            fill: "outline",
            onClick: skipStep
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.skip")), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            mode: "ios",
            expand: "block",
            class: "button--login ion-margin-top",
            type: "button",
            onClick: photoSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("actions.continue")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})