import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "register-option-wrapper__icon" }
const _hoisted_2 = { class: "register-option-wrapper__body" }
const _hoisted_3 = { class: "register-option-wrapper__body__title" }
const _hoisted_4 = { class: "register-option-wrapper__body__description" }
const _hoisted_5 = { class: "register-option-wrapper__arrow" }

import {IonIcon, IonButtons, IonButton} from '@ionic/vue';
import {chevronForward} from 'ionicons/icons';
import {useRouter} from "vue-router";
import {CoreStore, useCoreStore} from "@/stores/core";
import {USER_TYPES} from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterOption',
  props: {
        icon: String,
        title: String,
        description: String,
        to: String,
        userType: {
            type: String,
            required: true,
            default: USER_TYPES.EMPLOYEE
        }
    },
  setup(__props) {


const router = useRouter();
const coreStore: CoreStore = useCoreStore()

const props = __props

const redirectLink = async () => {
    coreStore.setUserType(props.userType)
    await router.push({name: props.to});
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: "register-option-wrapper",
    onClick: redirectLink
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(IonIcon), { icon: __props.icon }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(__props.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(__props.description), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(IonButtons), { slot: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonButton), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronForward),
                color: "neutral-80"
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})