import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonList,
  IonRouterOutlet,
} from "@ionic/vue";
import type { Ref } from "vue";
import { ref } from "vue";
import { ItemList } from "@/interfaces/ItemList";


export default /*@__PURE__*/_defineComponent({
  __name: 'DocsLayout',
  setup(__props) {

const links: Ref<ItemList> = ref([
  {
    label: "Formulario",
    to: "/docs/",
  },
  {
    label: "Listado",
    to: "/docs/list/",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(IonMenu), { "content-id": "main-content1" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonToolbar), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonTitle), null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("components")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonContent), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link, index) => {
              return (_openBlock(), _createBlock(_unref(IonList), { key: index }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    button: "",
                    onClick: ($event: any) => (_ctx.$router.push(link.to))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(link.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonPage), { id: "main-content1" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonToolbar), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButtons), { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonMenuButton))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(IonTitle), null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Menu")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonContent), { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRouterOutlet))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})